import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { FaFacebook, FaRegStar, FaStar, FaWhatsapp } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { MdMailOutline } from "react-icons/md";
import { FaCommentSms } from "react-icons/fa6";
import { getAffiliationInfo } from "interfaces/restaurant";

const Affiliate = () => {
    const [rating, setRating] = useState(0);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [affiliationInfos, setAffiliationInfos] = useState<any>(null);
    const params = useParams();
    const nav = useNavigate();

    const initAffiliationInfos = async () => {
        const res = await getAffiliationInfo(params.id);
        if (!res) return;
        setAffiliationInfos(res);
    };

    useEffect(() => {
        initAffiliationInfos();
    }, []);

    return (
        <div className="relative min-h-screen">
            {feedbackSent ? (
                <div className="pt-[50px]">
                    <p className="mt-10 text-[35px] text-center font-han">
                        Merci beaucoup !
                    </p>
                    <button
                        className="mt-10 flex mx-auto rounded-2xl px-10 bg-white py-4 border-2 border-[#1f2937] border-xl cursor-pointer"
                        onClick={() => {}}
                    >
                        <p
                            className="flex mx-auto text-base"
                            onClick={() => nav(`/access/${params.id}`)}
                        >
                            Retour au menu
                        </p>
                    </button>
                </div>
            ) : (
                <div className="pt-[50px]">
                    <p className="mt-10 text-[30px] px-2 text-center font-han">
                        Parrainez vos amis !
                    </p>
                    <ToastContainer />
                    <p className="px-10 mt-5 text-center text-lg">
                        Pour chaque ami parrainé, vous gagnez des points de
                        fidélité supplémentaires !
                    </p>
                    <div className="mt-10 p-2 mx-auto rounded-xl border-xl border-2 border-[#1f2937] w-[90%] max-w-[400px]">
                        <p className="text-center text-lg">
                            Partagez ce code avec vos amis :
                        </p>
                        <p className="text-center text-2xl">
                            {affiliationInfos?.sponsorCode}
                        </p>
                    </div>
                    <div className="mt-10 flex justify-center gap-5">
                        <FaWhatsapp
                            size="40"
                            className="cursor-pointer"
                            onClick={() => {
                                window.open(
                                    `https://wa.me/?text=${affiliationInfos?.sponsorMessage}`,
                                    "_blank",
                                );
                            }}
                        />
                        <FaCommentSms
                            size="40"
                            className="cursor-pointer"
                            onClick={() => {
                                window.open(
                                    `sms:?body=${affiliationInfos?.sponsorMessage}`,
                                    "_blank",
                                );
                            }}
                        />
                        <MdMailOutline
                            size="40"
                            className="cursor-pointer"
                            onClick={() => {
                                window.open(
                                    `mailto:?subject=${affiliationInfos?.sponsorMessage}`,
                                    "_blank",
                                );
                            }}
                        />
                    </div>
                    <button
                        className="mt-10 flex mx-auto rounded-2xl px-10 bg-white py-4 border-2 border-[#1f2937] border-xl cursor-pointer"
                        onClick={() => {}}
                    >
                        <p
                            className="flex mx-auto text-base"
                            onClick={() => nav(`/access/${params.id}`)}
                        >
                            Retour au menu
                        </p>
                    </button>
                </div>
            )}
        </div>
    );
};

export default Affiliate;
