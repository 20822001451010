// components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getMyOnlyRestaurantCustomers } from "interfaces/customer";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { getMyRestaurant, updateMyRestaurant } from "interfaces/restaurant";
import SaveStatus from "components/actions/SaveStatus";
import { LuRefreshCw } from "react-icons/lu";
import { dateToFrenchShort } from "utils/date";
import AddPointsModal from "./components/addPointsModal";
import { useDisclosure } from "@chakra-ui/hooks";
import RemovePointsModal from "./components/removePointsModal";
import {
    LinkSponsor,
    addPointsToUser,
    removePointsToUser,
} from "interfaces/user";
import { MdCheck } from "react-icons/md";
import LiveChatFooter from "components/helper/LiveChatFooter";
import Pagination from "components/pagination/Pagination";
import AffiliateModal from "./components/affiliateModal";

const Shop = () => {
    const nav = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const {
        isOpen: isOpenRemove,
        onClose: onCloseRemove,
        onOpen: onOpenRemove,
    } = useDisclosure();
    const {
        isOpen: isOpenAffiliate,
        onClose: onCloseAffiliate,
        onOpen: onOpenAffiliate,
    } = useDisclosure();
    const [limitPerPage, setLimitPerPage] = useState(5);
    const [restaurant, setRestaurant] = useState(null);
    const [saveStatus, setSaveStatus] = useState("none");
    const [refreshing, setRefreshing] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    const handleChange = (e: any) => {
        setConfirmationMsg(null);
        setRestaurant({
            ...restaurant,
            [e.target.id]: e.target.value,
        });
    };

    const dateToTime = (date: any) => {
        const hour = new Date(date).getHours().toString().padStart(2, "0");
        const minutes = new Date(date).getMinutes().toString().padStart(2, "0");
        return `${hour}H${minutes}`;
    };

    const fetchCustomers = async (page: number, limit: number) => {
        const res = await getMyOnlyRestaurantCustomers(page, limit);
        if (res) {
            setCustomers(res.data);
            setTotal(res.total);
            setPage(page);
            setLimitPerPage(limit);
        }
    };

    const addPoints = async (points: number) => {
        const res = await addPointsToUser(
            restaurant._id,
            selectedCustomer._id,
            points,
        );
        if (res) refresh();
    };

    const handleLinkSponsor = async (sponsorCode: string) => {
        const res = await LinkSponsor(selectedCustomer._id, sponsorCode);
        if (res) refresh();
    };

    const removePoints = async (points: number) => {
        const res = await removePointsToUser(
            restaurant._id,
            selectedCustomer._id,
            points,
        );
        if (res) refresh();
    };

    const initRestaurant = async () => {
        const restaurant = await getMyRestaurant();
        setRestaurant(restaurant);
    };

    const refresh = async () => {
        setRefreshing(true);
        fetchCustomers(page, limitPerPage);
        initRestaurant();
        setTimeout(() => {
            setRefreshing(false);
        }, 1000);
    };

    useEffect(() => {
        if (restaurant === null) return;
        setSaveStatus("saving");
        const delayDebounceFn = setTimeout(async () => {
            const res = await updateMyRestaurant(restaurant);
            if (res) {
                setSaveStatus("saved");
                setConfirmationMsg(
                    `D'ici quelques secondes le prochain QR CODE permettra de gagner ${restaurant.nextCustomerReward} points`,
                );
                // setTimeout(() => {
                //     setConfirmationMsg(null);
                // }, 5000);
            } else setSaveStatus("error");
        }, 2500);
        return () => clearTimeout(delayDebounceFn);
    }, [restaurant]);

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            nav("/login");
        }
        fetchCustomers(page, limitPerPage);
        initRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AddPointsModal
                isOpen={isOpen}
                onClose={onClose}
                addPoints={addPoints}
            />
            <AffiliateModal
                isOpen={isOpenAffiliate}
                onClose={onCloseAffiliate}
                linkSponsorCode={handleLinkSponsor}
            />
            <RemovePointsModal
                isOpen={isOpenRemove}
                onClose={onCloseRemove}
                rewards={restaurant?.rewards}
                removePoints={removePoints}
            />
            <SaveStatus status={saveStatus} />
            <Card extra="mt-2 pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                    <div className="relative h-full w-full rounded-[20px]">
                        <h4 className="hidden lg:block ml-2 my-auto text-2xl font-medium text-navy-700 dark:text-white">
                            Gestion boutique
                        </h4>
                        <div className="lg:absolute lg:top-0 lg:right-10">
                            <button
                                className={`p-5 linear w-full ${
                                    saveStatus === "loading"
                                        ? "cursor-wait"
                                        : "cursor-pointer"
                                } rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                onClick={() =>
                                    window.open(
                                        "/presentation?mode=phone",
                                        "_blank",
                                    )
                                }
                            >
                                Voir le QR-CODE
                            </button>
                        </div>
                        <LuRefreshCw
                            size={20}
                            className={`absolute text-navy-700 right-0 top-[50px] lg:top-1 ${
                                refreshing ? "animate-spin" : "cursor-pointer"
                            }`}
                            onClick={refresh}
                        />
                        <div className="">
                            <InputField
                                label="Nombre de points au prochain scan"
                                id="nextCustomerReward"
                                extra="w-full mt-5"
                                placeholder="chargement..."
                                value={
                                    confirmationMsg && saveStatus === "saved"
                                        ? restaurant?.defaultCustomerReward
                                        : restaurant?.nextCustomerReward
                                }
                                onChange={handleChange}
                                type="number"
                            />
                            {confirmationMsg && (
                                <div className="ml-2 flex">
                                    <MdCheck className="flex my-auto text-green-700 border border-green-700 rounded-full" />
                                    <p className="p-2 text-xs text-green-700 font-medium">
                                        {confirmationMsg}
                                    </p>
                                </div>
                            )}
                            {saveStatus === "saving" && (
                                <p className="mt-1 ml-2 text-xs text-gray-500">
                                    Sauvegarde en cours...
                                </p>
                            )}
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor={"lastCustomers"}
                                className={`text-sm font-medium text-navy-700`}
                            >
                                Derniers scans de clients:
                            </label>
                        </div>
                        <div className="mt-4 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium">
                            <p className="col-span-2">Dernier scan</p>
                            <p className="col-span-3">Client</p>
                            <p className="col-span-3">Dernière action</p>
                            <p className="col-span-2">Total points</p>
                            <p className="col-span-2">Action</p>
                        </div>
                        <div className="mt-1 grid grid-cols-1 gap-3">
                            {customers &&
                                customers.map((customer: any) => (
                                    <div className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4">
                                        <p className="col-span-2">
                                            {dateToFrenchShort(
                                                customer?.lastScan,
                                            ) ? (
                                                <>
                                                    {dateToFrenchShort(
                                                        customer?.lastScan,
                                                    )}{" "}
                                                    à{" "}
                                                    {dateToTime(
                                                        customer?.lastScan,
                                                    )}
                                                </>
                                            ) : (
                                                "Aucun scan"
                                            )}
                                        </p>
                                        <div className="col-span-3">
                                            <p>
                                                {customer?.givenName}{" "}
                                                {customer?.familyName}
                                            </p>
                                            <p className="text-xs">
                                                {customer?.email}
                                            </p>
                                            <p className="text-xs">
                                                {customer?.phone}
                                            </p>
                                        </div>
                                        <p className="col-span-2">
                                            QR scanné
                                            {/* , 2 point crédité */}
                                        </p>
                                        <p className="hidden sm:flex col-span-3 sm:col-span-2">
                                            {customer?.rewardsPoints}
                                        </p>
                                        <p className="col-span-3 sm:hidden sm:col-span-2">
                                            {customer?.rewardsPoints} points
                                        </p>
                                        <button
                                            className="text-sm font-medium border rounded-xl p-1 hover:bg-gray-50"
                                            onClick={() => {
                                                setSelectedCustomer(customer);
                                                onOpen();
                                            }}
                                        >
                                            + points
                                        </button>
                                        <button
                                            className="text-xs font-medium border rounded-xl p-1 hover:bg-gray-50"
                                            onClick={() => {
                                                setSelectedCustomer(customer);
                                                onOpenAffiliate();
                                            }}
                                        >
                                            Affiliation
                                        </button>
                                        <button
                                            className="text-sm font-medium border rounded-xl p-1 hover:bg-gray-50"
                                            onClick={() => {
                                                setSelectedCustomer(customer);
                                                onOpenRemove();
                                            }}
                                        >
                                            Offrir un cadeau
                                        </button>
                                    </div>
                                ))}
                        </div>
                        <Pagination
                            extra="mt-3"
                            text={false}
                            maxItems={total}
                            itemsPerPage={[5]}
                            page={page}
                            onChange={(page: number, limit: number) =>
                                fetchCustomers(page, limit)
                            }
                        />
                    </div>
                </div>
            </Card>
            <LiveChatFooter />
        </>
    );
};

export default Shop;
