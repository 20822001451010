import Delete from "./components/Delete";
import Password from "./components/Password";
import BusinessInfo from "./components/BusinessInfo";
import GoBack from "components/actions/GoBack";

const Setting = () => {
    return (
        <div className="mt-2">
            <GoBack />
            <div className="mt-2 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div className="flex flex-col gap-5">
                    {/* <Information /> */}
                    <BusinessInfo />
                </div>

                <div className="flex flex-col gap-5">
                    <Password />
                    <Delete />
                </div>
            </div>
        </div>
    );
};

export default Setting;
