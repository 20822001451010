import Card from "components/card";
import { useNavigate } from "react-router-dom";

const CustomerCard = (props: {
    image: string;
    company: string;
    displayName?: string;
    email: string;
    phone: string;
    download?: string;
    commands: string | number;
    extra?: string;
    id: string;
    customerId: string;
}) => {
    const { company, email, phone, extra, displayName, id } = props;
    const nav = useNavigate();
    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] hover:bg-gray-50 cursor-pointer ${extra}`}
            onClick={() => {
                nav(`/customer/${id}`);
            }}
        >
            <div className="h-full w-full">
                {/* <div className="relative w-full">
                    <img
                        src={image}
                        className="mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
                        alt=""
                    />
                </div> */}

                <div className="mb-3 flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        {displayName ? (
                            <div>
                                <p className="text-lg font-bold text-navy-700 dark:text-white">
                                    {" "}
                                    {displayName}
                                </p>
                                <p className="text-xs font-medium text-navy-700 dark:text-white">
                                    {" "}
                                    {company}
                                </p>
                            </div>
                        ) : (
                            <p className="text-md font-bold text-navy-700 dark:text-white">
                                {" "}
                                {company}
                                {displayName}
                            </p>
                        )}
                        <div className="gap-5">
                            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                {email || "Email non indiqué"}{" "}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
                                {phone || "+33 -- -- -- -- --"}{" "}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                    {/* <div className="flex">
                        <p className="mb-2 text-sm font-bold text-orange-400 dark:text-white">
                            Commandes à traiter: {commands}
                        </p>
                    </div> */}
                </div>
            </div>
        </Card>
    );
};

export default CustomerCard;
