import tabletImage from "../../assets/img/tablet.png";
import clientImage from "../../assets/img/client.png";
import flyerImage from "../../assets/img/flyers.png";
import phoneImage from "../../assets/img/qr-code.png";
import toolsImage from "../../assets/img/tools.png";
import analyticsImage from "../../assets/img/analytics.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getMyInfo } from "interfaces/user";

const Home = () => {
    const nav = useNavigate();
    const [me, setMe] = useState<any>({});
    const [pointsAttribution, setPointsAttribution] = useState("");
    const [giftAttribution, setGiftAttribution] = useState("");
    const [supportType, setSupportType] = useState("");

    const initMe = async () => {
        const me = await getMyInfo();
        const user = me.data;
        setMe(user);
        setPointsAttribution(user.onboardingSettings.pointsAttribution);
        setGiftAttribution(user.onboardingSettings.giftAttribution);
        setSupportType(user.onboardingSettings.supportType);
    };

    const openCrispChat = () => {
        const crispId = "9adcc5aa-0ea5-46ef-b687-0d9e9b8e4aa7";
        const crispBaseUrl = "https://go.crisp.chat/chat/embed/?website_id=";

        window.open(
            `${crispBaseUrl}${crispId}&user_email=${me.email}&user_phone$:${me.phone}`,
            "_blank",
        );
    };

    useEffect(() => {
        initMe();
    }, []);

    const TabMod = () => {
        return (
            <div
                className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() =>
                    window.open("/presentation?mode=phone", "_blank")
                }
            >
                <img src={tabletImage} alt="Tablet" className="w-[60px]" />
                <div>
                    <h1 className="mt-4 text-lg font-bold">
                        Ouvrir le mode tablette
                    </h1>
                    <p className="text-sm mt-1">
                        Le mode tablette vous permet de mettre les QR-codes à la
                        vue de vos clients pour qu'ils puissent consulter leur
                        solde.
                    </p>
                </div>
            </div>
        );
    };

    const PhoneMod = () => {
        return (
            <div
                className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() => nav("/shop")}
            >
                <img src={phoneImage} alt="Tablet" className="w-[60px]" />
                <div>
                    <h1 className="mt-4 text-lg font-bold">
                        Générer un QR code
                    </h1>
                    <p className="text-sm mt-1">
                        Le mode téléphone vous permet de générer des QR-codes
                        pour créer ou alimenter un compte client.
                    </p>
                </div>
            </div>
        );
    };

    const PaperMod = () => {
        return (
            <div
                className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                onClick={() => nav("/print")}
            >
                <img src={flyerImage} alt="Tablet" className="w-[60px]" />
                <div>
                    <h1 className="mt-4 text-lg font-bold">
                        QR-code à imprimer
                    </h1>
                    <p className="text-sm mt-1">
                        Disposez votre QR-code à la vue de vos clients pour
                        qu'ils le scanne à chaque passage en boutique.
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-3 grid grid-cols-1 md:grid-cols-12 gap-10 3xl:px-[120px]">
            {/* Left Section */}
            <div className="col col-span-10 md:col-span-7">
                <h1 className="mt-5 text-xl md:text-2xl md:mt-0 font-bold">
                    Actions rapides
                </h1>
                <div className="mt-4 mx-2 grid grid-cols-1 md:grid-cols-2 gap-4">
                    {supportType === "" && <TabMod />}
                    {supportType === "paper" && <PaperMod />}
                    {supportType === "tablet" && <TabMod />}
                    {supportType === "phone" && <PhoneMod />}
                    {supportType === "other" && <TabMod />}
                    <div>
                        <div
                            className="border rounded-xl p-4 cursor-pointer hover:border-2 hover:bg-gray-50"
                            onClick={() => nav("/performance")}
                        >
                            <div className="flex gap-4">
                                <img
                                    src={analyticsImage}
                                    alt="Customer"
                                    className="h-[50px]"
                                />
                                <h1 className="flex my-auto text-lg font-bold">
                                    Dashboard
                                </h1>
                            </div>
                            <div>
                                <p className="text-xs mt-3">
                                    Suivez les performances de votre boutique.
                                </p>
                            </div>
                        </div>
                        <div
                            className="mt-4 border rounded-xl p-4 cursor-pointer hover:border-2 hover:bg-gray-50"
                            onClick={() => nav("/my-customers")}
                        >
                            <div className="flex gap-4">
                                <img
                                    src={clientImage}
                                    alt="Customer"
                                    className="h-[50px]"
                                />
                                <h1 className="flex my-auto text-lg font-bold">
                                    Voir mes clients
                                </h1>
                            </div>
                            <div>
                                <p className="text-xs mt-3">
                                    Vous pouvez consulter la liste de vos
                                    clients.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="mt-10 text-2xl font-bold">
                    Pour aller plus loin
                </h1>
                <div className="mt-4 grid grid-cols-1 gap-4">
                    <div
                        className="relative border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-gray-50"
                        onClick={() => nav("/tools")}
                    >
                        <img src={toolsImage} alt="Data" className="w-[55px]" />
                        <div>
                            <h1 className="mt-4 text-lg font-bold">
                                Découvrir tous nos outils
                            </h1>
                            <p className="text-sm mt-1">
                                Fidiz vous propose une large gamme d'outils pour
                                fidéliser vos clients, capter des données,
                                mettre en place des campagnes marketing efficace
                                et surveiller la concurence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="flex col col-span-10 md:col-span-5">
                <div className="h-[100%] w-[2px] bg-gray-200 rounded-full"></div>
                <div className="mt-4 grid grid-cols-1">
                    <div className="mx-8">
                        <div className="px-6">
                            <h1 className="text-center text-lg font-bold">
                                Bienvenue 👋
                            </h1>
                        </div>
                        <div className="mt-5 border rounded-xl p-3">
                            <div>
                                <h1 className="text-base font-bold">
                                    Besoin d'aide avec votre compte ?
                                </h1>
                                <p className="text-xs mt-1">
                                    Contactez notre équipe commerciale ou notre
                                    support.
                                </p>
                                <div className="mt-1 flex justify-between px-3">
                                    <div
                                        className="flex gap-3 my-auto"
                                        onClick={openCrispChat}
                                    >
                                        <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                            Chat en ligne
                                        </p>
                                    </div>
                                    <div
                                        className="flex gap-3 my-auto"
                                        onClick={() => {
                                            window.open(
                                                "mailto:hello@fidiz.io",
                                                "_blank",
                                            );
                                        }}
                                    >
                                        <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                            Support par email
                                        </p>
                                    </div>
                                    <div
                                        className="flex gap-3 my-auto"
                                        onClick={() => {
                                            window.open(
                                                "tel:+33770350908",
                                                "_blank",
                                            );
                                        }}
                                    >
                                        <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                            Support par téléphone
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 h-[2px] w-[100%] bg-gray-200 rounded-full"></div>
                        <div className="px-3 mt-5">
                            <h1 className="mt-3 text-md font-bold">
                                1 - Paramétrage de votre boutique
                            </h1>
                            <p className="mt-2 text-sm">
                                Définissez les cadeaux que vous souhaitez offrir
                                à vos clients et les conditions d'obtention de
                                ces points.
                            </p>
                            <div className="flex gap-3 my-auto">
                                <p
                                    className="mt-3 text-xs text-center font-medium text-primary cursor-pointer"
                                    onClick={() => nav("/shop-settings")}
                                >
                                    Gérer ma boutique (1 min)
                                </p>
                            </div>
                        </div>
                        <div className="px-3 mt-5">
                            <h1 className="mt-3 text-md font-bold">
                                2 - Découvrez votre QR-code
                            </h1>
                            <p className="mt-2 text-sm">
                                Vous pouvez générer vos QR-codes et les afficher
                                en boutique. Scannez et testez par vous-même.
                            </p>
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() =>
                                    window.open(
                                        "/presentation?mode=phone",
                                        "_blank",
                                    )
                                }
                            >
                                <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                    Tester maintenant (1 min)
                                </p>
                            </div>
                        </div>
                        <div className="px-3 mt-5">
                            <h1 className="mt-3 text-md font-bold">
                                3 - Votre première campagne
                            </h1>
                            <p className="mt-2 text-sm">
                                Utilisez l'une de nos campagnes pré-existantes
                                pour attirer vos clients en boutique.
                            </p>
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() => nav("/marketing")}
                            >
                                <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                    Lancer une campagne (1 min)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
