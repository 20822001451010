import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getCheckoutSession = async () => {
    const res: any = await axios
        .get(`/stripe/checkout`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getCardInfo = async () => {
    const res = await axios.get(`/stripe/card`, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const purchaseToken = async (quantity: number) => {
    const res = await axios
        .post(`/stripe/purchase-token`, { quantity }, config())
        .catch(errorHandler);
    return res.data;
};

export const confirmPaiement = async (paymentIntentId: string) => {
    const res = await axios
        .post(`/stripe/confirm-payment`, { paymentIntentId }, config())
        .catch(errorHandler);
    return res.data;
};

export const getTokenLogs = async (page: number, limit: number) => {
    let query = `?page=${page}&limit=${limit}`;
    const res: any = await axios
        .get(`/stripe/token-logs${query}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const markAsSubscribed = async () => {
    const res = await axios
        .post(`/stripe/subscribe`, {}, config())
        .catch(errorHandler);
    return res.data;
};
