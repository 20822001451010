import GoBack from "components/actions/GoBack";
import { disconnect, getMyConnections } from "interfaces/connect";
import { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import env from "variables/config";

const MetaAddonInfo = () => {
    const nav = useNavigate();
    const [metaConnection, setMetaConnection] = useState(null);

    const openCrispChat = () => {
        const crispId = "9adcc5aa-0ea5-46ef-b687-0d9e9b8e4aa7";
        const crispBaseUrl = "https://go.crisp.chat/chat/embed/?website_id=";

        window.open(
            `${crispBaseUrl}${crispId}&user_email=${me.email}&user_phone$:${me.phone}`,
            "_blank",
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const connections = await getMyConnections();
            const meta = connections.find(
                (conn: any) => conn.service === "meta",
            );
            setMetaConnection(meta);
        };
        fetchData();
    }, []);

    if (!metaConnection) return <div>Chargement...</div>;

    const { me, pages } = metaConnection.parameters;

    const handleChangePermissions = async () => {
        const facebookLoginUrl = `https://www.facebook.com/v21.0/dialog/oauth?client_id=${env.META_APP_ID}&redirect_uri=${env.META_APP_REDIRECT_URI}&scope=pages_show_list,pages_read_engagement,pages_manage_posts,instagram_content_publish,instagram_basic,business_management`; // Removed: read_insights,pages_read_user_content
        window.location.href = facebookLoginUrl;
    };

    return (
        <div>
            <GoBack />
            <div className="grid grid-cols-1 md:grid-cols-12 gap-2 lg:gap-10 mt-2 lg:px-6 min-h-screen">
                <div className="col-span-10 md:col-span-6">
                    <div className="p-4 bg-white shadow-lg rounded-xl border">
                        <h3 className="text-base font-bold mb-2">
                            Pages Connectées
                        </h3>
                        {pages.map((page: any, index: number) => (
                            <div
                                key={page.id}
                                className="border p-4 rounded-lg mb-4"
                            >
                                <div className="flex items-center gap-2">
                                    <h4 className="text-lg font-semibold">
                                        {page.name}
                                    </h4>
                                    {index === 0 && (
                                        <span className="text-xs text-blue-500 text-bold">
                                            Page principale
                                        </span>
                                    )}
                                </div>
                                <p className="text-sm text-gray-500">
                                    {page.category}
                                </p>
                                <div className="flex items-center gap-2 mt-2 text-sm text-gray-600">
                                    Instagram Business:{" "}
                                    {page?.instagram_business_account?.id ? (
                                        <FaCheckCircle className="text-green-500" />
                                    ) : (
                                        "Non connecté"
                                    )}
                                </div>
                                <p className="text-xs text-gray-500">
                                    {page?.instagram_business_account?.id}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="col-span-10 md:col-span-6 space-y-6">
                    <div className="p-4 bg-white shadow-lg rounded-xl border">
                        <div className="flex items-center space-x-4">
                            <img
                                src={me.picture.data.url}
                                alt={me.name}
                                className="rounded-full h-16 w-16"
                            />
                            <div>
                                <p className="text-sm font-semibold">
                                    Connecté en tant que
                                </p>
                                <p className="text-lg font-bold">{me.name}</p>
                            </div>
                        </div>
                        <div className="flex mt-4 gap-5">
                            <button
                                onClick={handleChangePermissions}
                                className="text-sm text-primary hover:underline"
                            >
                                Modifier les permissions
                            </button>
                            <button
                                className="text-sm text-red-600 hover:underline"
                                onClick={async () => {
                                    if (
                                        window.confirm(
                                            "Voulez-vous vraiment déconnecter Meta ?",
                                        )
                                    ) {
                                        await disconnect(metaConnection._id);
                                        nav("/integrate");
                                    }
                                }}
                            >
                                Déconnecter Meta
                            </button>
                        </div>
                    </div>

                    <div className="p-4 bg-white shadow-lg rounded-xl border">
                        <h3 className="text-base font-bold mb-2">
                            Informations sur l'intégration
                        </h3>
                        <div className="mt-3 flex flex-col space-y-2">
                            <div className="flex justify-between text-sm">
                                <span>Première connexion:</span>
                                <span>
                                    {new Date(
                                        metaConnection.createdAt,
                                    ).toLocaleDateString()}{" "}
                                    {new Date(
                                        metaConnection.createdAt,
                                    ).toLocaleTimeString()}
                                </span>
                            </div>
                            <div className="flex justify-between text-sm">
                                <span>
                                    Dernière modification des permissions:
                                </span>
                                <span>
                                    {new Date(
                                        metaConnection.updatedAt,
                                    ).toLocaleDateString()}{" "}
                                    {new Date(
                                        metaConnection.updatedAt,
                                    ).toLocaleTimeString()}
                                </span>
                            </div>
                            <div className="flex justify-between text-sm">
                                <span>Dernière utilisation:</span>
                                <span>
                                    {new Date().toLocaleDateString()}{" "}
                                    {new Date().toLocaleTimeString()}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 bg-white shadow-lg rounded-xl border">
                        <h3 className="text-base font-bold mb-2">
                            Besoin d'aide avec Meta ?
                        </h3>
                        <p className="text-xs">
                            Contactez notre équipe de support pour toute
                            question concernant l'intégration Meta.
                        </p>
                        <div className="mt-1 flex justify-between px-10">
                            <div
                                className="flex gap-3 my-auto"
                                onClick={openCrispChat}
                            >
                                <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                    Chat en ligne
                                </p>
                            </div>
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() => {
                                    window.open(
                                        "mailto:hello@fidiz.io",
                                        "_blank",
                                    );
                                }}
                            >
                                <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                    Support par email
                                </p>
                            </div>
                            <div
                                className="flex gap-3 my-auto"
                                onClick={() => {
                                    window.open("tel:+33770350908", "_blank");
                                }}
                            >
                                <p className="mt-3 text-xs text-center font-medium text-primary cursor-pointer">
                                    Support par téléphone
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MetaAddonInfo;
