import Default from "layouts/auth/types/Default";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "interfaces/user";

function ResetPassword() {
    const params = useParams();
    const nav = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleForgotPassword = async () => {
        if (password !== confirmPassword) {
            toast.error("Les mots de passe ne correspondent pas");
            return;
        }

        const res = await resetPassword({ password, token: params.id });
        if (res.data) {
            nav("/auth/login");
        }
    };

    return (
        <Default
            maincard={
                <div className="flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-0">
                    {/* Sign in section */}
                    <div className="mx-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <ToastContainer />
                        <h3 className="text-xl lg:text-2xl mb-5 font-han dark:text-white">
                            Changer mon mot de passe
                        </h3>
                        {/* Email */}
                        <div className="flex mt-5">
                            <input
                                id="passwordl"
                                key={"password"}
                                type="password"
                                placeholder="Mot de passe*"
                                className="p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                onChange={(e: any) =>
                                    setPassword(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex mt-3">
                            <input
                                id="passwordl"
                                key={"password"}
                                type="password"
                                placeholder="Confirmation du mot de passe*"
                                className="p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                onChange={(e: any) =>
                                    setConfirmPassword(e.target.value)
                                }
                            />
                        </div>
                        <button
                            id="connect-btn"
                            className="linear mt-5 w-full rounded-xl bg-[#FEDE65] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:bg-[#fedd65a4] active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            onClick={() => handleForgotPassword()}
                        >
                            Modifier mon mot de passe
                        </button>
                    </div>
                </div>
            }
        />
    );
}

export default ResetPassword;
