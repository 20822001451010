const ActionButton = (props: {value: any, extra?: string, onClick?: any}) => {
	const {value, extra, onClick} = props;
    return (
        <button className={`linear flex items-center justify-center rounded-xl bg-green-500 px-10 py-3 text-base font-medium text-white hover:bg-green-600 active:bg-green-700 ${extra}`}
		onClick={onClick}>
            {value}
        </button>
    );
};

export default ActionButton;
