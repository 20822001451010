const CancelButton = (props: {value: string, extra?: string, onClick?: any}) => {
	const {value, extra, onClick} = props;
    return (
        <button className={`linear flex rounded-xl border-2 items-center justify-center border-red-500 px-10 py-3 text-base font-medium text-red-500 hover:bg-red-600/5 active:bg-red-700/5 ${extra}`}
		onClick={onClick}>
            {value}
        </button>
    );
};

export default CancelButton;
