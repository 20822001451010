import axios from "axios";
import { errorHandler } from "../utils";
import env from "variables/config";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization:
                "Bearer " + localStorage.getItem("tablet-manager-token"),
        },
    };
};

export const loginFromMagicLink = async (token: string) => {
    const res: any = await axios
        .get(`/auth/magic-link/${token}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getMyRestaurant = async () => {
    const res = await axios.get(`/restaurants`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const updateMyRestaurant = async (restaurant: any) => {
    const id = restaurant._id;
    const res: any = await axios
        .put(
            `/restaurants/${id}`,
            {
                rewards: restaurant.rewards,
                name: restaurant.name,
                nextCustomerReward: restaurant.nextCustomerReward,
                googlePlaceId: restaurant.googlePlaceId,
                affiliation: restaurant.affiliation,
                sponsorGiftPoints: restaurant.sponsorGiftPoints,
                affiliateGiftPoints: restaurant.affiliateGiftPoints,
                sponsorMessage: restaurant.sponsorMessage,
                wheelGifts: restaurant.wheelGifts,
            },
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const addPointsToUser = async (
    restaurantId: string,
    userId: string,
    points: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(`/user/add-points/${restaurantId}/${userId}`, { points }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const getLoyaltyLogs = async (
    page: number,
    limit: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .get(`/user/loyalty-logs?limit=${limit}&page=${page}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const removePointsToUser = async (
    restaurantId: string,
    userId: string,
    points: number,
): Promise<{ data: any; error: any }> => {
    const res: any = await axios
        .put(
            `/user/remove-points/${restaurantId}/${userId}`,
            { points },
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
