const isValidVATNumber = (vatNumber: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(/^[A-Z]{2}[0-9A-Z\+\*\.]{2,12}$/);
    return regex.test(vatNumber);
};

const isValidSiren = (siren: string): boolean => {
   if (siren) siren = siren.replace(/\s/g, '');
    const regex = new RegExp(/^[0-9]{9}$/);
    return regex.test(siren);
}

const isValidEmail = (email: string): boolean => {
    const regex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return regex.test(email);
}

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp(/^[0-9\-\+]+$/);
    return regex.test(phoneNumber);
}

const isFormErrored = (object: any, validationRules: any[]): any => {
    for (const [field, validationFn] of Object.entries(validationRules))
        if (!validationFn(object[field]))
            return true;
    return false;
}

export { isValidVATNumber, isValidSiren, isValidEmail, isValidPhoneNumber, isFormErrored };