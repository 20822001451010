import { useNavigate } from "react-router-dom";

import Card from "components/card";
import chevron from "../../assets/svg/chevron.svg";
import { dateToFrench } from "utils/date";

const DeliveryCard = (props: {
    external: boolean;
    date: string;
    displayDate?: boolean;
	customersNbr: number;
    ordersNbr: number;
    products: any;
	extra?: string;
	deliveryTourName: string;
	deliveryTourId: string;
    deliveryId: string;
	key: number;
    status?: string;
}) => {
    const { external, date, displayDate, ordersNbr, products, extra, deliveryTourName, deliveryId, key, status } = props;
	const nav = useNavigate();
    return (
        <Card
            extra={`flex flex-col w-full h-[185px] !p-4 3xl:p-![18px] bg-white border border-[#7090B0]/[0.25] ${extra}`}
            key={key}
        >
            <div
                className="flex flex-col justify-between h-full w-full hover:cursor-pointer"
                onClick={() =>
                    nav(
                        `/delivery${external ? "-ext" : ""}/${
                            date && date.split("T")[0]
                        }/${deliveryId}`,
                    )
                }
            >
                <div className="flex items-center justify-between px-1 md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
                    <div className="mb-2">
                        <p className="text-lg font-bold text-navy-700 dark:text-white">
                            {deliveryTourName
                                ? deliveryTourName
                                : "Tournée par défaut"}
                        </p>
                        {displayDate && (
                            <p className="text-xs text-navy-700 dark:text-white">
                                {dateToFrench(date)}
                            </p>
                        )}
                        <p className="mt-2 items-center text-sm text-[#A3AED0] dark:text-white">
                            {ordersNbr} commande{ordersNbr > 1 && "s"}{" "}
                            contenant:
                        </p>
                        {products
                            .slice(0, 2)
                            .map((item: any, index: number) => {
                                return (
                                    <p className="flex gap-0 items-center text-sm text-[#A3AED0] dark:text-white">
                                        <img src={chevron} alt="chevron" />{" "}
                                        {item.quantity} {item.unity} {item.name}
                                        .
                                    </p>
                                );
                            })}
                        {products.length > 2 && (
                            <p className="ml-[24px] items-center text-sm text-[#A3AED0] dark:text-white">
                                {`+ ${products.length - 2} autre${
                                    products.length - 2 > 1 ? "s" : ""
                                } produit${
                                    products.length - 2 > 1 ? "s" : ""
                                }.`}
                            </p>
                        )}
                    </div>
                </div>
                <div className="absolute bottom-0 mb-2 items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
                    <div className="flex">
                        <p className="text-xs font-bold text-red-300 dark:text-white">
                            {new Date(date) < new Date(new Date().setHours(0, 0, 0, 0)) &&
                                status === "pending" &&
                                `Délai de livraison dépassé !`}{" "}
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default DeliveryCard;
