import Card from "components/card";

const BusinessInfo = () => {
    return (
        <Card extra={"w-full px-6 py-6 border"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-medium">Votre abonnement</h4>
                <p className="mt-2 text-base text-gray-400">
                    Vous pouvez modifier les informations de facturation
                </p>
                <button
                    className={`p-5 linear mt-4 w-full rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                    onClick={() =>
                        window.open(
                            "https://billing.stripe.com/p/login/7sI14Vbzv72lgRaaEE",
                            "_blank",
                        )
                    }
                >
                    Ouvrir portail de facturation
                </button>
            </div>
            {/* inputs */}
        </Card>
    );
};

export default BusinessInfo;
