import { getStaticQRCodeUrl } from "interfaces/presentationMode";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const PrintQr = () => {
    const [staticUrl, setStaticUrl] = useState("");

    const initStaticUrl = async () => {
        const data = await getStaticQRCodeUrl();
        setStaticUrl(data.url);
    };

    useEffect(() => {
        initStaticUrl();
    }, []);

    return (
        <div className="">
            <p className="pt-[150px] text-[45px] text-center font-bold">
                QR code à imprimer
            </p>
            <QRCode
                size={200}
                className="mt-10 flex mx-auto p-3 border-4 border-[#1f2937] rounded-xl"
                value={staticUrl}
            />
            <p className="mt-10 text-center text-lg">
                Vous pouvez aussi générer un nouveau QR-code et le rediriger
                vers:
            </p>
            <p className="mt-2 text-center text-xs">{staticUrl}</p>
            <p className="text-xs absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                Made by Fidiz.
            </p>
        </div>
    );
};

export default PrintQr;
