import GoBack from "components/actions/GoBack";
import { getEmailModel, updateEmailModel } from "interfaces/emailModel";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const advices = [
    "Inclure le nom de votre établissement.",
    "Le message doit être court et concis.",
    "Utiliser un ton amical et engageant.",
    "Intégrer 'STOP, pour ne plus recevoir de SMS'.",
];

const EditSmsModel = () => {
    const emailEditorRef = useRef(null);
    const nav = useNavigate();
    const params = useParams();
    const [emailModel, setEmailModel] = useState(null);

    const handleChange = (e: any) => {
        setEmailModel({ ...emailModel, [e.target.id]: e.target.value });
    };

    const saveTemplate = async () => {
        const email = {
            name: emailModel.name,
            text: emailModel.text,
        };
        const res = await updateEmailModel(params.id, email);
        if (res) nav("/email-model");
    };

    const initEmailModels = async () => {
        const email = await getEmailModel(params.id);
        if (email) {
            setEmailModel(email);
        }
    };

    useEffect(() => {
        initEmailModels();
    }, []);

    return (
        <>
            <GoBack text="Mes templates" />
            <div className="mt-2 lg:p-5 grid grid-cols-12 gap-1">
                <div className="col-span-12 lg:col-span-6">
                    <div>
                        <input
                            id="name"
                            key={"name"}
                            type="text"
                            placeholder="Nom du template"
                            value={emailModel?.name}
                            className="p-2 w-[300px] h-[40px] rounded-xl border-xl border border-gray-300"
                            onChange={handleChange}
                        />
                    </div>
                    <textarea
                        id="text"
                        className="mt-4 w-full lg:w-[500px] h-[320px] px-4 py-2 focus:outline-none focus:ring rounded-xl border-xl border border-gray-300 transition-colors duration-300"
                        placeholder="Contenue du SMS"
                        onChange={handleChange}
                        value={emailModel?.text}
                    ></textarea>
                </div>
                <div className="col-span-12 lg:col-span-6">
                    {/* Phone preview */}
                    <div className="flex justify-center w-full">
                        <div className="">
                            <div className="w-full min-w-full h-128 bg-gray-100 rounded-3xl px-4 relative pb-4 shadow-lg">
                                <p className="pt-2 text-sm text-gray-700 text-center">
                                    Prévisualisation
                                </p>
                                <div className="mt-1 bg-gray-300 w-16 h-1.5 rounded-full mx-auto mb-2"></div>
                                <div className="bg-white rounded-xl p-3 shadow">
                                    <div className="text-sm text-gray-700">
                                        <p>
                                            De:{" "}
                                            <span className="font-bold">
                                                36105
                                            </span>
                                        </p>
                                        <p className="mt-2 text-xs">
                                            {emailModel?.text}
                                        </p>
                                        <p className="mt-4 text-right text-blue-600">
                                            Voir plus
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 border rounded-xl p-3 w-full">
                        <h1 className="text-base font-bold">
                            💡 Les conseils de nos experts
                        </h1>
                        <p className="text-xs mt-1">
                            Suivez ces conseils pour optimiser votre campagne
                            SMS
                        </p>
                        {/* Advices */}
                        <div>
                            {advices.map((advice, index) => (
                                <div className="mt-3 flex items-center">
                                    <input
                                        type="checkbox"
                                        className="mr-2"
                                        defaultChecked={false}
                                    />
                                    <p className="text-sm">{advice}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3 flex justify-center lg:justify-end">
                <button
                    className={`p-2 linear h-[40px] rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                    onClick={saveTemplate}
                >
                    Sauvegarder le SMS
                </button>
            </div>
        </>
    );
};

export default EditSmsModel;
