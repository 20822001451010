import { getMyInfoUser } from "interfaces/user";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import DynamicQRCode from "./components/DynamicQRCode";
import BurgerImgage from "assets/img/burger.jpeg";

const PresentationPage = () => {
    const [email, setEmail] = useState(null);
    const [user, setUser] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const mode = queryParameters.get("mode");

    const initUser = async () => {
        const user = await getMyInfoUser();
        if (user) {
            setUser(user.data);
        }
    };

    useEffect(() => {
        if (timeLeft === 0) {
            window.location.reload();
            setTimeLeft(null);
        }

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    useEffect(() => {
        const token = localStorage.getItem("user-token");
        if (!token) return;
        initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <div className="pt-1 lg:pt-5 h-screen">
            <div
                className={`mt-2 grid grid-cols-1 ${
                    mode === "phone" ? "" : "xl:grid-cols-2"
                }  gap-10 p-10 py-[70px] mx-5 shadow-xl rounded-xl border-4 border-[#1f2937]`}
            >
                <div className="px-5">
                    <p className="text-3xl font-han text-center">
                        Envie de cumuler des points ?
                    </p>
                    <p className="mt-5 text-lg text-center">
                        Bonne nouvelle, on récompense ta fidélité 🎉
                    </p>
                    {isConnected ? (
                        <div className="mt-10">
                            <p className="text-3xl text-center">Connecté 🥳</p>
                            <p className="mt-3 text-center">
                                Vous pouvez retourner sur votre téléphone !
                            </p>
                            <p className="mt-3 text-center text-xs">
                                La tablette sera prête pour le prochain
                                utilisateur dans {timeLeft} secondes
                            </p>
                        </div>
                    ) : (
                        <div className="mt-7">
                            <DynamicQRCode
                                onConnection={() => {
                                    setIsConnected(true);
                                    setTimeLeft(15);
                                }}
                            />
                        </div>
                    )}
                </div>
                {mode !== "phone" && (
                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
                        <img
                            src={BurgerImgage}
                            alt="food"
                            className="hidden lg:block rounded-xl shadow-2xl mt-10 h-[300px] 2xl:h-full 2xl:mt-3"
                        />
                        {/* <img
                    src={Burger2Image}
                    alt="food"
                    className="rounded-2xl h-[270px]"
                /> */}
                        {/* <img
                    src={Burger3Image}
                    alt="food"
                    className="rounded-2xl h-[270px]"
                />
                <img src={BurgerImgage} className="rounded-2xl h-[270px]" /> */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PresentationPage;
