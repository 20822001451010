import GoBack from "components/actions/GoBack";
import {
    getEmailModel,
    sendTestEmail,
    updateEmailModel,
} from "interfaces/emailModel";
import { getMyInfo } from "interfaces/user";
import { useRef, useState } from "react";

import EmailEditor from "react-email-editor";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditEmailModel = () => {
    const emailEditorRef = useRef(null);
    const nav = useNavigate();
    const params = useParams();
    const [emailModel, setEmailModel] = useState(null);
    const [user, setUser] = useState(null);
    const [testEmailSend, setTestEmailSend] = useState(false);

    const handleChange = (e: any) => {
        setEmailModel({ ...emailModel, [e.target.id]: e.target.value });
    };

    const saveTemplate = () => {
        emailEditorRef.current.editor.exportHtml(
            async (data: any) => {
                const { design, html } = data;
                const email = {
                    name: emailModel.name,
                    json: JSON.stringify(design),
                    html: html,
                };
                const res = await updateEmailModel(params.id, email);
                if (res) nav("/email-model");
            },
            { minify: true },
        );
    };

    const initEmailModels = async () => {
        const email = await getEmailModel(params.id);
        if (email) {
            setEmailModel(email);
            const json = JSON.parse(email.json);
            emailEditorRef.current.editor.loadDesign(json);
        }
    };

    const initUser = async () => {
        const res: any = await getMyInfo();
        if (res) setUser(res.data);
    };

    const onLoad = async () => {
        initEmailModels();
        initUser();
    };

    const onReady = () => {
        // editor is ready
        console.log("onReady");
    };

    const triggerTestEmail = async () => {
        emailEditorRef.current.editor.exportHtml(
            async (data: any) => {
                const { design, html } = data;
                const email = {
                    name: emailModel.name,
                    json: JSON.stringify(design),
                    html: html,
                };
                const emailSaved = await updateEmailModel(params.id, email);
                if (emailSaved) {
                    const res = await sendTestEmail(params.id);
                    if (res) {
                        setTestEmailSend(true);
                        toast.success("Email de test envoyé");
                    }
                }
            },
            { minify: true },
        );
    };

    return (
        <>
            <GoBack text="Mes templates" />
            <div className="mt-2 relative border p-5 border-gray-300 rounded-xl">
                <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                    options={{
                        locale: "fr",
                        projectId: 240269,
                    }}
                />
                <div className="mt-3 flex justify-between">
                    <div className="">
                        <p className="text-sm">email: "{user?.email}"</p>
                        <p className="text-sm">object: "email de test"</p>
                        {testEmailSend ? (
                            <p className="text-sm text-green-600">
                                Email de test envoyé
                            </p>
                        ) : (
                            <p
                                className="mt-1 text-sm text-blue-600 cursor-pointer"
                                onClick={triggerTestEmail}
                            >
                                Envoyer l'email de test
                            </p>
                        )}
                    </div>
                    <div className="flex gap-4">
                        <div className="flex my-auto">
                            <input
                                id="name"
                                key={"name"}
                                type="text"
                                placeholder="Nom du template"
                                value={emailModel?.name}
                                className="p-2 w-[300px] h-[40px] rounded-xl border-xl border border-gray-300"
                                onChange={handleChange}
                            />
                        </div>
                        <button
                            className={`flex my-auto p-2 linear h-[40px] rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70`}
                            onClick={saveTemplate}
                        >
                            Sauvegarder le template
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditEmailModel;
