// Assets
import { MdArrowForward, MdFlag } from "react-icons/md";
import Card from "components/card";

const OnBoardingCard = (props: {
    title: string;
    actualStep: number;
    totalStep: number;
    description: string;
    onClick: any;
    nextText?: string;
}) => {
    const { title, actualStep, totalStep, description, onClick, nextText } = props;
    return (
        <Card extra={"w-full p-4"}>
            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Toola en 10 minutes: {title}
            </h4>
            <div className="mt-3 flex items-center justify-between">
                <p className="text-base font-medium uppercase text-gray-600">
                    Étape {actualStep} sur {totalStep}
                </p>
                <p className="text-lg text-green-500">
                    <MdFlag />
                </p>
            </div>
            <div className="mt-[20px] grid w-full grid-cols-5 gap-2">
                {Array.from(Array(totalStep)).map(
                    (value: any, index: number) => (
                        <div
                            key={index}
                            className={`h-2 w-full rounded-[36px] ${
                                index + 1 <= actualStep
                                    ? "bg-green-500"
                                    : "bg-lightPrimary dark:bg-white/10"
                            }`}
                        />
                    ),
                )}
            </div>
            {/* what you'll learn */}
            <div className="flex justify-between mt-3">
                <div>
                    <p className="text-navy-700 dark:text-white">
                        {description}
                    </p>
                </div>
                <div
                    className="mt-5 flex gap-4 hover:cursor-pointer step-onboarding-next"
                    onClick={onClick}
                >
                    <p className="ml-3 text-navy-700 dark:text-white">
                        {nextText ? nextText : "Étape suivante"}
                    </p>
                    <MdArrowForward size={25} />
                </div>
            </div>
        </Card>
    );
};

export default OnBoardingCard;
