import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { getRestaurantPublicInfo, getWheelPrize } from "interfaces/restaurant";
import { claimPoints, getMyInfoUser, updateUserInfo } from "interfaces/user";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import env from "variables/config";
import { ToastContainer } from "react-toastify";
import { Wheel } from "react-custom-roulette";
import { MdPhone } from "react-icons/md";
import QRCode from "react-qr-code";

import arrowImage from "assets/img/arrow.png";

const GameWheel = () => {
    const params = useParams();
    const [email, setEmail] = useState(null);
    const [user, setUser] = useState(null);
    const [points, setPoints] = useState(0);
    const [displayConfetti, setDisplayConfetti] = useState(false);
    const [restaurant, setRestaurant] = useState(null);
    const [claimedReward, setClaimedReward] = useState(null);
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [data, setData] = useState(null);
    const [phone, setPhone] = useState("");
    const [prize, setPrize] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const nav = useNavigate();

    const handlePhoneChange = (e: any) => {
        setPhone(e.target.value);
    };

    const login = (token: string) => {
        const id = params.id;
        const sessionToken = new URLSearchParams(window.location.search).get(
            "token",
        );
        axios
            .post(`${env.apiUrl}/auth/access`, { token, id, sessionToken })
            .then((response: any) => {
                if (response.data.token) {
                    setEmail(response.data.email);
                    localStorage.setItem("user-token", response.data.token);
                    localStorage.setItem("email", response.data.email);
                }
            })
            .catch((error: any) => {
                nav("/login-error");
            });
    };

    const addPhoneNumber = async () => {
        if (phone === "") return;
        const res = await updateUserInfo({ phone });
        if (res) {
            setUser((prev: any) => ({ ...prev, phone }));
        }
    };

    const Login = () => {
        return (
            <div className="pt-[80px]">
                <p className="px-10 text-[35px] text-center font-han">
                    Tournez la roue pour gagner des points et des cadeaux
                </p>
                <p className="mt-10 text-center text-2xl">
                    Connecte toi en 1 clic
                </p>
                <svg
                    width="446"
                    height="479"
                    viewBox="0 0 446 479"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="rotate-90 mx-auto mt-4 h-20 w-20"
                >
                    <path
                        d="M379.831 88.044a5.002 5.002 0 0 1-4.49-7.208 5.003 5.003 0 0 1 2.37-2.322c10.5-4.89 18.17-8.37 27.06-12.41 7.45-3.37 15.74-7.13 27.3-12.45-7.28-7.77-13.32-14.31-19.2-20.68-7.4-8-14.39-15.59-22.87-24.52a5.017 5.017 0 0 1-1.045-1.65 5.016 5.016 0 0 1 .095-3.828 5.015 5.015 0 0 1 2.775-2.641 5.004 5.004 0 0 1 5.425 1.22c8.53 9 15.54 16.58 23 24.63 7.1 7.68 14.43 15.63 23.89 25.66a5.007 5.007 0 0 1 1.292 4.396 5.001 5.001 0 0 1-2.832 3.603c-15 6.93-24.92 11.42-33.67 15.39-8.75 3.97-16.52 7.49-27 12.36-.659.3-1.376.454-2.1.45z"
                        fill="#000"
                    ></path>
                    <path
                        d="M79.78 478.756c-1.35 0-2.7 0-4.08-.12-25.63-1.56-46.32-12.36-59.82-31.24-16.57-23.16-18.59-53.11-13.25-72.53a5.007 5.007 0 0 1 6.156-3.495 5.002 5.002 0 0 1 3.495 6.155c-4.72 17.12-2.92 43.57 11.73 64.06 11.69 16.34 29.77 25.7 52.3 27.07 29.19 1.76 52.9-19.62 72.49-65.39 17.76-41.47 29.79-97.61 40.41-147.13 8.57-40 16.67-77.76 26.78-104.4 7.47-19.69 25.84-56.58 65-82.49 42.77-28.32 96.84-34.65 160.72-18.81a4.995 4.995 0 0 1 3.192 2.241 5.014 5.014 0 0 1 .61 3.853 4.998 4.998 0 0 1-6.212 3.606c-150.85-37.39-201.91 63.42-213.96 95.14-9.84 25.94-17.86 63.35-26.35 103-10.71 50-22.86 106.6-41 149-20.59 48.02-46.21 71.48-78.21 71.48z"
                        fill="#000"
                    ></path>
                </svg>
                <div className="flex mt-10">
                    <GoogleLogin
                        onSuccess={(credentialResponse) => {
                            login(credentialResponse.credential);
                        }}
                        containerProps={{
                            className: "mx-auto",
                        }}
                        shape="circle"
                        size="large"
                        theme="outline"
                        logo_alignment="center"
                        onError={() => {
                            console.log("Login Failed");
                        }}
                    />
                </div>
                <div
                    className="flex mt-5 mx-auto rounded-full bg-white border border-xl cursor-pointer w-[250px] px-4"
                    onClick={() =>
                        nav(
                            `/account/${params.id}?token=${new URLSearchParams(
                                window.location.search,
                            ).get("token")}`,
                        )
                    }
                >
                    <MdPhone className="flex my-auto text-lg text-[#1f2937]" />
                    <p className="py-3 mx-auto text-center text-sm">
                        Se connecter par téléphone
                    </p>
                </div>
                <p className="flex mt-5 text-xs text-center mx-auto w-[300px]">
                    En m'inscrivant j’accepte de recevoir des promotions par
                    email ou par SMS
                </p>
            </div>
        );
    };

    const handleSpinClick = async () => {
        if (!mustSpin) {
            const res = await getWheelPrize(params.id);
            if (res) {
                setMustSpin(true);
                setPrizeNumber(res.prizeIndex);
                setCoupon(res.coupon);
            }
        }
    };

    const EndScreen = () => {
        return (
            <div className="pt-10">
                <p className="text-center font-han text-[32px]">Votre cadeau</p>
                <p className="text-center text-lg">
                    Présentez ce QR-code en caisse
                </p>
                <div>
                    <QRCode
                        size={300}
                        className="mt-10 flex mx-auto p-3 border-4 mb-2 border-[#1f2937] rounded-xl"
                        value={coupon}
                    />
                </div>
            </div>
        );
    };

    const UserSpace = () => {
        return (
            <>
                <div className="pt-4">
                    <div className="rounded-2xl py-4 mx-5">
                        <p className="mt-4 text-center font-han text-[32px]">
                            Tente ta chance {prize}
                        </p>
                    </div>
                    <ToastContainer />
                    <p className="text-center text-xl px-10">
                        Tourne la roue pour essayer de gagner un cadeau !
                    </p>
                    <svg
                        width="446"
                        height="479"
                        viewBox="0 0 446 479"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="rotate-90 mx-auto mt-4 h-20 w-20"
                    >
                        <path
                            d="M379.831 88.044a5.002 5.002 0 0 1-4.49-7.208 5.003 5.003 0 0 1 2.37-2.322c10.5-4.89 18.17-8.37 27.06-12.41 7.45-3.37 15.74-7.13 27.3-12.45-7.28-7.77-13.32-14.31-19.2-20.68-7.4-8-14.39-15.59-22.87-24.52a5.017 5.017 0 0 1-1.045-1.65 5.016 5.016 0 0 1 .095-3.828 5.015 5.015 0 0 1 2.775-2.641 5.004 5.004 0 0 1 5.425 1.22c8.53 9 15.54 16.58 23 24.63 7.1 7.68 14.43 15.63 23.89 25.66a5.007 5.007 0 0 1 1.292 4.396 5.001 5.001 0 0 1-2.832 3.603c-15 6.93-24.92 11.42-33.67 15.39-8.75 3.97-16.52 7.49-27 12.36-.659.3-1.376.454-2.1.45z"
                            fill="#000"
                        ></path>
                        <path
                            d="M79.78 478.756c-1.35 0-2.7 0-4.08-.12-25.63-1.56-46.32-12.36-59.82-31.24-16.57-23.16-18.59-53.11-13.25-72.53a5.007 5.007 0 0 1 6.156-3.495 5.002 5.002 0 0 1 3.495 6.155c-4.72 17.12-2.92 43.57 11.73 64.06 11.69 16.34 29.77 25.7 52.3 27.07 29.19 1.76 52.9-19.62 72.49-65.39 17.76-41.47 29.79-97.61 40.41-147.13 8.57-40 16.67-77.76 26.78-104.4 7.47-19.69 25.84-56.58 65-82.49 42.77-28.32 96.84-34.65 160.72-18.81a4.995 4.995 0 0 1 3.192 2.241 5.014 5.014 0 0 1 .61 3.853 4.998 4.998 0 0 1-6.212 3.606c-150.85-37.39-201.91 63.42-213.96 95.14-9.84 25.94-17.86 63.35-26.35 103-10.71 50-22.86 106.6-41 149-20.59 48.02-46.21 71.48-78.21 71.48z"
                            fill="#000"
                        ></path>
                    </svg>
                    {claimedReward && (
                        <p className="mt-10 text-[35px] text-center font-bold">
                            {claimedReward.name} gagné !
                        </p>
                    )}
                </div>
            </>
        );
    };

    const initUser = async () => {
        const user = await getMyInfoUser();
        if (user) {
            setUser(user.data);
        } else {
            localStorage.removeItem("user-token");
            localStorage.removeItem("email");
            window.location.reload();
        }
    };

    const initRestaurant = async () => {
        const id = params.id;
        const restaurant = await getRestaurantPublicInfo(id);
        if (restaurant) {
            setRestaurant(restaurant);
            setData(restaurant.wheelGifts);
        } else {
            localStorage.removeItem("user-token");
            localStorage.removeItem("email");
            window.location.reload();
        }
    };

    useEffect(() => {
        const id = params.id;

        if (user && user.rewards) {
            const userPoints = user.rewards.find((r: any) => r.id === id);
            if (userPoints) setPoints(userPoints.points);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const getSessionTokenPoints = async () => {
        const restaurantId = params.id;
        const sessionToken = new URLSearchParams(window.location.search).get(
            "token",
        );
        const res = await claimPoints(restaurantId, sessionToken);
        if (res) {
            const points = res.data.points;
            for (let i = 0; i <= points; i++) {
                setTimeout(() => {
                    setPoints(i);
                }, i * 100);
            }
        } else {
            localStorage.removeItem("user-token");
            localStorage.removeItem("email");
            window.location.reload();
        }
    };

    const handleStopSpinning = async () => {
        setPrize(data[prizeNumber].option);
        setMustSpin(false);
    };

    useEffect(() => {
        const token = localStorage.getItem("user-token");
        if (!token) return;
        initUser();
        initRestaurant();
        getSessionTokenPoints();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        const email = localStorage.getItem("email");
        if (email) setEmail(email);
    }, []);

    return (
        <div className="relative min-h-screen">
            {displayConfetti && <Confetti />}
            {!email && <Login />}
            {user && user.phone && email && !prize && <UserSpace />}
            {prize && <EndScreen />}
            {user && user.phone && email && data && !prize && (
                <div className="mt-5 flex mx-auto justify-center">
                    <div>
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={data}
                            backgroundColors={["#c2e5ff", "#5167f6", "#e6e6e6"]}
                            perpendicularText={true}
                            pointerProps={{
                                src: arrowImage,
                                style: {
                                    width: "50px",
                                    height: "50px",
                                    right: "10px",
                                    rotate: "135deg",
                                },
                            }}
                            fontSize={20}
                            fontFamily="Arial"
                            fontStyle=""
                            outerBorderWidth={2}
                            innerBorderWidth={2}
                            radiusLineWidth={2}
                            onStopSpinning={handleStopSpinning}
                        />
                        <div className="flex justify-center mt-2">
                            <button
                                className={`p-5 linear rounded-xl bg-primary text-white py-3 transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                onClick={handleSpinClick}
                            >
                                Tourner la roue
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* Ask phone number at register */}
            {user && email && !user.phone && (
                <div className="pt-[50px]">
                    <p className="mt-10 text-[35px] text-center font-han">
                        Finaliser mon inscription
                    </p>
                    <ToastContainer />
                    <p className="px-4 mt-8 text-center text-lg">
                        Aussi simple de finaliser ton inscription que de gagner
                        nos cadeaux
                    </p>
                    <div className="mt-10 flex mx-auto">
                        <input
                            id="phoneNumber"
                            key={"phoneNumber"}
                            type="text"
                            value={phone}
                            placeholder="Numéro de téléphone"
                            className="mx-auto p-2 rounded-xl w-[300px] h-[60px] border-xl border-2 border-[#1f2937]"
                            onChange={handlePhoneChange}
                        />
                    </div>
                    <button
                        className="mt-10 flex mx-auto rounded-2xl px-10 bg-white py-4 border-2 border-[#1f2937] border-xl cursor-pointer"
                        onClick={addPhoneNumber}
                    >
                        <p className="flex mx-auto text-base">
                            Inscription, check !
                        </p>
                    </button>
                </div>
            )}
        </div>
    );
};

export default GameWheel;
