import CreateButton from "components/buttons/CreateButton";
import { markAsSubscribed } from "interfaces/stripe";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Import img from src/assets

const OrderSuccess = () => {
    const nav = useNavigate();
    const [subscribed, setSubscribed] = useState(false);

    const subscribe = async () => {
        const res = await markAsSubscribed();
        if (res) {
            setSubscribed(true);
            localStorage.removeItem("waiting-checkout");
        }
    };

    useEffect(() => {
        subscribe();
    }, []);

    return (
        <div className="h-screen w-full bg-gray-100">
            <div className="mx-auto my-auto flex max-w-screen-xl flex-col items-center pb-24">
                <p className="mt-10 text-[100px]">🥳</p>
                <h2 className="mb-2 mt-10 text-center text-[35px] font-bold text-navy-700 dark:text-white">
                    Vous êtes maintenant abonné à Fidiz !
                </h2>
                {subscribed && (
                    <CreateButton
                        extra="mt-10 bg-talk-500"
                        value="Retourner au site"
                        onClick={() => nav("/")}
                    />
                )}
            </div>
        </div>
    );
};

export default OrderSuccess;
