import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const disconnect = async (id: string) => {
    const res: any = await axios
        .delete(`/connect/${id}`, config())
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const connectToMeta = async (code: string) => {
    const res: any = await axios
        .get(`/connect/meta`, {
            params: { code },
            ...config(),
        })
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const connectToGMB = async (code: string) => {
    const res: any = await axios
        .get(`/connect/gmb`, {
            params: { code },
            ...config(),
        })
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getMyConnections = async () => {
    const res: any = await axios.get(`/connect`, config()).catch(errorHandler);
    if (!res) return null;
    return res.data;
};
