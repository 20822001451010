import Pagination from "components/pagination/Pagination";
import { useState } from "react";

const Ugc = () => {
    const items: any = [
        {
            attachments: [
                {
                    media_url:
                        "https://iframe.mediadelivery.net/embed/196373/7409216b-5c7a-47d4-9967-7c8e3a0353b9?autoplay=false&loop=false&muted=false&preload=true&responsive=true",
                    media_url_type: "iframe",
                },
            ],
            page_name: "Restaurant Juan-les-Pins",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://youdji-production-2.b-cdn.net/media/8adc82f1-f254-406f-ad48-882b53c34d12/snaptik-app-7324742187705568544-hd-1.mov",
                    media_url_type: "video",
                },
            ],
            page_name: "Restaurant Italien",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://iframe.mediadelivery.net/embed/196373/a8a92ecd-c645-47e5-84e5-353b1243f130?autoplay=false&loop=false&muted=false&preload=true&responsive=truehttps://youdji-production-2.b-cdn.net/media/8adc82f1-f254-406f-ad48-882b53c34d12/snaptik-app-7324742187705568544-hd-1.mov",
                    media_url_type: "iframe",
                },
            ],
            page_name: "Brunch Parisien",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://s3.eu-west-2.wasabisys.com/takema-portfolios/portfolios/1689201000019x245010680703877120_vanina-ugc/takema_vanina-ugc_03877120_20240130_115540_35512_1.mp4#t=0.1",
                    media_url_type: "video",
                },
            ],
            page_name: "Fluffy Pancakes",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://s3.eu-west-2.wasabisys.com/takema/portfolios/takema_IMG_8129.MOV#t=0.1",
                    media_url_type: "video",
                },
            ],
            page_name: "Lobsta",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://s3.eu-west-2.wasabisys.com/takema/portfolios/1708093078887x542657211553284100_romane-ugc/takema_romane-ugc_53284100_20240216_152218_33_2.MOV#t=0.1",
                    media_url_type: "video",
                },
            ],
            page_name: "Bar à jeux",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://s3.eu-west-2.wasabisys.com/takema-portfolios/portfolios/1704227953018x740225822641356800_hannahmarx/takema_hannahmarx_41356800_20240103_150927_81265_1.mp4#t=0.1",
                    media_url_type: "video",
                },
            ],
            page_name: "Petit déjeuner luxueux",
        },
        {
            attachments: [
                {
                    media_url:
                        "https://s3.eu-west-2.wasabisys.com/takema/portfolios/1704227953018x740225822641356800_hannahmarx/takema_hannahmarx_41356800_20240103_150927_33632_1.MOV#t=0.1",
                    media_url_type: "video",
                },
            ],
            page_name: "Restaurant côte d'azur",
        },
    ];

    const [page, setPage] = useState(1);

    const AdCard = (props: { ad: any }) => {
        const { ad } = props;
        const { page_name, attachments } = ad;
        const media = attachments[0].media_url;
        const type = attachments[0].media_url_type;

        return (
            <div className="border rounded-xl p-6">
                {type === "image" && (
                    <div className="h-[200px] w-full">
                        <img
                            src={media}
                            alt="Tablet"
                            className="flex mx-auto h-[300px] rounded-xl"
                        />
                    </div>
                )}
                {type === "video" && (
                    <video
                        src={media}
                        className="rounded-xl h-[400px] w-full"
                        controls
                    ></video>
                )}
                {type === "iframe" && (
                    <iframe
                        src={media}
                        className="rounded-xl h-[400px] w-full"
                    ></iframe>
                )}
                <div>
                    <h1 className="mt-4 text-lg font-bold">{page_name}</h1>
                    <p className="text-sm mt-1">Publicité UGC</p>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-3 ">
            <div className="">
                <h1 className="mt-5 text-xl md:text-2xl md:mt-0 font-bold">
                    Analyse des créations UGC
                </h1>
                <p className="mt-3 text-gray-600">
                    Inspirez vous des créations de vos concurrents et découvrez
                    des créateurs avec qui collaborer.
                </p>
                <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {items.slice((page - 1) * 6, page * 6).map((item: any) => (
                        <AdCard ad={item} />
                    ))}
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[6]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
        </div>
    );
};

export default Ugc;
