import { bu } from "@fullcalendar/core/internal-common";
import GoBack from "components/actions/GoBack";
import { get } from "https";
import { getRankingList } from "interfaces/ranking";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Keywords = () => {
    const [businesses, setBusinesses] = useState([]);
    const params = useParams();
    const { id } = params;

    const getImage = (business: any) => {
        return `https://placehold.co/500x500?text=${business.displayName.text}`;
        if (business.thumbnail) return business.thumbnail;
        const randomImage = Math.floor(
            Math.random() * business?.images?.length,
        );
        if (business.images) return business.images[randomImage];
    };

    const BusinessCard = (props: { business: any; position: number }) => {
        const { business, position } = props;
        return (
            <div className="relative border rounded-lg p-4 shadow-md bg-white">
                {/* Position */}
                <div
                    className={`absolute top-0 right-0 ${
                        business.isMe ? "bg-blue-700" : "bg-talk-500"
                    } opacity-90 text-white px-2 py-1 rounded-tr-lg rounded-bl-lg`}
                >
                    #{position}
                </div>
                <div className="flex items-start space-x-4">
                    <img
                        src={getImage(business)}
                        alt={business.displayName.text}
                        className="w-20 h-20 rounded-md object-cover"
                        onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src = `https://placehold.co/500x500?text=${business.displayName.text}`;
                        }}
                    />
                    <div>
                        {business.isMe ? (
                            <h2 className="text-lg font-bold text-blue-800">
                                Vous
                            </h2>
                        ) : (
                            <h2 className="text-lg font-semibold">
                                {business.displayName.text}
                            </h2>
                        )}
                        <p className="text-gray-500">
                            {business.type} · {business.formattedAddress}
                        </p>
                        <p className="text-yellow-500">
                            {business.rating} ★ ({business.userRatingCount}{" "}
                            avis)
                        </p>
                        <p className="text-sm italic text-gray-600 mt-1">
                            "{business.description}"
                        </p>
                    </div>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                    <p>{business.hours}</p>
                    <p
                        className="text-blue-500 hover:underline cursor-pointer"
                        onClick={() =>
                            window.open(business.googleMapsUri, "_blank")
                        }
                    >
                        Voir sur Google
                    </p>
                </div>
            </div>
        );
    };

    const BusinessList = (props: { businesses: any }) => {
        const { businesses } = props;
        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
                {businesses?.map((business: any, index: number) => (
                    <BusinessCard
                        key={index}
                        business={business}
                        position={index + 1}
                    />
                ))}
            </div>
        );
    };

    const initRankingsList = async () => {
        const res = await getRankingList(id);
        if (res) setBusinesses(res);
    };

    useEffect(() => {
        initRankingsList();
    }, []);

    return (
        <div className="min-h-screen">
            <GoBack />
            <main>
                <BusinessList businesses={businesses} />
            </main>
        </div>
    );
};

export default Keywords;
