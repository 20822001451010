import Card from "components/card";
import { MdOutlineCall, MdOutlineEdit, MdOutlineEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const CustomerInfoPreview = (props: { customer: any }) => {
    const { customer } = props;
    const navigate = useNavigate();

    return (
        <Card extra={"w-full !p-5 border col-span-12 sm:col-span-6 relative"}>
            {/* Header */}
            <div
                id="edit-customer"
                className="absolute top-5 right-5 hover:cursor-pointer"
                onClick={() => navigate(`/edit-customer/${customer?._id}`)}
            >
                <MdOutlineEdit />
            </div>
            <div className="flex w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    {customer && customer.email?.split("@")[0]}{" "}
                </h4>
            </div>
            <div className="text-sm sm:flex gap-5 my-auto">
                <div className="mt-2 sm:mt-0 flex gap-2">
                    <MdOutlineCall className="my-auto" size={20} />
                    <div>
                        <p className="font-medium">Numéro de téléphone</p>
                        <p>
                            {customer && customer.phone
                                ? customer.phoneInfo || customer.phone
                                : "+33- -- -- -- --"}
                        </p>
                    </div>
                </div>
                <div className="mt-5 sm:mt-0 flex gap-2">
                    <MdOutlineEmail className="my-auto" size={20} />
                    <div>
                        <p className="font-medium">Adresse mail</p>
                        <p className="">
                            {customer?.emailInfo || customer?.email}
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default CustomerInfoPreview;
