import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import env from "variables/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PhoneAuthentication = () => {
    const params = useParams();
    const [loginInfos, setLoginInfos] = useState({ phone: "", email: "" });
    const [accountExist, setAccountExist] = useState(true);
    const nav = useNavigate();

    const handleChange = (e: any) => {
        setLoginInfos((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const login = () => {
        const sessionToken = new URLSearchParams(window.location.search).get(
            "token",
        );
        const restaurantId = params.id;
        axios
            .post(`${env.apiUrl}/auth/phone-login`, {
                restaurantId,
                email: loginInfos.email,
                phone: loginInfos.phone,
            })
            .then((response: any) => {
                if (response.data.exist && response.data.token) {
                    localStorage.setItem("user-token", response.data.token);
                    localStorage.setItem("email", response.data.email);
                    nav(`/access/${restaurantId}?token=${sessionToken}`);
                } else {
                    setAccountExist(false);
                }
            })
            .catch((error: any) => {
                nav("/login-error");
            });
    };

    useEffect(() => {
        console.log(loginInfos);
    }, [loginInfos]);

    return (
        <div className="relative min-h-screen">
            <div className="pt-[50px]">
                <p className="mt-10 text-[35px] text-center font-han">
                    C'est parti pour l'aventure !
                </p>
                <ToastContainer />
                <p className="mt-10 text-center text-lg px-4">
                    Des cadeaux vous attendent ! Remplissez vos infos pour en
                    profiter !
                </p>
                {!accountExist && (
                    <div className="mt-10 flex mx-auto">
                        <input
                            id="email"
                            key={"email"}
                            type="text"
                            value={loginInfos.email}
                            placeholder="Addresse mail*"
                            className="mx-auto p-2 rounded-xl w-[300px] h-[60px] border-xl border-2 border-[#1f2937]"
                            onChange={handleChange}
                        />
                    </div>
                )}
                <div className="mt-10 flex mx-auto">
                    <input
                        id="phone"
                        key={"phone"}
                        type="text"
                        value={loginInfos.phone}
                        placeholder="Numéro de téléphone"
                        className="mx-auto p-2 rounded-xl w-[300px] h-[60px] border-xl border-2 border-[#1f2937]"
                        onChange={handleChange}
                    />
                </div>
                <button
                    className="mt-10 flex mx-auto rounded-2xl px-10 bg-white border-2 py-4 border-xl border-[#1f2937] cursor-pointer"
                    onClick={() => {
                        if (loginInfos.phone) {
                            login();
                        }
                    }}
                >
                    <p className="flex mx-auto text-base">
                        M'enregistrer / Me connecter
                    </p>
                </button>
            </div>
            <p className="text-xs absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                Made by Fidiz.
            </p>
        </div>
    );
};

export default PhoneAuthentication;
