import Pagination from "components/pagination/Pagination";
import { useState } from "react";

const Ads = () => {
    const items: any = [
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/482869251157448_88abc5c6.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Nachos (Ca\nes)",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-07-27T00:23:11",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-07-28T17:23:09.234000",
            lang_iso_code: "fr",
            last_seen_date: "2024-07-28T17:23:09.234000",
            format: "image",
            id: "482869251157448",
            link_url: null,
            link_url_domain: null,
            page_id: "100083031325790",
            page_link_url: "https://www.facebook.com/100083031325790",
            page_name: "Nachos (Cannes)",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-07-28T17:23:44.680145",
                comment: 0,
                haha: 0,
                like: 111,
                love: 9,
                share: 0,
                sorry: 0,
                total_reactions: 121,
                view: 0,
                wow: 1,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=482869251157448&id=100083031325790",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: false,
                    link_url:
                        "https://www.facebook.com/hashtag/frenchtacos?__eep__=6",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/3370221419916413_76191f7d.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "active",
                    title: null,
                    typ: "photo",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2022-11-03T10:30:09",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2022-11-03T12:28:41",
            lang_iso_code: "fr",
            last_seen_date: "2022-11-03T12:28:41",
            format: "image",
            id: "3370221419916413",
            link_url: null,
            link_url_domain: null,
            page_id: "1423986684539906",
            page_link_url: "https://facebook.com/tacosfrance",
            page_name: "Chamas Tacos",
            page_profile_image_url:
                "https://media2.dropispy.com/files/ZXzptRklVakJqU0UwMlRIazVhbUl5TlRCYVZ6VXdURzFHYTJNelFqVk1iVTUyWWxNNWFsbHFZekZaTWxadFQwTXdNazF0Um14TVZGSnJUVEpaZEZscWJHcE5lVEZzV2xSYWJFMUVaM3BPYlZVelRtcFZkV0Z1UW00PQ==",
            snapshot: {
                anger: null,
                at: "2022-11-03T12:28:41",
                comment: 0,
                haha: null,
                like: 6,
                love: 1,
                share: null,
                sorry: null,
                total_reactions: 7,
                view: null,
                wow: null,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/1423986684539906/posts/3370221419916413",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_802af0dc.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_0ce05ab3.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_576dd97e.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_d4645bdc.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_66955536.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_ad3daae8.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_090b1153.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_5190a989.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_e0ecb33e.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: "speedtacos66.fr",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/108412478884749_27a55e9c.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "SPEED TACOS 66",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2023-04-06T14:56:52",
            cta_text: "Order now",
            cta_type: "ORDER_NOW",
            first_seen_date: "2023-10-20T17:28:11.761000",
            lang_iso_code: "fr",
            last_seen_date: "2023-10-20T17:28:11.761000",
            format: "album",
            id: "108412478884749",
            link_url: null,
            link_url_domain: null,
            page_id: "100091479901679",
            page_link_url: "https://www.facebook.com/100091479901679",
            page_name: "SPEED TACOS 66",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2023-10-21T02:37:45.706263",
                comment: 7,
                haha: 0,
                like: 48,
                love: 3,
                share: 6,
                sorry: 0,
                total_reactions: 51,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100091479901679/posts/108412478884749",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: true,
                    link_url: "https://www.pointburger.fr/",
                    link_url_domain: "www.pointburger.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/152743504171878_6ccb08b4.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "inactive",
                    title: "Burger fois gras",
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: true,
                    link_url: "https://www.pointburger.fr/",
                    link_url_domain: "www.pointburger.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/152743504171878_670568e1.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "active",
                    title: "Burger Boursin",
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: true,
                    link_url: "https://www.pointburger.fr/",
                    link_url_domain: "www.pointburger.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/152743504171878_9d597f79.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "inactive",
                    title: "Tacos 1 viande",
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: true,
                    link_url: "https://www.pointburger.fr/",
                    link_url_domain: "www.pointburger.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/152743504171878_3cf8866b.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "inactive",
                    title: "Tacos gratiné le chef",
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: true,
                    link_url: "https://www.pointburger.fr/",
                    link_url_domain: "www.pointburger.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/152743504171878_ebec0367.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "inactive",
                    title: "Burger Classique",
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2022-12-02T15:59:12",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2022-12-12T13:46:09.631000",
            lang_iso_code: "fr",
            last_seen_date: "2022-12-12T13:46:09.631000",
            format: "carousel",
            id: "152743504171878",
            link_url: null,
            link_url_domain: null,
            page_id: "people",
            page_link_url: null,
            page_name: "Point Burger",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2022-12-14T20:32:29.629693",
                comment: 1,
                haha: 0,
                like: 6,
                love: 1,
                share: 2,
                sorry: 0,
                total_reactions: 7,
                view: null,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://facebook.com/152743504171878",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/897599032398456_6695902f.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Burger King France",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-08-06T09:26:23",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-08-07T06:29:43",
            lang_iso_code: "fr",
            last_seen_date: "2024-08-07T12:06:43.570000",
            format: "image",
            id: "897599032398456",
            link_url: null,
            link_url_domain: null,
            page_id: "100064451031939",
            page_link_url: "https://www.facebook.com/100064451031939",
            page_name: "Burger King France",
            page_profile_image_url:
                "https://media2.dropispy.com/files/ZXzptRklVakJqU0UwMlRIazVhbUl5TlRCYVZ6VXdURzFHYTJNelFqVk1iVTUyWWxNNE0xUnNXWGRsV0ZKclVXNXJlVm93TVdsU1IzUXlURzF3ZDFwM1BUMD0=",
            snapshot: {
                anger: 69,
                at: "2024-08-08T17:15:42.016492",
                comment: 0,
                haha: 16,
                like: 2500,
                love: 229,
                share: 214,
                sorry: 5,
                total_reactions: 2918,
                view: 0,
                wow: 13,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100064451031939/posts/897599032398456",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Message Page",
                    cta_type: "MESSAGE_PAGE",
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/775056388074530_9b9dbf84.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "O'Tacos Martinique",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-06-04T19:14:09",
            cta_text: "Send message",
            cta_type: "MESSAGE_PAGE",
            first_seen_date: "2024-06-15T14:20:41.972000",
            lang_iso_code: "fr",
            last_seen_date: "2024-06-15T14:20:41.972000",
            format: "image",
            id: "775056388074530",
            link_url: null,
            link_url_domain: null,
            page_id: "100067105262292",
            page_link_url: "https://www.facebook.com/100067105262292",
            page_name: "O'Tacos Martinique",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-06-15T14:24:39.802961",
                comment: 0,
                haha: 0,
                like: 8,
                love: 2,
                share: 3,
                sorry: 0,
                total_reactions: 11,
                view: 0,
                wow: 1,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid02AmJQNQiHHyrUT63CnWMaXfj6YzTJKdNPtKrjDDLLUDyXrXuzdTvvRH9KhRqRX8aPl&id=100067105262292",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: false,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/5378915258893388_7208eea7.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: "active",
                    title: null,
                    typ: "image_link",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2022-09-06T13:43:34",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2022-09-19T15:17:22.182000",
            lang_iso_code: "fr",
            last_seen_date: "2022-09-19T19:26:15.181000",
            format: "image",
            id: "5378915258893388",
            link_url: null,
            link_url_domain: null,
            page_id: "tacosdetoulouse",
            page_link_url: null,
            page_name: "New School Tacos",
            page_profile_image_url: null,
            snapshot: {
                anger: 9,
                at: "2022-09-27T10:02:25.546139",
                comment: 154,
                haha: 27,
                like: 582,
                love: 79,
                share: 15,
                sorry: 2,
                total_reactions: 701,
                view: null,
                wow: 2,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://facebook.com/5378915258893388",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_187e2a17.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_41500361.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_92038f60.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_19f1527c.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_6f1a42d0.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Contact Us",
                    cta_type: "CONTACT_US",
                    description: null,
                    header: "facebook.com",
                    is_sub: null,
                    link_url: "https://www.facebook.com/KebabPoireSurVie/",
                    link_url_domain: "www.facebook.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/459148286630226_6bd41c18.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "L'Extra Tacos",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-05-17T19:10:41",
            cta_text: "Contact us",
            cta_type: "CONTACT_US",
            first_seen_date: "2024-06-01T22:19:09.568000",
            lang_iso_code: "de",
            last_seen_date: "2024-06-01T22:19:09.568000",
            format: "album",
            id: "459148286630226",
            link_url: "https://www.facebook.com/KebabPoireSurVie/",
            link_url_domain: "www.facebook.com",
            page_id: "100076051742448",
            page_link_url: "https://www.facebook.com/100076051742448",
            page_name: "L'Extra Tacos",
            page_profile_image_url: null,
            snapshot: {
                anger: 1,
                at: "2024-07-20T17:28:28.234127",
                comment: 0,
                haha: 0,
                like: 87,
                love: 7,
                share: 25,
                sorry: 0,
                total_reactions: 95,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100076051742448/posts/459148286630226",
            days_running: 11,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Message Page",
                    cta_type: "MESSAGE_PAGE",
                    description:
                        "nouveau u00e0 Caenngood naan, nvous propose des cheese naan, fait maison, pru00e9parer dans un four tandour en terre cuite pour  vous garantir un gou00fbt unique  .nvous pouvez le composer  avec plusieurs viandes, au choix marinade, faite maison, le fameux chicken tandoori, chicken oriental et chicken curry kefta... et aussi sandwich baguette tacos , bowls et pizza. Bien su00fbr confectionnu00e9 avec des ingru00e9dients frais pour vous garantir une qualitu00e9 optimale. nNous vous attendons 22, rue de la Du00e9fense passive u00e0 Caen  tous les jours de 11h30 u00e0 14 h 30 et de 18h00 u00e0 23h facile d'accu00e8s avec place de stationnement. Et si vous le souhaitez vous pouvez commander via notre  site Goodnaan14.fr et retirer votre commande sur place ou bien vous faire livrer.",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/122114586968283793_46fcfff4.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Good Naan Caen",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Message Page",
                    cta_type: "MESSAGE_PAGE",
                    description:
                        "nouveau u00e0 Caenngood naan, nvous propose des cheese naan, fait maison, pru00e9parer dans un four tandour en terre cuite pour  vous garantir un gou00fbt unique  .nvous pouvez le composer  avec plusieurs viandes, au choix marinade, faite maison, le fameux chicken tandoori, chicken oriental et chicken curry kefta... et aussi sandwich baguette tacos , bowls et pizza. Bien su00fbr confectionnu00e9 avec des ingru00e9dients frais pour vous garantir une qualitu00e9 optimale. nNous vous attendons 22, rue de la Du00e9fense passive u00e0 Caen  tous les jours de 11h30 u00e0 14 h 30 et de 18h00 u00e0 23h facile d'accu00e8s avec place de stationnement. Et si vous le souhaitez vous pouvez commander via notre  site Goodnaan14.fr et retirer votre commande sur place ou bien vous faire livrer.",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/122114586968283793_8e8210e4.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Good Naan Caen",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Message Page",
                    cta_type: "MESSAGE_PAGE",
                    description:
                        "nouveau u00e0 Caenngood naan, nvous propose des cheese naan, fait maison, pru00e9parer dans un four tandour en terre cuite pour  vous garantir un gou00fbt unique  .nvous pouvez le composer  avec plusieurs viandes, au choix marinade, faite maison, le fameux chicken tandoori, chicken oriental et chicken curry kefta... et aussi sandwich baguette tacos , bowls et pizza. Bien su00fbr confectionnu00e9 avec des ingru00e9dients frais pour vous garantir une qualitu00e9 optimale. nNous vous attendons 22, rue de la Du00e9fense passive u00e0 Caen  tous les jours de 11h30 u00e0 14 h 30 et de 18h00 u00e0 23h facile d'accu00e8s avec place de stationnement. Et si vous le souhaitez vous pouvez commander via notre  site Goodnaan14.fr et retirer votre commande sur place ou bien vous faire livrer.",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/122114586968283793_5fe1aa35.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Good Naan Caen",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: "Message Page",
                    cta_type: "MESSAGE_PAGE",
                    description:
                        "nouveau u00e0 Caenngood naan, nvous propose des cheese naan, fait maison, pru00e9parer dans un four tandour en terre cuite pour  vous garantir un gou00fbt unique  .nvous pouvez le composer  avec plusieurs viandes, au choix marinade, faite maison, le fameux chicken tandoori, chicken oriental et chicken curry kefta... et aussi sandwich baguette tacos , bowls et pizza. Bien su00fbr confectionnu00e9 avec des ingru00e9dients frais pour vous garantir une qualitu00e9 optimale. nNous vous attendons 22, rue de la Du00e9fense passive u00e0 Caen  tous les jours de 11h30 u00e0 14 h 30 et de 18h00 u00e0 23h facile d'accu00e8s avec place de stationnement. Et si vous le souhaitez vous pouvez commander via notre  site Goodnaan14.fr et retirer votre commande sur place ou bien vous faire livrer.",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/122114586968283793_850d5e73.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Good Naan Caen",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-05-09T12:54:12",
            cta_text: "Send message",
            cta_type: "MESSAGE_PAGE",
            first_seen_date: "2024-05-12T14:26:11.916000",
            lang_iso_code: "fr",
            last_seen_date: "2024-05-12T14:26:11.916000",
            format: "album",
            id: "122114586968283793",
            link_url: null,
            link_url_domain: null,
            page_id: "61558513797273",
            page_link_url: "https://www.facebook.com/61558513797273",
            page_name: "Good Naan Caen",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-05-12T14:29:33.704960",
                comment: 18,
                haha: 0,
                like: 32,
                love: 6,
                share: 36,
                sorry: 0,
                total_reactions: 38,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid0XJYdTWqSm2UZmh2edenatjaaiy1Lg2jSc9ox8H2SacTKAX9hDY6X9Lc5HYcvG2jzl&id=61558513797273",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: "",
                    header: "cotesushi.com",
                    is_sub: false,
                    link_url: "https://cotesushi.com/carte",
                    link_url_domain: "cotesushi.com",
                    media_height: null,
                    media_poster_url:
                        "https://do4h0vy7erfx.cloudfront.net/poster_9215125571832713_fb0ca26f.jpg",
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/9215125571832713_378275b2.mp4",
                    media_url_type: "video",
                    media_width: null,
                    state: "active",
                    title: "Nouvelle Carte 🥢",
                    typ: "video",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2023-03-02T13:05:31",
            cta_text: "Order now",
            cta_type: "ORDER_NOW",
            first_seen_date: "2023-03-06T17:00:29.445000",
            lang_iso_code: "fr",
            last_seen_date: "2023-03-12T01:37:48.500000",
            format: "video",
            id: "9215125571832713",
            link_url: "https://cotesushi.com/carte",
            link_url_domain: "cotesushi.com",
            page_id: "cotesushifrance",
            page_link_url: "https://www.facebook.com/219511534727540",
            page_name: "Côté Sushi",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-08-10T15:07:22.924631",
                comment: 0,
                haha: 0,
                like: 26,
                love: 4,
                share: 0,
                sorry: 0,
                total_reactions: 30,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid02koCKNq8LmmkS4Naq2buZQob6zCeHCjwmMukHad11cnZUSSQrMUzhk8VH7zR681Djl&id=219511534727540",
            days_running: 5,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Get Directions",
                    cta_type: "GET_DIRECTIONS",
                    description: null,
                    header: "quick.fr",
                    is_sub: null,
                    link_url:
                        "https://www.quick.fr/temps-forts/french-burger?utm_source=SOCIAL",
                    link_url_domain: "www.quick.fr",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/880686304086811_ab025181.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Quick France",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-07-26T17:21:22",
            cta_text: "Get directions",
            cta_type: "GET_DIRECTIONS",
            first_seen_date: "2024-08-01T11:32:15.917000",
            lang_iso_code: "fr",
            last_seen_date: "2024-08-01T11:32:15.917000",
            format: "album",
            id: "880686304086811",
            link_url:
                "https://www.quick.fr/temps-forts/french-burger?utm_source=SOCIAL",
            link_url_domain: "www.quick.fr",
            page_id: "100064363496705",
            page_link_url: "https://www.facebook.com/100064363496705",
            page_name: "Quick France",
            page_profile_image_url: null,
            snapshot: {
                anger: 1,
                at: "2024-08-08T14:28:31.029361",
                comment: 0,
                haha: 3,
                like: 211,
                love: 5,
                share: 0,
                sorry: 0,
                total_reactions: 222,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/Quick/posts/pfbid02KGXM1MWeRLbAKL8X9qpBpHfkAMCkDBzCWYpbthtymKsAshkTFHtWXdnPJmD4o4aGl",
            days_running: 7,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description:
                        "ud83cudd95ud83cudf74 [MENU u27a1ufe0f 22 AU 26 AVRIL ] ud83cudf74ud83cudd95nViens du00e9couvrir notre carte de la semaine !nu2764ufe0f Nos coups de food u2b07ufe0f :nu2705 POULET MASSALu00c9 (lundi)nu2705 STEAK DE THON (mardi)nu2705 CARRI CAMARONS (mercredi)nu2705 BOL RENVERSu00c9 (jeudi)nu2705 SAUTu00c9 MINES (vendredi)nnRetrouve maintenant 1 entru00e9e par jour et de nouveaux plats u00e0 la carte ! nnud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0f LIVRAISON ud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fnFais toi u00e9galement livru00e9 avec Please - La Ru00e9union :nEn Livraison u27a1ufe0fu27a1ufe0fu27a1ufe0f https://bit.ly/2QHBZih u2b05ufe0fu2b05ufe0fu2b05ufe0fnud83cudd95ud83cudd95ud83cudd95 Et maintenant avec Uber Eats ud83cudd95ud83cudd95ud83cudd95nu27a1ufe0fu27a1ufe0fu27a1ufe0f https://bit.ly/3HFI4Vq u2b05ufe0fu2b05ufe0fu2b05ufe0fnnud83eudd59ud83cudf2fud83cudf5f STREET FOOD ud83cudf5fud83cudf2fud83eudd59nu27a1ufe0f Retrouve u00e9galement toute la semaine nos menus assiette kebab u00e0 7,90u20ac ou le sandwich uniquement u00e0 6,90u20ac :nud83eudd59 Kebab + ud83cudf5f frites + ud83eudd57 saladenud83cudf2f French Tacos + ud83cudf5f frites + ud83eudd57 saladennu260eufe0fu260eufe0fu260eufe0f PASSE TA COMMANDE u260eufe0fu260eufe0fu260eufe0fnu27a1ufe0f Afin d'u00e9viter l'attente en station, tu peux passer ta commande au 02 62 29 32 42nnud83eudd6aud83eudd6aud83eudd6a NOS SANDWICHS ud83eudd6aud83eudd6aud83eudd6anu27a1ufe0f Nous avons u00e9galement une carte des sandwichs, lords, macatias...",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/1044543163730100_2386fae1.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Engen Chaudron",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
                {
                    cta_text: null,
                    cta_type: null,
                    description:
                        "ud83cudd95ud83cudf74 [MENU u27a1ufe0f 22 AU 26 AVRIL ] ud83cudf74ud83cudd95nViens du00e9couvrir notre carte de la semaine !nu2764ufe0f Nos coups de food u2b07ufe0f :nu2705 POULET MASSALu00c9 (lundi)nu2705 STEAK DE THON (mardi)nu2705 CARRI CAMARONS (mercredi)nu2705 BOL RENVERSu00c9 (jeudi)nu2705 SAUTu00c9 MINES (vendredi)nnRetrouve maintenant 1 entru00e9e par jour et de nouveaux plats u00e0 la carte ! nnud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0f LIVRAISON ud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fud83dudeb4u200du2642ufe0fnFais toi u00e9galement livru00e9 avec Please - La Ru00e9union :nEn Livraison u27a1ufe0fu27a1ufe0fu27a1ufe0f https://bit.ly/2QHBZih u2b05ufe0fu2b05ufe0fu2b05ufe0fnud83cudd95ud83cudd95ud83cudd95 Et maintenant avec Uber Eats ud83cudd95ud83cudd95ud83cudd95nu27a1ufe0fu27a1ufe0fu27a1ufe0f https://bit.ly/3HFI4Vq u2b05ufe0fu2b05ufe0fu2b05ufe0fnnud83eudd59ud83cudf2fud83cudf5f STREET FOOD ud83cudf5fud83cudf2fud83eudd59nu27a1ufe0f Retrouve u00e9galement toute la semaine nos menus assiette kebab u00e0 7,90u20ac ou le sandwich uniquement u00e0 6,90u20ac :nud83eudd59 Kebab + ud83cudf5f frites + ud83eudd57 saladenud83cudf2f French Tacos + ud83cudf5f frites + ud83eudd57 saladennu260eufe0fu260eufe0fu260eufe0f PASSE TA COMMANDE u260eufe0fu260eufe0fu260eufe0fnu27a1ufe0f Afin d'u00e9viter l'attente en station, tu peux passer ta commande au 02 62 29 32 42nnud83eudd6aud83eudd6aud83eudd6a NOS SANDWICHS ud83eudd6aud83eudd6aud83eudd6anu27a1ufe0f Nous avons u00e9galement une carte des sandwichs, lords, macatias...",
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/1044543163730100_645e4d32.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Engen Chaudron",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-04-22T07:29:25",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-04-24T17:37:47.766000",
            lang_iso_code: "fr",
            last_seen_date: "2024-04-24T17:37:47.766000",
            format: "album",
            id: "1044543163730100",
            link_url: null,
            link_url_domain: null,
            page_id: "100045232786216",
            page_link_url: "https://www.facebook.com/100045232786216",
            page_name: "Engen Chaudron",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-04-24T17:41:34.449904",
                comment: 2,
                haha: 0,
                like: 11,
                love: 3,
                share: 1,
                sorry: 0,
                total_reactions: 14,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid021jVwRBsDuEa2ioaxiD3vmyddfk1utDiR6uzWx6gYAEf2pqE9y7gWsty8gR52hn8il&id=100045232786216",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: "media1.giphy.com",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url:
                        "https://do4h0vy7erfx.cloudfront.net/poster_830049589165359_02400ab9.jpg",
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/830049589165359_a57926b9.mp4",
                    media_url_type: "video",
                    media_width: null,
                    state: null,
                    title: "Boulangerie Ange",
                    typ: "video",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-04-16T07:07:50",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-04-17T09:28:40.427000",
            lang_iso_code: "fr",
            last_seen_date: "2024-04-17T17:27:28",
            format: "video",
            id: "830049589165359",
            link_url: null,
            link_url_domain: null,
            page_id: "100064809755617",
            page_link_url: "https://www.facebook.com/100064809755617",
            page_name: "Boulangerie Ange",
            page_profile_image_url:
                "https://media2.dropispy.com/files/ZXzptRklVakJqU0UwMlRIazVhbUl5TlRCYVZ6VXdURzFHYTJNelFqVk1iVTUyWWxNNVVWUnJNVlZVYlRWRlQwaENjMU5xUmxOV01sb3pURzF3ZDFwM1BUMD0=",
            snapshot: {
                anger: 3,
                at: "2024-04-28T12:27:45.327090",
                comment: 25,
                haha: 4,
                like: 588,
                love: 74,
                share: 95,
                sorry: 1,
                total_reactions: 674,
                view: 0,
                wow: 1,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100064809755617/posts/830049589165359",
            days_running: 5,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: "media.tenor.co",
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url:
                        "https://do4h0vy7erfx.cloudfront.net/poster_826829972820654_41c3d66d.jpg",
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/826829972820654_0603405e.mp4",
                    media_url_type: "video",
                    media_width: null,
                    state: null,
                    title: "Boulangerie Ange",
                    typ: "video",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-04-11T08:25:00",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-04-15T13:45:41",
            lang_iso_code: "fr",
            last_seen_date: "2024-04-19T06:17:12",
            format: "video",
            id: "826829972820654",
            link_url: null,
            link_url_domain: null,
            page_id: "100064809755617",
            page_link_url: "https://facebook.com/ange.boulangerie",
            page_name: "Boulangerie Ange",
            page_profile_image_url:
                "https://media2.dropispy.com/files/ZXzptRklVakJqU0UwMlRIazVhbUl5TlRCYVZ6VXdURzFHYTJNelFqVk1iVTUyWWxNNVVWUnJNVlZVYlRWRlQwaENjMU5xUmxOV01sb3pURzF3ZDFwM1BUMD0=",
            snapshot: {
                anger: 1,
                at: "2024-04-20T19:17:44.036821",
                comment: 42,
                haha: 1,
                like: 675,
                love: 83,
                share: 157,
                sorry: 0,
                total_reactions: 763,
                view: 0,
                wow: 2,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100064809755617/posts/826829972820654",
            days_running: 3,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Get Directions",
                    cta_type: "GET_DIRECTIONS",
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url:
                        "https://share.here.com/r/mylocation/e-eyJuYW1lIjoiIiwiYWRkcmVzcyI6IjkgUXVhaSBWZWlsIFBpY2FyZCwgQmVzYW5cdTAwZTdvbiwgQm91cmdvZ25lLUZyYW5jaGUtQ29tdFx1MDBlOSwgRnJhbmNlIiwibGF0aXR1ZGUiOjQ3LjIzOTY0LCJsb25naXR1ZGUiOjYuMDE5NjV9?link=addresses&fb_locale=en_US&ref=facebook",
                    link_url_domain: "share.here.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/122150675600060051_e22c38d6.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Chamas Tacos (Besançon)",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-04-11T07:40:50",
            cta_text: "Get directions",
            cta_type: "GET_DIRECTIONS",
            first_seen_date: "2024-04-30T09:47:36.029000",
            lang_iso_code: null,
            last_seen_date: "2024-04-30T09:47:36.029000",
            format: "image",
            id: "122150675600060051",
            link_url:
                "https://share.here.com/r/mylocation/e-eyJuYW1lIjoiIiwiYWRkcmVzcyI6IjkgUXVhaSBWZWlsIFBpY2FyZCwgQmVzYW5cdTAwZTdvbiwgQm91cmdvZ25lLUZyYW5jaGUtQ29tdFx1MDBlOSwgRnJhbmNlIiwibGF0aXR1ZGUiOjQ3LjIzOTY0LCJsb25naXR1ZGUiOjYuMDE5NjV9?link=addresses&fb_locale=en_GB&ref=facebook",
            link_url_domain: "share.here.com",
            page_id: "61551801545607",
            page_link_url: "https://www.facebook.com/61551801545607",
            page_name: "Chamas Tacos",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-04-30T09:48:52.583038",
                comment: 0,
                haha: 0,
                like: 10,
                love: 0,
                share: 0,
                sorry: 0,
                total_reactions: 10,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/permalink.php?story_fbid=pfbid0TeTGXQYcGrAevvhihdd84nLqgmJjUEKehMZT4pw8diDWAvqU3oZc9j1r6xiwqAMXl&id=61551801545607",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/1024006735855225_44888fbe.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "O'tacos Chartres",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-03-30T17:48:27",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-03-30T21:31:44.086000",
            lang_iso_code: "fr",
            last_seen_date: "2024-03-30T21:31:44.086000",
            format: "image",
            id: "1024006735855225",
            link_url: null,
            link_url_domain: null,
            page_id: "100047377253194",
            page_link_url: "https://www.facebook.com/100047377253194",
            page_name: "O'tacos Chartres",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-03-31T20:18:44.116612",
                comment: 0,
                haha: 0,
                like: 20,
                love: 1,
                share: 0,
                sorry: 0,
                total_reactions: 21,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/permalink.php?story_fbid=pfbid02QLt4DJFiuA8u2Bu4avPiLKXzchNRTeKFxqcc4SDCyU7SmscBctDhYPVGC73kAfoEl&id=100047377253194",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Get Directions",
                    cta_type: "GET_DIRECTIONS",
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url:
                        "https://share.here.com/r/mylocation/e-eyJuYW1lIjoiIiwiYWRkcmVzcyI6IjEgQWxiYW55IFN0LCBTeWRuZXksIE5ldyBTb3V0aCBXYWxlcywgQXVzdHJhbGlhIiwibGF0aXR1ZGUiOi0zMy44MjM5OSwibG9uZ2l0dWRlIjoxNTEuMTk4MDl9?link=addresses&fb_locale=en_US&ref=facebook",
                    link_url_domain: "share.here.com",
                    media_height: null,
                    media_poster_url:
                        "https://do4h0vy7erfx.cloudfront.net/poster_823776783126456_6aa44a48.jpg",
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/823776783126456_366d3c62.mp4",
                    media_url_type: "video",
                    media_width: null,
                    state: null,
                    title: "Chololo Sydney",
                    typ: "video",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-02-16T23:59:49",
            cta_text: "Get directions",
            cta_type: "GET_DIRECTIONS",
            first_seen_date: "2024-03-31T23:33:48.917000",
            lang_iso_code: "en",
            last_seen_date: "2024-03-31T23:33:48.917000",
            format: "video",
            id: "823776783126456",
            link_url:
                "https://share.here.com/r/mylocation/e-eyJuYW1lIjoiIiwiYWRkcmVzcyI6IjEgQWxiYW55IFN0LCBTeWRuZXksIE5ldyBTb3V0aCBXYWxlcywgQXVzdHJhbGlhIiwibGF0aXR1ZGUiOi0zMy44MjM5OSwibG9uZ2l0dWRlIjoxNTEuMTk4MDl9?link=addresses&fb_locale=en_US&ref=facebook",
            link_url_domain: "share.here.com",
            page_id: "100064824247560",
            page_link_url: "https://www.facebook.com/100064824247560",
            page_name: "Chololo Sydney",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-03-31T23:36:28.464836",
                comment: 5,
                haha: 0,
                like: 44,
                love: 2,
                share: 1,
                sorry: 0,
                total_reactions: 46,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid02pKtcRuxFP4PASk1ReSxVW4XzAo3DkY6yg5BxMQneE4VRdPkhoJcSbvzUouyRrwCYl&id=100064824247560",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Learn More",
                    cta_type: "LEARN_MORE",
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: "https://restaurants.tacos-avenue.com/",
                    link_url_domain: "restaurants.tacos-avenue.com",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/1135199557879613_66b10d51.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Tacos Avenue (La Seyne-sur-Mer)",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-01-24T12:01:15",
            cta_text: "Learn more",
            cta_type: "LEARN_MORE",
            first_seen_date: "2024-01-29T04:44:03.216000",
            lang_iso_code: "fr",
            last_seen_date: "2024-01-29T04:44:03.216000",
            format: "image",
            id: "1135199557879613",
            link_url: "https://restaurants.tacos-avenue.com/",
            link_url_domain: "restaurants.tacos-avenue.com",
            page_id: "100041687392570",
            page_link_url: "https://www.facebook.com/100041687392570",
            page_name: "Tacos Avenue (La Seyne-sur-Mer)",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-01-29T04:44:34.338590",
                comment: 0,
                haha: 0,
                like: 2,
                love: 0,
                share: 1,
                sorry: 0,
                total_reactions: 2,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/1135199557879613",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url:
                        "https://do4h0vy7erfx.cloudfront.net/poster_405958612460170_e5e678de.jpg",
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/405958612460170_897ef8ff.mp4",
                    media_url_type: "video",
                    media_width: null,
                    state: null,
                    title: "ALL PIZZA & PASTA Buffet-Agen",
                    typ: "video",
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-07-28T11:32:21",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-08-05T11:00:49.074000",
            lang_iso_code: "fr",
            last_seen_date: "2024-08-05T11:00:49.074000",
            format: "video",
            id: "405958612460170",
            link_url: null,
            link_url_domain: null,
            page_id: "100091381030799",
            page_link_url: "https://www.facebook.com/100091381030799",
            page_name: "ALL PIZZA & PASTA Buffet-Agen",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-08-05T11:05:57.287080",
                comment: 0,
                haha: 0,
                like: 345,
                love: 53,
                share: 178,
                sorry: 0,
                total_reactions: 401,
                view: 0,
                wow: 3,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/story.php?story_fbid=pfbid02ASytmt8njrWXCqt5J6DYkiDR73ii3ptJkJc59x9YdiqUGZ3XFbfBsKmfzrjqfZVml&id=100091381030799",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: "Start Order",
                    cta_type: "ORDER_NOW",
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: "https://otacos.re/",
                    link_url_domain: "otacos.re",
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/695180806097928_e4f8880d.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "O'tacos Réunion",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-01-17T07:02:14",
            cta_text: "Order now",
            cta_type: "ORDER_NOW",
            first_seen_date: "2024-01-17T11:59:29.763000",
            lang_iso_code: "fr",
            last_seen_date: "2024-01-17T11:59:29.763000",
            format: "image",
            id: "695180806097928",
            link_url: "https://otacos.re/",
            link_url_domain: "otacos.re",
            page_id: "100068180437347",
            page_link_url: "https://www.facebook.com/100068180437347",
            page_name: "O'tacos Réunion",
            page_profile_image_url: null,
            snapshot: {
                anger: 0,
                at: "2024-01-21T14:31:16.996888",
                comment: 7,
                haha: 1,
                like: 27,
                love: 8,
                share: 0,
                sorry: 0,
                total_reactions: 36,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/695180806097928",
            days_running: 0,
            __typename: "AdvertMini",
        },
        {
            attachments: [
                {
                    cta_text: null,
                    cta_type: null,
                    description: null,
                    header: null,
                    is_sub: null,
                    link_url: null,
                    link_url_domain: null,
                    media_height: null,
                    media_poster_url: null,
                    media_url:
                        "https://do4h0vy7erfx.cloudfront.net/893621742331416_737a66bf.jpg",
                    media_url_type: "image",
                    media_width: null,
                    state: null,
                    title: "Regal Food",
                    typ: null,
                    __typename: "AttachmentsEntity",
                },
            ],
            creation_date: "2024-01-02T23:40:24",
            cta_text: null,
            cta_type: null,
            first_seen_date: "2024-01-02T23:40:24",
            lang_iso_code: "fr",
            last_seen_date: "2024-01-02T23:40:24",
            format: "image",
            id: "893621742331416",
            link_url: null,
            link_url_domain: null,
            page_id: "100050507965486",
            page_link_url: "https://facebook.com/Marseillelesaviezvous",
            page_name: "Regal food",
            page_profile_image_url:
                "https://media2.dropispy.com/files/ZXzptRklVakJqU0UwMlRIazVhbUl5TlRCYVZ6VXdURzFHYTJNelFqVk1iVTUyWWxNNU1FMHlaekJoYW1zMVZWZFNOazFFVm5OamJUVjJURzF3ZDFwM1BUMD0=",
            snapshot: {
                anger: 0,
                at: "2024-01-03T17:10:58.074487",
                comment: 5,
                haha: 0,
                like: 64,
                love: 1,
                share: 4,
                sorry: 0,
                total_reactions: 66,
                view: 0,
                wow: 0,
                __typename: "SnapshotsEntityOrSnapshot",
            },
            url: "https://www.facebook.com/100050507965486/posts/893621742331416",
            days_running: 0,
            __typename: "AdvertMini",
        },
    ];

    const [page, setPage] = useState(1);

    const AdCard = (props: { ad: any }) => {
        const { ad } = props;
        const { page_name, attachments } = ad;
        const media = attachments[0].media_url;
        const type = attachments[0].media_url_type;

        return (
            <div
                className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                onClick={() => window.open(ad.url, "_blank")}
            >
                {type === "image" ? (
                    <div className="h-[200px] w-full">
                        <img
                            src={media}
                            alt="Tablet"
                            className="flex mx-auto h-[200px] rounded-xl"
                        />
                    </div>
                ) : (
                    <video
                        src={`${media}#t=0.1`}
                        className="rounded-xl h-[200px] w-full"
                        controls
                        preload="metadata"
                    />
                )}
                <div>
                    <h1 className="mt-4 text-lg font-bold">{page_name}</h1>
                    <p className="text-sm mt-1">Publicité détectée</p>
                </div>
            </div>
        );
    };

    return (
        <div className="mt-3 ">
            <div className="">
                <h1 className="mt-5 text-xl md:text-2xl md:mt-0 font-bold">
                    Analyse des annonces
                </h1>
                <p className="mt-3 text-gray-600">
                    Vous pouvez surveillez les annonces de vos concurrents grâce
                    à notre outils de veille publicitaire.
                </p>
                <div className="mt-4 w-[100%] h-[1px] bg-gray-200 rounded-full"></div>
                <p className="mt-5 flex justify-end text-gray-600 text-xs">
                    Les annonces des clients fidiz sont excluent des résultats.
                </p>
                <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {items.slice((page - 1) * 8, page * 8).map((item: any) => (
                        <AdCard ad={item} />
                    ))}
                </div>
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={items.length}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) => setPage(page)}
                />
            </div>
        </div>
    );
};

export default Ads;
