import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getAutomations = async () => {
    const res = await axios.get("/automations", config()).catch(errorHandler);
    return res.data;
};

export const getAutomation = async (id: string) => {
    const res = await axios
        .get(`/automations/${id}`, config())
        .catch(errorHandler);
    return res.data;
};

export const createAutomation = async (data: any) => {
    const res = await axios
        .post("/automations", data, config())
        .catch(errorHandler);
    return res.data;
};

export const updateAutomation = async (id: string, data: any) => {
    const res = await axios
        .put(`/automations/${id}`, data, config())
        .catch(errorHandler);
    return res.data;
};

export const deleteAutomation = async (id: string) => {
    const res = await axios
        .delete(`/automations/${id}`, config())
        .catch(errorHandler);
    return res.data;
};
