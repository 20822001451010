import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getRankings = async () => {
    const res = await axios.get("/rankings", config()).catch(errorHandler);
    return res.data;
};

export const getRanking = async (id: string) => {
    const res = await axios
        .get(`/rankings/${id}`, config())
        .catch(errorHandler);
    return res.data;
};

export const getRankingList = async (id: string) => {
    const res = await axios
        .get(`/rankings/list/${id}`, config())
        .catch(errorHandler);
    return res.data;
};

export const createRanking = async (data: any) => {
    const res = await axios
        .post("/rankings", data, config())
        .catch(errorHandler);
    return res.data;
};

export const updateRanking = async (id: string, data: any) => {
    const res = await axios
        .put(`/rankings/${id}`, data, config())
        .catch(errorHandler);
    return res.data;
};

export const deleteRanking = async (id: string) => {
    const res = await axios
        .delete(`/rankings/${id}`, config())
        .catch(errorHandler);
    return res.data;
};
