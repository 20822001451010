import Card from "components/card";
import { dateToFrench } from "utils/date";
import { getIcon } from "utils/icons";

const LastProductions = (props: { productions: any }) => {
    const { productions } = props;
    return (
        <Card extra={"w-full p-4 h-full border"}>
            <div className="mb-8 w-full">
                <p className="text-lg font-medium">
                    Vos dernières productions
                </p>
                <p className="mt-2 text-base text-gray-600">
                    Liste de vos dernières productions
                </p>
            </div>
            {productions &&
                productions.slice(0, 3).map((production: any) => (
                    <div className="mt-3 flex w-full items-center hover:shadow-2xl justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:!bg-navy-700 dark:shadow-none">
                        <div className="flex items-center mb-3">
                            <div className="flex items-center justify-center">
                                <img
                                    className="h-24 w-24 rounded-xl"
                                    src={getIcon(
                                        production.productName,
                                        "product"
                                    )}
                                    alt=""
                                />
                            </div>
                            <div className="">
                                <p className="text-lg ml-2 font-medium text-navy-700 dark:text-white">
                                    {production.productName ===
                                    "Product not found"
                                        ? "Le produit n'existe plus"
                                        : production.productName}{" "}
                                    x{production.quantity}
                                </p>
                                <p className="mt-2 ml-2 text-sm text-gray-600">
                                    {production.date &&
                                        dateToFrench(production.date)}
                                    -{" "}
                                    {production.comment !== ' '
                                        ? production.comment
                                        : "Aucun commentaire"}
                                </p>
                            </div>
                        </div>
                        <div className="flex w-[130px] items-center justify-center rounded-lg bg-green-100 py-2 text-base font-bold text-green-500">
                            Terminé
                        </div>
                    </div>
                ))}
        </Card>
    );
};

export default LastProductions;
