import { Scanner } from "@yudiel/react-qr-scanner";
import { getCoupon } from "interfaces/restaurant";
import { useState } from "react";
import { toast } from "react-toastify";

const Scan = () => {
    const [coupon, setCoupon] = useState<any>(null);

    const initCoupon = async (id: any) => {
        setCoupon(null);
        const res = await getCoupon(id);
        if (!res) {
            toast.error("Coupon invalide");
        } else if (res?.description !== undefined) {
            setCoupon(res);
            toast.success(`Coupon ${res?.description} validé`);
        }
    };

    return (
        <div className="mt-10 md:mt-0 grid grid-cols-1 md:grid-cols-2">
            <div className="flex mx-auto w-[300px] md:w-[400px] bg-[#1f2937] rounded-xl border-2 border-[#1f2937]">
                <Scanner
                    onScan={(result: any) => {
                        initCoupon(result[0].rawValue);
                    }}
                    allowMultiple={true}
                    scanDelay={5000}
                    classNames={{
                        container: "w-full h-full rounded-xl bg-[#1f2937]",
                        video: "w-full h-full rounded-xl bg-[#1f2937]",
                    }}
                    constraints={{ facingMode: "environment" }}
                />
            </div>
            <div className="flex mx-auto w-[300px] rounded-xl border-2 border-[#1f2937]">
                {coupon && (
                    <div className="mt-10 flex mx-auto text-2xl">
                        <p>{coupon?.description}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Scan;
