import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getPageBySlug = async (slug: string) => {
    const res = await axios.get(`/page/${slug}`, config()).catch(errorHandler);
    return res.data;
};

export const getPage = async () => {
    const res = await axios.get("/page", config()).catch(errorHandler);
    return res.data;
};

export const updatePage = async (data: any) => {
    const res = await axios.put(`/page`, data, config()).catch(errorHandler);
    return res.data;
};
