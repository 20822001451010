import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import env from "variables/config";
import { forgotPassword, resetPassword } from "interfaces/user";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);

    const handleForgotPassword = async () => {
        const res = await forgotPassword({ email });
        if (res) {
            toast.success(
                "Un email de réinitialisation de mot de passe a été envoyé",
            );
            setPasswordReset(true);
        }
    };

    return (
        <Default
            maincard={
                <div className="flex h-full w-full items-center justify-center px-4 md:mx-0 md:px-0">
                    {/* Sign in section */}
                    <div className="mx-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                        <ToastContainer />
                        <h3 className="text-xl lg:text-2xl mb-5 font-han dark:text-white">
                            Changer mon mot de passe
                        </h3>
                        {/* Email */}
                        <div className="flex mt-10">
                            <input
                                id="email"
                                key={"email"}
                                type="text"
                                placeholder="email*"
                                className="p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                onChange={(e: any) => setEmail(e.target.value)}
                            />
                        </div>
                        {!passwordReset && (
                            <button
                                id="connect-btn"
                                className="linear mt-10 w-full rounded-xl bg-[#FEDE65] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:bg-[#fedd65a4] active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                onClick={() => handleForgotPassword()}
                            >
                                Recevoir un email
                            </button>
                        )}
                    </div>
                </div>
            }
        />
    );
}

export default ForgotPassword;
