import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useState } from "react";

const AffiliateModal = (props: {
    isOpen: any;
    onClose: any;
    linkSponsorCode: any;
}) => {
    const { isOpen, onClose, linkSponsorCode } = props;
    const [sponsorCode, setSponsorCode] = useState(null);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="mb-[20px] text-lg font-medium">
                            Associer un code de parrainage
                        </h1>
                        <InputField
                            label="Code de parrainage"
                            id="sponsorCode"
                            extra="w-full"
                            value={sponsorCode}
                            onChange={(e: any) =>
                                setSponsorCode(e.target.value)
                            }
                        />
                        <div className="mt-5 flex gap-2 mx-auto">
                            <button
                                onClick={onClose}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => {
                                    linkSponsorCode(sponsorCode);
                                    onClose();
                                }}
                                className="linear rounded-xl border-2 border-gray-700 px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                            >
                                Rajouter
                            </button>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AffiliateModal;
