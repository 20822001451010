import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";

const RemovePointsModal = (props: {
    isOpen: any;
    onClose: any;
    rewards: any;
    removePoints: any;
}) => {
    const { isOpen, onClose, rewards, removePoints } = props;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[650px] flex flex-col !z-[1004]">
                        <h1 className="text-lg font-medium">Offir un cadeau</h1>
                        <div className="mt-5 grid grid-cols-1 gap-3">
                            {rewards &&
                                rewards.map((reward: any) => (
                                    <div
                                        className="flex border rounded-xl p-4 cursor-pointer hover:bg-gray-200"
                                        onClick={() => {
                                            removePoints(reward.points);
                                            onClose();
                                        }}
                                    >
                                        <p>
                                            {reward.name} pour {reward.points}{" "}
                                            points
                                        </p>
                                    </div>
                                ))}
                        </div>
                        <div
                            className="mt-3 flex border rounded-xl p-4 cursor-pointer hover:bg-gray-200"
                            onClick={onClose}
                        >
                            <p>Aucun</p>
                        </div>
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default RemovePointsModal;
