import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "components/card";
import { createCustomer } from "interfaces/customer";
import InputField from "components/fields/InputField";
import { createLead } from "interfaces/lead";

const InsertLead = () => {
    const navigate = useNavigate();
    const [newCustomer, setNewCustomer] = useState<any>({});

    const handleChange = (e: any) => {
        setNewCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            [e.target.id]: e.target.value,
        }));
    };

    const addLead = async (redirectToList = true) => {

        const res = await createLead(newCustomer);
        if (res) {
            toast.success("Nouveau lead ajouté avec succès");
        }
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="mt-4 grid  grid-cols-12">
            <div className={`col-span-12`}>
                <Card extra={"w-full !p-5"}>
                    {/* Header */}
                    <div className="w-full px-[8px]">
                        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                            Insérer un lead
                        </h4>
                    </div>
                    {/* inputs */}
                    <div className="mt-8 grid gap-3 grid-cols-12">
                        <p className="col-span-12 text-base">
                            Informations client
                        </p>
                        <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label="Email client"
                                placeholder="client@gmail.com"
                                id="customerEmail"
                                type="text"
                                onChange={handleChange}
                                error="Numéro de client invalide"
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                            <InputField
                                label="Titre*"
                                placeholder="Entreprise qui recherche..."
                                id="title"
                                type="text"
                                onChange={handleChange}
                                error="Email invalide"
                                value={newCustomer.mail}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                            <InputField
                                label="Email*"
                                placeholder="entreprise@gmail.com"
                                id="email"
                                type="text"
                                onChange={handleChange}
                                error="Email invalide"
                                value={newCustomer.mail}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label="Téléphone"
                                placeholder="+33678003678"
                                id="phone"
                                type="text"
                                onChange={handleChange}
                                error="invalide"
                                value={newCustomer.phone}
                                inputSmall={true}
                            />
                        </div>
                        <div className="col-span-12 lg:col-span-2">
                            <InputField
                                label={`site Internet`}
                                placeholder="entreprise.fr"
                                id="website"
                                type="text"
                                onChange={handleChange}
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    <div className="mt-10 grid grid-cols-6 gap-5">
                        <button
                            className="linear col-span-3 flex items-center justify-center rounded-xl bg-orange-400 px-3 py-3 text-sm font-medium text-white hover:bg-orange-500 active:bg-orange-600"
                            onClick={() => addLead()}
                        >
                            Envoyer le lead au client
                        </button>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default InsertLead;
