
// Custom components
function InputField(props: {
  id: string;
  label?: string;
  error?: string;
  isInvalid?: boolean;
  isLoaded?: any;
  required?: boolean;
  extra?: string;
  placeholder?: string;
  defaultValue?: string | number;
  variant?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  onChange?: any;
  ref?: any;
  value?: string | number;
  min?: string;
  onFocus?: any;
  inputSmall?: boolean;
}) {
  const {
    label,
    error,
    id,
    extra,
    required = false,
    isLoaded = true,
    type,
    placeholder,
	defaultValue,
    variant,
    state,
    disabled,
    onChange,
    value,
    min,
    ref,
    onFocus,
    isInvalid = false,
    inputSmall = false
  } = props;

  // Define if the error should be displayed
  let displayError = false;
  if (isLoaded) {
    if (error && isInvalid && (value && value !== "" && value !== undefined)) displayError = true;
    if (required && !value) displayError = true;
  }

  return (
      <div className={`flex flex-col gap-2 ${extra}`}>
          <div className="flex">
              {label && (
                  <label
                      htmlFor={id}
                      className={`text-navy-700
                      ${inputSmall ? "text-xs" : "text-sm"}
                      ${
                          variant === "auth"
                              ? "ml-1.5 font-medium"
                              : "font-medium"
                      }
                      ${displayError ? "text-red-500" : "text-navy-700"}`}
                  >
                      {label}
                  </label>
              )}
              {displayError && (
                  <p
                      className={`ml-3 my-auto text-red-500 dark:text-red-400
                      ${inputSmall ? "text-xs" : "text-sm"}
                      `}
                  >
                      {error ? error : "Champ requis"}
                  </p>
              )}
          </div>
          {}
          <input
              onChange={onChange}
              disabled={disabled}
              min={min}
              ref={ref}
              type={type}
              onFocus={onFocus}
              id={id}
              value={!isLoaded ? "Chargement..." : value}
              placeholder={!displayError && placeholder}
              defaultValue={defaultValue}
              className={`flex w-full ${
                  inputSmall ? "h-[35px]" : "h-[46px]"
              } items-center ${
                  !isLoaded && "animate-pulse"
              } justify-center rounded-xl border bg-white/0 px-2.5 py-3 text-sm outline-none ${
                  disabled === true
                      ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                      : displayError
                        ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                        : state === "success"
                          ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                          : "border-gray-200 dark:!border-white/10 dark:text-white"
              }`}
          />
      </div>
  );
}

export default InputField;
