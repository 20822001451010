import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getMyRestaurant = async () => {
    const res = await axios.get(`/restaurants`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const getCoupon = async (id: any) => {
    const res = await axios
        .get(`/restaurants/coupon/${id}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getRestaurant = async (id: any) => {
    const res = await axios
        .get(`/restaurants/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getWheelPrize = async (id: any) => {
    const res = await axios
        .get(`/restaurants/${id}/wheel-prize`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getRestaurantPublicInfo = async (id: any) => {
    const res = await axios
        .get(`/restaurants/${id}/public`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getAffiliationInfo = async (id: any) => {
    const res = await axios
        .get(`/user/affiliation/${id}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("user-token"),
            },
        })
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateMyRestaurant = async (restaurant: any) => {
    const id = restaurant._id;
    const res: any = await axios
        .put(
            `/restaurants/${id}`,
            {
                rewards: restaurant.rewards,
                name: restaurant.name,
                nextCustomerReward: restaurant.nextCustomerReward,
                googlePlaceId: restaurant.googlePlaceId,
                affiliation: restaurant.affiliation,
                sponsorGiftPoints: restaurant.sponsorGiftPoints,
                affiliateGiftPoints: restaurant.affiliateGiftPoints,
                sponsorMessage: restaurant.sponsorMessage,
                wheelGifts: restaurant.wheelGifts,
            },
            config(),
        )
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};
