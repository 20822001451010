// components
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InputField from "components/fields/InputField";
import SaveStatus from "components/actions/SaveStatus";
import { LuRefreshCw } from "react-icons/lu";
import { dateToFrenchShort } from "utils/date";
import AddPointsModal from "./components/addPointsModal";
import { useDisclosure } from "@chakra-ui/hooks";
import RemovePointsModal from "./components/removePointsModal";
import {
    addPointsToUser,
    getLoyaltyLogs,
    removePointsToUser,
    getMyRestaurant,
    updateMyRestaurant,
    loginFromMagicLink,
} from "interfaces/external/tablet-manager";
import { MdCheck } from "react-icons/md";
import LiveChatFooter from "components/helper/LiveChatFooter";
import Pagination from "components/pagination/Pagination";

const Shop = () => {
    const params = useParams();
    const [customers, setCustomers] = useState(null);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [isTokenInit, setIsTokenInit] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const {
        isOpen: isOpenRemove,
        onClose: onCloseRemove,
        onOpen: onOpenRemove,
    } = useDisclosure();
    const [limitPerPage, setLimitPerPage] = useState(5);
    const [restaurant, setRestaurant] = useState(null);
    const [saveStatus, setSaveStatus] = useState("none");
    const [refreshing, setRefreshing] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [confirmationMsg, setConfirmationMsg] = useState("");

    const handleChange = (e: any) => {
        setConfirmationMsg(null);
        setRestaurant({
            ...restaurant,
            [e.target.id]: e.target.value,
        });
    };

    const dateToTime = (date: any) => {
        const hour = new Date(date).getHours().toString().padStart(2, "0");
        const minutes = new Date(date).getMinutes().toString().padStart(2, "0");
        return `${hour}H${minutes}`;
    };

    const fetchCustomers = async (page: number, limit: number) => {
        const res: any = await getLoyaltyLogs(page, limit);
        if (res) {
            setCustomers(res.data);
            setTotal(res.total);
            setPage(page);
            setLimitPerPage(limit);
        }
    };

    const addPoints = async (points: number) => {
        const res = await addPointsToUser(
            restaurant._id,
            selectedCustomer._id,
            points,
        );
        if (res) refresh();
    };

    const removePoints = async (points: number) => {
        const res = await removePointsToUser(
            restaurant._id,
            selectedCustomer._id,
            points,
        );
        if (res) refresh();
    };

    const initRestaurant = async () => {
        const restaurant = await getMyRestaurant();
        setRestaurant(restaurant);
    };

    const refresh = async () => {
        setRefreshing(true);
        fetchCustomers(page, limitPerPage);
        initRestaurant();
        setTimeout(() => {
            setRefreshing(false);
        }, 1000);
    };

    useEffect(() => {
        if (restaurant === null) return;
        setSaveStatus("saving");
        const delayDebounceFn = setTimeout(async () => {
            const res = await updateMyRestaurant(restaurant);
            if (res) {
                setSaveStatus("saved");
                setConfirmationMsg(
                    `La tablette fera gagner ${restaurant.nextCustomerReward} points dans quelques secondes.`,
                );
                // setTimeout(() => {
                //     setConfirmationMsg(null);
                // }, 5000);
            } else setSaveStatus("error");
        }, 2500);
        return () => clearTimeout(delayDebounceFn);
    }, [restaurant]);

    const timeAgo = (date: any) => {
        const now = new Date();
        const diff = now.getTime() - new Date(date).getTime();
        const minutes = Math.floor(diff / 60000);
        if (minutes < 1) {
            return `${Math.floor(diff / 1000)} secondes`;
        } else if (minutes < 60) {
            return `${minutes} minutes`;
        } else if (minutes < 1440) {
            return `${Math.floor(minutes / 60)} heures`;
        } else {
            return `${Math.floor(minutes / 1440)} jours`;
        }
    };

    const initTokenFromMagicLink = async () => {
        const magicLink = params.id;
        const res = await loginFromMagicLink(magicLink);
        if (res) {
            localStorage.setItem("tablet-manager-token", res.token);
            setTimeout(() => {
                setIsTokenInit(true);
            }, 100);
        }
    };

    useEffect(() => {
        if (!isTokenInit) return;
        fetchCustomers(page, limitPerPage);
        initRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTokenInit]);

    useEffect(() => {
        initTokenFromMagicLink();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AddPointsModal
                isOpen={isOpen}
                onClose={onClose}
                addPoints={addPoints}
            />
            <RemovePointsModal
                isOpen={isOpenRemove}
                onClose={onCloseRemove}
                rewards={restaurant?.rewards}
                removePoints={removePoints}
            />
            <LuRefreshCw
                size={20}
                className={`absolute text-navy-700 left-4 top-4 ${
                    refreshing ? "animate-spin" : "cursor-pointer"
                }`}
                onClick={refresh}
            />
            <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 px-4">
                <SaveStatus status={saveStatus} />
                <div className="relative h-full w-full rounded-[20px]">
                    <div className="">
                        <InputField
                            label="Nombre de points offerts par la tablette"
                            id="nextCustomerReward"
                            extra="w-full mt-5"
                            placeholder="chargement..."
                            value={
                                confirmationMsg && saveStatus === "saved"
                                    ? restaurant?.defaultCustomerReward
                                    : restaurant?.nextCustomerReward
                            }
                            onChange={handleChange}
                            type="number"
                        />
                        {confirmationMsg && (
                            <div className="ml-2 flex">
                                <MdCheck className="flex my-auto text-green-700 border border-green-700 rounded-full" />
                                <p className="p-2 text-xs text-green-700 font-medium">
                                    {confirmationMsg}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="mt-3">
                        <label
                            htmlFor={"lastCustomers"}
                            className={`text-sm font-medium text-navy-700`}
                        >
                            Derniers actions
                        </label>
                    </div>
                    <div className="mt-1 grid grid-cols-1 gap-3">
                        {customers && customers.length === 0 && (
                            <p className="mt-10 mb-10 text-xl px-5 text-center text-gray-900">
                                Aucun client n'utilise votre tablette pour le
                                moment.
                            </p>
                        )}
                        {customers &&
                            customers.map((customer: any) => (
                                <div className="relative w-full border rounded-xl p-4 gap-4 grid grid-cols-5 items-center bg-white shadow-md">
                                    {/* Points Indicator */}
                                    <p
                                        className={`flex lg:col-span-12 items-center justify-center rounded-full h-10 w-10 border-2 font-bold ${
                                            customer?.points > 0
                                                ? "text-green-500"
                                                : "text-red-500"
                                        }`}
                                    >
                                        {customer?.points > 0
                                            ? `+${customer?.points}`
                                            : customer?.points}
                                    </p>

                                    {/* Customer Details */}
                                    <div className="col-span-4 space-y-1">
                                        <p className="text-md font-semibold text-gray-900">
                                            {customer?.user?.phone.replace(
                                                /(.{2})/g,
                                                "$1 ",
                                            )}
                                        </p>
                                        <p className="text-sm text-gray-900">
                                            {customer?.user?.email}
                                        </p>
                                    </div>

                                    {/* Description */}
                                    <p className="col-span-12 text-sm text-gray-900 px-2">
                                        {customer?.description}
                                    </p>

                                    {/* Date */}
                                    <p className="absolute top-2 right-4 text-xs text-gray-900">
                                        {dateToFrenchShort(customer?.createdAt)
                                            ? `Il y a ${timeAgo(
                                                  customer?.createdAt,
                                              )}`
                                            : "Aucun scan"}
                                    </p>

                                    {/* Action Buttons */}
                                    <p className="col-span-12 text-sm text-center font-bold text-gray-900">
                                        {customer?.user?.rewards?.length >= 1
                                            ? ` ${customer?.user?.rewards[0].points} points cumulés`
                                            : ` aucun point cumulé`}
                                    </p>
                                    <div className="col-span-12 flex space-x-3">
                                        <button
                                            className="flex-1 text-sm font-medium border rounded-lg p-2 text-gray-900 transition"
                                            onClick={() => {
                                                setSelectedCustomer(
                                                    customer?.user,
                                                );
                                                onOpen();
                                            }}
                                        >
                                            + Points
                                        </button>
                                        <button
                                            className="flex-1 text-sm font-medium border rounded-lg p-2 text-gray-900 transition"
                                            onClick={() => {
                                                setSelectedCustomer(
                                                    customer?.user,
                                                );
                                                onOpenRemove();
                                            }}
                                        >
                                            Offrir un cadeau
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <Pagination
                        extra="mt-3"
                        text={false}
                        maxItems={total}
                        itemsPerPage={[5]}
                        page={page}
                        onChange={(page: number, limit: number) =>
                            fetchCustomers(page, limit)
                        }
                    />
                </div>
            </div>
            <LiveChatFooter />
        </>
    );
};

export default Shop;
