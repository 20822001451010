const CreateButton = (props: {value: string, extra?: string, onClick?: any}) => {
	const {value, extra, onClick} = props;
    return (
        <button className={`linear flex items-center justify-center rounded-xl bg-orange-400 px-4 py-3 text-base font-medium text-white hover:bg-orange-600 active:bg-orange-700 ${extra}`}
		onClick={onClick}>
            {value}
        </button>
    );
};

export default CreateButton;