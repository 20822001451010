import Card from "components/card";
import { useEffect, useState } from "react";
import { dateToFrench } from "utils/date";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import {
    confirmPaiement,
    getCardInfo,
    getTokenLogs,
    purchaseToken,
} from "interfaces/stripe";
import Pagination from "components/pagination/Pagination";

const Actions: any = {
    purchase: "Achat de tokens",
};

const Tokens = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedPack, setSelectedPack] = useState<any>(null);
    const [card, setCard] = useState<any>(null);
    const [paiementLoading, setPaiementLoading] = useState(false);
    const [tokenLogs, setTokenLogs] = useState<any>(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const paymentIntent = queryParameters.get("payment_intent");
    const [page, setPage] = useState(1);
    const limit = 5;

    const initCardInfo = async () => {
        const cardInfo = await getCardInfo();
        if (!cardInfo) return;
        setCard(cardInfo);
    };

    const fetchTokenLogs = async (page: number, limit: number) => {
        const logs = await getTokenLogs(page, limit);
        if (!logs) return;
        setPage(page);
        setTokenLogs(logs);
    };

    const startConfirmPaiement = async () => {
        const res = await confirmPaiement(paymentIntent);
        if (!res) return;
        window.location.href = "/tokens";
    };

    const handlePurchaseToken = async (quantity: number) => {
        if (!quantity) return;
        setPaiementLoading(true);
        const res = await purchaseToken(quantity);

        if (res.requires_action) {
            window.location.href = res.redirect_url;
        } else {
            setPaiementLoading(false);
            window.location.reload();
        }
    };

    useEffect(() => {
        initCardInfo();
        fetchTokenLogs(page, limit);
        if (paymentIntent) startConfirmPaiement();
    }, []);

    const ConfirmPaiementModal = (props: {
        isOpen: any;
        onClose: any;
        description: any;
        context: string;
        deleteFunction: any;
        archived?: boolean;
    }) => {
        const { isOpen, onClose } = props;

        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] text-2xl font-bold">
                                Acheter des tokens
                            </h1>
                            <p>
                                {">"} {selectedPack?.quantity} tokens pour{" "}
                                {selectedPack?.price}€ HT.
                            </p>
                            <p>
                                {">"} Équivalent {selectedPack?.quantity} SMS
                            </p>
                            <p>{">"} Valable jusqu'à la fin du mois.</p>
                            <div className="mt-5 mx-auto">
                                <button
                                    className={`px-5 linear w-full rounded-xl ${selectedPack?.btnColor} border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                                    onClick={() =>
                                        handlePurchaseToken(
                                            selectedPack?.quantity / 100,
                                        )
                                    }
                                >
                                    {paiementLoading ? (
                                        <div className="justify-centerx">
                                            <svg
                                                aria-hidden="true"
                                                className="flex mx-auto w-7 h-7 text-gray-500 animate-spin dark:text-gray-600 fill-gray-900"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                        </div>
                                    ) : (
                                        `Recharger ${selectedPack?.quantity} tokens`
                                    )}
                                </button>
                                <p className="mt-1 text-sm text-center">
                                    {selectedPack?.price}€HT via une{" "}
                                    {card?.display_brand} se terminant par:{" "}
                                    {card?.last4}
                                </p>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };

    const ModuleCard = (props: {
        name: string;
        gradient: string;
        btnColor: string;
        price: number;
        quantity?: number;
        onClick: any;
    }) => {
        const { name, gradient, btnColor, price, onClick } = props;

        return (
            <div
                className="relative w-full mt-2 cursor-default shadow-lg border-2 border-[#1f2937] rounded-xl"
                onClick={() => {}}
            >
                <div
                    className={`px-2 py-2 border bg-gradient-to-r ${gradient} rounded-t-lg flex items-center justify-center`}
                >
                    <p className="my-5 align-middle text-center text-lg font-han">
                        {name}
                    </p>
                </div>
                {/* Badge */}
                {/* <div className="absolute top-3 right-3 bg-green-600/80 text-white text-xs px-2 py-1 rounded-lg">
                    {"Gratuit"}
                </div> */}
                {/* Header */}
                <div className="py-3 w-full px-4 rounded-b-lg border shadow">
                    <div className="mt-2 grid grid-cols-2 gap-4">
                        <div
                            className={`p-1 px-3 rounded-xl flex gap-3 border-2 border-[#1f2937] bg-gradient-to-r ${gradient}`}
                        >
                            <p className="flex my-auto font-bold">{price}€</p>
                            <div className="h-full w-1 bg-[#1f2937] rounded-full"></div>
                            <p className="flex my-auto text-xs">Prix HT</p>
                        </div>
                        <div
                            className={`p-1 px-3 rounded-xl flex gap-3 border-2 border-[#1f2937] bg-gradient-to-r ${gradient}`}
                        >
                            <p className="flex my-auto font-bold">0,09€</p>
                            <div className="h-full w-1 bg-[#1f2937] rounded-full"></div>
                            <p className="flex my-auto mx-auto text-xs">
                                par token
                            </p>
                        </div>
                    </div>
                    <button
                        className={`mb-4 linear mt-6 w-full rounded-xl ${btnColor} border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                        onClick={() => {
                            setSelectedPack(props);
                            onClick();
                        }}
                    >
                        Recharger
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <ConfirmPaiementModal
                isOpen={isOpen}
                onClose={onClose}
                description="Voulez-vous vraiment archiver cet utilisateur ?"
                context="l'utilisateur"
                deleteFunction={() => {}}
            />
            <h1 className="mt-5 text-xl md:text-2xl md:mt-0 font-bold">
                Recharge
            </h1>
            <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-4">
                <ModuleCard
                    name="Recharge de 100 tokens"
                    gradient="from-[#f1e9f8] to-[#f1e9f8b2]"
                    btnColor="bg-[#f1e9f8]"
                    price={9}
                    quantity={100}
                    onClick={onOpen}
                />
                <ModuleCard
                    name="Recharge de 300 tokens"
                    gradient="from-talk-500 to-[#fedd6597]"
                    btnColor="bg-talk-500"
                    price={27}
                    quantity={300}
                    onClick={onOpen}
                />
                <ModuleCard
                    name="Recharge de 500 tokens"
                    gradient="from-[#d4adfe] to-[#d4adfe9b]"
                    btnColor="bg-[#d4adfe]"
                    price={45}
                    quantity={500}
                    onClick={onOpen}
                />
            </div>
            <h1 className="mt-5 text-xl md:text-2xl font-bold">Transactions</h1>
            {!tokenLogs && (
                <div className="mt-10 justify-center">
                    <svg
                        aria-hidden="true"
                        className="flex mx-auto w-10 h-10 text-talk-500/30 animate-spin dark:text-gray-600 fill-talk-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                        />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                        />
                    </svg>
                    <p className="text-center text-talk-500 font-han mt-5 text-lg">
                        Chargement...
                    </p>
                </div>
            )}
            {tokenLogs?.data?.length > 0 && (
                <div className="mt-4 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium">
                    <p className="col-span-3">Utilisateur</p>
                    <p className="col-span-3">Date</p>
                    <p className="col-span-3">Action</p>
                    <p className="col-span-2">Crédits</p>
                </div>
            )}
            <div className="mt-1 grid grid-cols-1 gap-3">
                {tokenLogs &&
                    tokenLogs?.data?.map((log: any, index: number) => (
                        <div className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4">
                            <div className="col-span-3 flex gap-2">
                                <img
                                    src={`https://ui-avatars.com/api/?name=m`}
                                    alt="Customer"
                                    className="flex my-auto w-[30px] rounded-full"
                                ></img>
                                <p className="flex my-auto">Moi même</p>
                            </div>
                            <p className="col-span-3">
                                {dateToFrench(log.createdAt)}
                            </p>
                            <p className="col-span-3">{Actions[log.action]}</p>
                            <p className="hidden sm:flex col-span-3 sm:col-span-2">
                                +{log.tokenMovement}
                            </p>
                            <p className="col-span-3 sm:hidden sm:col-span-2">
                                +{log.tokenMovement} points
                            </p>
                        </div>
                    ))}
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={tokenLogs?.total}
                    itemsPerPage={[5]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchTokenLogs(page, limit)
                    }
                />
                {tokenLogs?.data?.length === 0 && (
                    <div className="mt-3 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center">
                            Rien pour le moment !
                        </p>
                        <p className="mt-3 text-center">
                            Achetez ou dépensez des tokens pour voir vos actions
                            ici
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};
export default Tokens;
