import { useState } from "react";

function GoBackButton(props: { onclick: any }) {
    const { onclick } = props;
    return (
        <div
            className="flex items-center justify-start cursor-pointer"
            onClick={onclick}
        >
            <svg
                className="w-7 h-7 mr-2"
                fill="none"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </div>
    );
}

function AskEmail(props: { onSubmit: (phone: string) => void; goBack: any }) {
    const { onSubmit, goBack } = props;
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const emailDomains = [
        "@gmail.com",
        "@outlook.com",
        "@yahoo.fr",
        "@hotmail.com",
        "@hotmail.fr",
    ];

    const handleEmailChange = (e: any) => {
        if (error) setError(null);
        setEmail(e.target.value);
    };

    const handleDomainClick = (domain: any) => {
        const [user] = email.split("@");
        setEmail(`${user}${domain}`);
    };

    const handleSubmit = (email: string) => {
        if (!email || !email.includes("@") || !email.includes(".")) {
            setError("Adresse email invalide");
            return;
        }
        onSubmit(email);
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
            <div className="bg-[#9191e9] shadow-2xl rounded-sm h-screen">
                {/* Center text middle of screen */}
                <div className="ml-10 mt-[30%]">
                    <h1 className="text-white text-[45px] font-bold pt-10">
                        Merci pour votre visite !
                    </h1>
                    <p className="text-white text-xl mt-3">
                        Entre ton email pour finaliser ton compte
                    </p>
                </div>
            </div>
            {/* Request user emails */}
            <div className="flex flex-col items-center justify-center min-h-screen p-4">
                <div className="absolute top-4 left-4">
                    <GoBackButton onclick={goBack} />
                </div>
                <p className="flex items-start text-left text-red-500 font-bold text-sm justify-start">
                    {error}
                </p>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Votre adresse email"
                    className="mt-2 w-[80%] p-4 text-lg rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-blue-500 outline-none transition shadow-sm placeholder-gray-400"
                />

                <div className="flex flex-wrap gap-2 mt-4 w-[80%]">
                    {emailDomains.map((domain) => (
                        <button
                            key={domain}
                            onClick={() => handleDomainClick(domain)}
                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-full text-xs font-medium transition hover:bg-blue-100 hover:text-blue-600 focus:bg-blue-100 focus:text-blue-600"
                        >
                            {domain}
                        </button>
                    ))}
                </div>

                <div className="mt-4 text-left text-xs text-gray-500">
                    En m'inscrivant j'acceptes recevoir des offres exclusives
                    par email et sms.
                </div>
                <button
                    onClick={() => handleSubmit(email)}
                    className="mt-3 p-2 w-[80%] bg-[#9191e9] text-white text-xl font-semibold rounded-lg shadow-lg hover:bg-priamy/80 active:bg-primary/70 transition-all duration-200 focus:outline-none"
                >
                    Recevoir mon point de fidélité
                </button>
            </div>
        </div>
    );
}

export default AskEmail;
