import Card from "components/card";
import TopCustomerElement from "./TopCustomerElement";

function TopCustomers(props: { data: any }) {
    const { data } = props;
    return (
        <Card extra="px-[34px] pt-3 rounded-[20px] w-full border">
            <h4 className="ml-px text-lg font-bold text-navy-700 dark:text-white">
                Top clients
            </h4>
            <p className="mb-2">En fonction des points disponible</p>
            {data &&
                data
                    .slice(0, 5)
                    .map((item: any, index: number) => (
                        <TopCustomerElement
                            key={index}
                            givenName={item.givenName}
                            total={item.points}
                            email={item.email}
                            rank={index + 1}
                        />
                    ))}
            {/* <div className="contain bottom-1 w-max items-center gap-1">
        <div className="text-base font-bold text-toola-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          See all transfers
        </div>
      </div> */}
        </Card>
    );
}

export default TopCustomers;
