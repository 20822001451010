import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
const ProductionTips = () => {
  return (
    <Card extra={"w-full p-5 border"}>
      {/* Header */}
      <div>
        <h4 className="text-lg font-medium">
          Conseils de production (Prochainement)
        </h4>
        <p className="mt-2 text-base text-gray-600">
          Prédictions suivant vos productions et ventes antèrieurs
        </p>
      </div>

      {/* Timeline items */}
      <div className="mt-2 blur-sm">
        <TimelineItem
          title="Meeting with a client"
          day="03"
          weekday="Wed"
          hours="10:30 - 12:00"
          current="current"
          mb="mb-[16px]"
        />
        <TimelineItem
          mb="mb-[16px]"
          title="Webinar - Marketing for Developers"
          day="12"
          weekday="Fri"
          hours="10:30 - 12:00"
        />
        <TimelineItem
          mb="mb-[16px]"
          title="Design UI and check sales on PayPal"
          day="16"
          weekday="Tue"
          hours="09:00 - 14:00"
        />
      </div>
    </Card>
  );
};

export default ProductionTips;
