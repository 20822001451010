import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ActivityChart = (props: { data: any }) => {
    const { data } = props;

    const [barSerie, setBarSerie] = useState<any[]>([
        {
            name: "Client connecté",
            data: [0],
        },
        {
            name: "Nouveau client",
            data: [0],
        },
    ]);

    const [barOptions, setBarOptions] = useState<any>({
        chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                borderRadiusApplication: "end", // 'around', 'end'
                borderRadiusWhenStacked: "last", // 'all', 'last'
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        xaxis: {
            type: "datetime",
            categories: [
                "08/08/2024 GMT",
                "08/09/2024 GMT",
                "08/10/2024 GMT",
                "08/11/2024 GMT",
                "08/12/2024 GMT",
                "08/13/2024 GMT",
            ],
        },
        legend: {
            position: "right",
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
    });

    const formatDate = (date: string) => {
        const dateArray = date.split("-");
        return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]} GMT`;
    };

    useEffect(() => {
        if (!data) return;
        console.log(data.activity);
        setBarSerie([
            {
                name: "Client existant",
                data: data.activity.lastScan,
            },
            {
                name: "Nouveau client",
                data: data.activity.createdAt,
            },
        ]);
        const dates = data.activity.dates.map((date: string) =>
            formatDate(date),
        );
        setBarOptions({
            ...barOptions,
            xaxis: {
                ...barOptions.xaxis,
                categories: dates,
            },
        });
    }, [data]);

    return (
        <div className="border rounded-xl p-6 col-span-1 md:col-span-3">
            <h1 className="text-lg font-bold">Usage programme de fidélité</h1>
            <ReactApexChart
                options={barOptions as any}
                series={barSerie as any}
                type="bar"
                width="100%"
                height="85%"
            />
        </div>
    );
};

export default ActivityChart;
