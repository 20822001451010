import TalkImg from "assets/img/talk-logo-2.svg";
import { useNavigate } from "react-router-dom";

const Audit = () => {
    const nav = useNavigate();

    const storeResult = (key: string, index: number, score: number) => {
        localStorage.setItem(key, JSON.stringify({ index, score }));
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-12">
            {/* Left side */}
            <div className="hidden lg:block col col-span-4 border-r-2 border-b-2 rounded-b-lg border-[#1f2937] p-5 bg-talk-500 h-screen">
                <div
                    className={`flex mx-auto items-center bg-white w-[140px] rounded-lg border-2 border-[#1f2937]`}
                >
                    <img
                        className={`p-2 w-[140px]`}
                        src={TalkImg}
                        alt="Fidiz logo"
                    />
                </div>
                <p className="mt-3 text-lg text-center">
                    Faites revenir vos clients en boutique avec Fidiz
                </p>
                <div className="mt-[100px] px-10">
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            1
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Capter les infos de vos clients.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            2
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Lancer des campagnes marketing.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            3
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Mesurez l'impact de vos actions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="col col-span-8 p-10 px-[20px] lg:px-[80px]">
                <h1 className="font-han text-xl font-bold">
                    1/5 - Quel est votre rythme sur les reseaux sociaux ?
                </h1>
                <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div
                        className="border shadow-lg rounded-xl  p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_1", 0, 5);
                            nav("/audit/2");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                🤭 Moins de 1 post par mois
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Vous n'avez pas encore de réseaux sociaux ou
                                vous n'êtes pas très actif pour le moment. Votre
                                visibilté est réduite.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl  p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_1", 1, 10);
                            nav("/audit/2");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                😄 Entre 1 et 3 posts par mois
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Vous avez déjà un compte Instagram ou Facebook
                                et vous publiez de temps en temps. Vos clients
                                peuvent vous suivre.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl  p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_1", 2, 13);
                            nav("/audit/2");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                😎 Entre 3 et 5 posts par mois
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Vous publiez assez régulièrement sur vos réseaux
                                et vous avez une petite communauté qui vous
                                suit.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl  p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_1", 3, 20);
                            nav("/audit/2");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                🚀 Plus de 5 posts par mois
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Vous prenez à coeur vos réseaux sociaux. Votre
                                communauté est engagée ou en croissance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Audit;
