import { getIcon } from "utils/icons";
import { MdOutlineFastfood } from "react-icons/md";

import ActionButton from "components/buttons/ActionButton";
import CancelButton from "components/buttons/CancelButton";
import CreateButton from "components/buttons/CreateButton";
import CustomerCard from "components/card/CustomerCard";
import DeliveryCard from "components/card/deliveryCard";
import EventCalendar from "components/calendar/EventCalendar";
import InputField from "components/fields/InputField";
import MiniStatistics from "components/card/MiniStatistics";
import OrderCard from "components/card/orderCard";
import SearchSelector from "components/fields/SearchSelector";
import Select from "components/fields/Select";
import StockCard from "components/card/StockCard";
import LastProductions from "views/admin/main/profile/overview/components/Project";
import MovementTable from "views/stock/stockList/components/MovementTable";
import ProductionTips from "views/admin/main/applications/calendar/components/ProductionTips";

const handleChange = (e: any) => {};

const Inputs = () => {
    return (
        <>
            <h3 className="mt-5 text-lg">Champs de saisie</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">Champ texte</span>
                        <p className="text-sm mb-5">
                            Nom du composant : InputField
                            <br />
                            Props :
                            <ul>
                                <li>label [string]</li>
                                <li>placeholder [string]</li>
                                <li>id [string]</li>
                                <li>type [string]</li>
                                <li>onChange [any]</li>
                            </ul>
                        </p>
                    </div>

                    <InputField
                        label="Nom"
                        placeholder="Mathieu"
                        id="id"
                        type="text"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Champ nombre
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : InputField
                            <br />
                            Props :
                            <ul>
                                <li>label [string]</li>
                                <li>placeholder [string]</li>
                                <li>id [string]</li>
                                <li>type [string]</li>
                                <li>onChange [any]</li>
                            </ul>
                        </p>
                    </div>

                    <InputField
                        label="Quantité"
                        placeholder="10"
                        id="id"
                        type="number"
                        onChange={handleChange}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">Champ date</span>
                        <p className="text-sm mb-5">
                            Nom du composant : InputField
                            <br />
                            Props :
                            <ul>
                                <li>label [string]</li>
                                <li>placeholder [string]</li>
                                <li>id [string]</li>
                                <li>type [string]</li>
                                <li>onChange [any]</li>
                            </ul>
                        </p>
                    </div>

                    <InputField
                        label="Date de commande"
                        placeholder="01/12/2023"
                        id="id"
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={handleChange}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Liste déroulante
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : Select
                            <br />
                            Props :
                            <ul>
                                <li>label [string]</li>
                                <li>options [any]</li>
                                <li>displayKey [string]</li>
                                <li>onChange [any]</li>
                                <li>value [string]</li>
                            </ul>
                        </p>
                    </div>

                    <div>
                        <Select
                            label="Statut"
                            options={[
                                { option: "En cours", value: "opt1" },
                                { option: "Annulée", value: "opt2" },
                                { option: "Terminée", value: "opt3" },
                            ]}
                            displayKey="option"
                            onSelect={handleChange}
                            value=""
                        />
                    </div>
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Liste déroulante avec recherche
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : SearchSelector
                            <br />
                            Props :
                            <ul>
                                <li>label [string]</li>
                                <li>hideLabel [bool]</li>
                                <li>options [any]</li>
                                <li>displayKey [string]</li>
                                <li>onSelect [any]</li>
                                <li>value [string]</li>
                                <li>canCreate [bool]</li>
                                <li>createNew [any]</li>
                            </ul>
                        </p>
                    </div>

                    <SearchSelector
                        label="Produit"
                        displayKey="option"
                        options={[
                            { option: "Jus d'orange", value: "opt1" },
                            { option: "Sucre", value: "opt2" },
                            { option: "Café", value: "opt3" },
                        ]}
                        onSelect={handleChange}
                    />
                </div>
            </div>
        </>
    );
};

const Buttons = () => {
    return (
        <>
            <h3 className="mt-5 text-lg">Boutons</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Bouton d'action
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : ActionButton
                            <br />
                            Props :
                            <ul>
                                <li>value [string]</li>
                                <li>extra [string]</li>
                                <li>onClick [any]</li>
                            </ul>
                        </p>
                    </div>

                    <ActionButton value="Produire" extra="mt-3" />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Bouton de création
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : CreateButton
                            <br />
                            Props :
                            <ul>
                                <li>value [string]</li>
                                <li>extra [string]</li>
                                <li>onClick [any]</li>
                            </ul>
                        </p>
                    </div>

                    <CreateButton value="Créer un produit" extra="mt-3" />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Bouton d'annulation
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : CancelButton
                            <br />
                            Props :
                            <ul>
                                <li>value [string]</li>
                                <li>extra [string]</li>
                                <li>onClick [any]</li>
                            </ul>
                        </p>
                    </div>

                    <CancelButton value="Retour" extra="mt-3" />
                </div>
            </div>
        </>
    );
};

const Cards = () => {
    return (
        <>
            <h3 className="mt-5 text-lg">Cartes</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Carte statistiques
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : MiniStatistics
                            <br />
                            Props :
                            <ul>
                                <li>name [string]</li>
                                <li>values [string]</li>
                                <li>icon [JSX element]</li>
                                <li>value [string]</li>
                                <li>iconBg [string]</li>
                            </ul>
                        </p>
                    </div>

                    <MiniStatistics
                        name="Produits"
                        value={"12"}
                        icon={<MdOutlineFastfood color="#0260CB" />}
                        iconBg="bg-lightPrimary"
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Carte produit
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : StockCard
                            <br />
                            Props :
                            <ul>
                                <li>name [string]</li>
                                <li>img [string]</li>
                                <li>unity [string]</li>
                                <li>quantity [number]</li>
                                <li>type [string]</li>
                                <li>id [string]</li>
                                <li>alert [number]</li>
                                <li>reference [number]</li>
                            </ul>
                        </p>
                    </div>

                    <StockCard
                        id={"abc"}
                        key={0}
                        name={"Orange"}
                        type={"product"}
                        alert={150}
                        img={getIcon("orange", "ingredient")}
                        unity={"kg"}
                        quantity={35}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Carte client
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : CustomerCard
                            <br />
                            Props :
                            <ul>
                                <li>image [string]</li>
                                <li>company [string]</li>
                                <li>email [string]</li>
                                <li>phone [string]</li>
                                <li>download [string]</li>
                                <li>commands [string|number]</li>
                                <li>extra [string]</li>
                                <li>id [string]</li>
                                <li>customerId [string]</li>
                            </ul>
                        </p>
                    </div>

                    <CustomerCard
                        image={"abc"}
                        company={"Toola"}
                        email={"mathieu@toola.eu"}
                        phone={"0102030405"}
                        commands={""}
                        id={"1"}
                        customerId={"1"}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Carte commande
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : OrderCard
                            <br />
                            Props :
                            <ul>
                                <li>company [string]</li>
                                <li>download [string]</li>
                                <li>extra [string]</li>
                                <li>id [string]</li>
                                <li>orderId [string]</li>
                                <li>deliveryDate [string]</li>
                                <li>createdAt [string]</li>
                                <li>items [any]</li>
                            </ul>
                        </p>
                    </div>

                    <OrderCard
                        company={"Toola"}
                        deliveryDate={"01/12/2023"}
                        createdAt={"01/11/2023"}
                        items={[
                            {
                                name: "Jus d'orange",
                                quantity: 12,
                            },
                            {
                                name: "Sucre",
                                quantity: 7,
                            },
                            {
                                name: "Café",
                                quantity: 10,
                            },
                        ]}
                        id={"1"}
                        orderId={"1"}
                    />
                </div>

                <div className="col-span-12 md:col-span-2 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Carte livraison
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : DeliveryCard
                            <br />
                            Props :
                            <ul>
                                <li>company [string]</li>
                                <li>download [string]</li>
                                <li>extra [string]</li>
                                <li>id [string]</li>
                                <li>orderId [string]</li>
                                <li>deliveryDate [string]</li>
                                <li>createdAt [string]</li>
                                <li>items [any]</li>
                            </ul>
                        </p>
                    </div>

                    <DeliveryCard
                        external={false}
                        date={"01/12/2023"}
                        customersNbr={1}
                        ordersNbr={1}
                        products={[]}
                        deliveryTourName={"Nom de la tournée"}
                        deliveryTourId={"1"}
                        deliveryId={""}
                        key={1}
                    />
                </div>
            </div>
        </>
    );
};

const Others = () => {
    return (
        <>
            <h3 className="mt-5 text-lg">Autres</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 md:col-span-5 h-96">
                    <div>
                        <span className="text-base font-bold">Calendrier</span>
                        <p className="text-sm mb-5">
                            Nom du composant : EventCalendar
                            <br />
                            Props :
                            <ul>
                                <li>calendarData [any]</li>
                            </ul>
                        </p>
                    </div>

                    {/* <EventCalendar
                        initialDate={new Date().toISOString()}
                        calendarData={[
                            {title: `Jus d'orange x 100`,
                            start: "01/10/2023",
                            end: "10/10/2013",
                            backgroundColor: "#FF973B",
                            className: "warning"} //error, warning, success
                        ]}
                    /> */}
                </div>
            </div>
        </>
    );
};

const Views = () => {
    return (
        <>
            <h3 className="mt-5 text-lg">Mouvements</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 flex flex-col justify-between">
                    <div>
                        <span className="text-base font-bold">
                            Tableau des mouvements
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : MovementTable
                            <br />
                            Props :
                            <ul>
                                <li>tableData [any]</li>
                            </ul>
                        </p>
                    </div>

                    <MovementTable tableData={""} />
                </div>
            </div>

            <h3 className="mt-5 text-lg">Productions</h3>
            <div className="mt-5 grid grid-cols-10 gap-10">
                <div className="col-span-12 md:col-span-5">
                    <div>
                        <span className="text-base font-bold">
                            Conseils de production
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : ProductionTips
                            <br />
                            Props : -
                        </p>
                    </div>

                    <ProductionTips />
                </div>
                <div className="col-span-12 md:col-span-5">
                    <div>
                        <span className="text-base font-bold">
                            Dernieres production
                        </span>
                        <p className="text-sm mb-5">
                            Nom du composant : LastProductions
                            <br />
                            Props :
                            <ul>
                                <li>productions [any]</li>
                            </ul>
                        </p>
                    </div>

                    <LastProductions
                        productions={[
                            {
                                productName: `Jus d'orange`,
                                quantity: 100,
                                date: "01/12/2023",
                                backgroundColor: "#FF973B",
                                status: "scheduled",
                                comment: "comentaire",
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

const UiKit = () => {
    return (
        <div className="container mx-auto px-5 pt-5">
            <h1 className="text-4xl">Ui Kit</h1>
            <h2 className="mt-8 text-xl">Composants génériques</h2>
            <Inputs />
            <Buttons />
            <Cards />
            <Others />
            <h2 className="mt-20 pt-16 text-xl">
                Composants spécifiques à une vue
            </h2>
            <Views />
        </div>
    );
};

export default UiKit;
