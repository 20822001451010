import LiveChatFooter from "components/helper/LiveChatFooter";
import { getMyInfo } from "interfaces/user";
import { useEffect, useState } from "react";

declare global {
    namespace JSX {
        interface IntrinsicElements {
            "stripe-pricing-table": React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >;
        }
    }
}

const Subscribe = () => {
    const [me, setMe] = useState<any>(null);

    const initMe = async () => {
        const me = await getMyInfo();
        setMe(me.data);
    };

    useEffect(() => {
        initMe();
    }, []);

    return (
        <>
            {me && me.email && (
                <stripe-pricing-table
                    pricing-table-id="prctbl_1QD1zlA65fhVNUV2NkDMDRm4"
                    publishable-key="pk_live_51Olp7gA65fhVNUV2aocEmdHyTv9aCaenVwk7Amn1EPyPVlpQaYeX7gCF3uYhNQccKZ6dqOhVAQ2ztcwfrAmHs1Vn00O3KX2MYL"
                ></stripe-pricing-table>
            )}
            <LiveChatFooter />
        </>
    );
};

export default Subscribe;
