import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import { requestTabletGift, validateCouponTablet } from "interfaces/tablet";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const RequestGiftModal = (props: {
    isOpen: any;
    onCloseWithGift?: any;
    onCloseWithoutGift?: any;
    mail: string;
    rewardId: string;
}) => {
    const params = useParams();
    const { isOpen, onCloseWithGift, onCloseWithoutGift, mail, rewardId } =
        props;
    const [email, setEmail] = useState(mail);
    const [reward, setReward] = useState(rewardId);
    const [codeSent, setCodeSent] = useState(false);
    const [code, setCode] = useState("");
    const restaurantId = params.id;

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handleCodeChange = (e: any) => {
        if (e.target.value.length <= 4) setCode(e.target.value);
    };

    const handleRewardClick = async () => {
        const res = await requestTabletGift(restaurantId, reward, email);
        if (res) {
            setCodeSent(true);
        }
    };

    const handleCodeVerification = async () => {
        const res = await validateCouponTablet(restaurantId, code);
        if (res) {
            onCloseWithGift(res.giftName);
        }
    };

    useEffect(() => {
        setEmail(mail);
    }, [mail]);

    useEffect(() => {
        setReward(rewardId);
    }, [rewardId]);

    return (
        <Modal isOpen={isOpen} onClose={() => {}}>
            <ModalOverlay className="bg-[#000] !opacity-60" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                    <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[650px] flex flex-col !z-[1004]">
                        {codeSent ? (
                            <>
                                <h1 className="flex mx-auto mb-[20px] text-2xl font-bold">
                                    Valider mon code
                                </h1>
                                <input
                                    type="text"
                                    id="text"
                                    value={code}
                                    onChange={handleCodeChange}
                                    placeholder="****"
                                    className="flex mx-auto text-center mt-2 w-[80%] p-4 text-lg rounded-lg border border-gray-300 focus:border-primary focus:ring-primary outline-none transition shadow-sm placeholder-gray-400"
                                />
                                <button
                                    className="mt-5 col-span-12 linear w-[90%] flex mx-auto items-center justify-center rounded-xl bg-[#9191e9] px-10 py-3 text-base font-medium text-white active:bg-[#9191e9]/90"
                                    onClick={handleCodeVerification}
                                >
                                    Réclamer mon cadeau
                                </button>
                                <p
                                    className="flex mx-auto mt-2 text-center text-md text-gray-500 hover:underline hover:cursor-pointer"
                                    onClick={onCloseWithoutGift}
                                >
                                    Fermer sans réclamer mon cadeau
                                </p>
                            </>
                        ) : (
                            <>
                                <h1 className="flex mx-auto mb-[20px] text-2xl font-bold">
                                    Confirme ton adresse email
                                </h1>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    placeholder="Votre adresse email"
                                    className="flex mx-auto mt-2 w-[80%] p-4 text-lg rounded-lg border border-gray-300 focus:border-primary focus:ring-primary outline-none transition shadow-sm placeholder-gray-400"
                                />
                                <button
                                    className="mt-5 col-span-12 linear w-[90%] flex mx-auto items-center justify-center rounded-xl bg-[#9191e9] px-10 py-3 text-base font-medium text-white active:bg-[#9191e9]/90"
                                    onClick={handleRewardClick}
                                >
                                    Recevoir mon code et ma récompense
                                    maintenant
                                </button>
                                <p
                                    className="flex mx-auto mt-2 text-center text-md text-gray-500 hover:underline hover:cursor-pointer"
                                    onClick={onCloseWithoutGift}
                                >
                                    Je réclamerai ma récompense à ma prochaine
                                    visite
                                </p>
                            </>
                        )}
                    </Card>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default RequestGiftModal;
