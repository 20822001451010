import { useDisclosure } from "@chakra-ui/hooks";
import { getTabletRewards } from "interfaces/tablet";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";
import RequestGiftModal from "./RequestGiftModal";
import sound from "assets/bip.mp3";

function UserInterface(props: { disconnect: any }) {
    const { disconnect } = props;
    const params = useParams();
    const restaurantId = params.id;
    const [confetti, setConfetti] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [askedReward, setAskedReward] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [claimedReward, setClaimedReward] = useState(null);
    const [giftName, setGiftName] = useState(null);
    const [countdown, setCountdown] = useState(30);
    const [error, setError] = useState(null);

    const initUserInfo = async () => {
        const res = await getTabletRewards(restaurantId);
        if (res) {
            setUserInfo(res);
        }
    };

    const handleRewardClick = async (rewardId: string, points: number) => {
        if (error) setError(null);

        if (userInfo.points < points) {
            setError("Points insuffisants");
            return;
        }

        setAskedReward(rewardId);
        onOpen();
    };

    const startCountdown = () => {
        const interval = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(interval);
            disconnect();
        }, 30000);
    };

    const handleCloseWithGift = (gift: string) => {
        setConfetti(true);
        setTimeout(() => {
            setConfetti(false);
        }, 20000);
        onClose();
        setClaimedReward(askedReward);
        setGiftName(gift);
        startCountdown();
        const audio = new Audio(sound);
        audio.play();
    };

    const handleCloseWithoutGift = () => {
        setAskedReward(null);
        onClose();
    };

    useEffect(() => {
        initUserInfo();
    }, []);

    return (
        <div className="relative bg-[#9191e9] shadow-2xl rounded-sm min-h-screen">
            <RequestGiftModal
                isOpen={isOpen}
                onCloseWithGift={handleCloseWithGift}
                onCloseWithoutGift={handleCloseWithoutGift}
                mail={userInfo?.email}
                rewardId={askedReward}
            />
            {confetti && <Confetti />}
            {/* Center text middle of screen */}
            {claimedReward ? (
                <>
                    <div className="pt-10 relative ml-10 text-center">
                        <p className="mt-10 text-white text-3xl">
                            Votre récompense est débloquée
                        </p>
                        <h1 className="mt-10 text-white text-[45px] font-bold">
                            {giftName}
                        </h1>
                    </div>
                    <div className="absolute justify-center items-center text-center bottom-1 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <button
                            className="bg-white text-[#9191e9] text-3xl text-center justify-center font-bold p-4 rounded-xl w-full"
                            onClick={disconnect}
                        >
                            Me déconnecter ({countdown})
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className="pt-10 ml-10 text-center">
                        <p className="text-white text-xl">Tu as cumulé</p>
                        <h1 className="mt-2 text-white text-[45px] font-bold">
                            {userInfo?.points} points !
                        </h1>
                        {askedReward && !error && (
                            <p className="flex mx-auto text-white animate-pulse mt-2 max-w-[400px]">
                                Email envoyé à {userInfo?.email} avec le QR code
                                de votre cadeau
                            </p>
                        )}
                        {error && (
                            <p className="mt-2 text-red-600 font-bold text-lg text-center">
                                {error}
                            </p>
                        )}
                    </div>
                    {/* Rewards list */}
                    <div className="mt-10 flex flex-col items-center p-2">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pb-10">
                            {userInfo?.rewards.map((reward: any) => (
                                <div
                                    key={reward._id}
                                    className="bg-white rounded-lg text-center p-4 w-full"
                                >
                                    <h1 className="text-lg font-bold">
                                        {reward.name}
                                    </h1>
                                    <p>{reward.points} points</p>
                                    <p className="text-sm">
                                        {reward.description}
                                    </p>
                                    <button
                                        className={`mt-5 text-white p-4 rounded-xl transition ${
                                            userInfo.points < reward.points ||
                                            askedReward
                                                ? "bg-[#9191e9]/50 cursor-default"
                                                : "bg-[#9191e9] hover:bg-[#9191e9]/80"
                                        }`}
                                        onClick={() =>
                                            handleRewardClick(
                                                reward._id,
                                                reward.points,
                                            )
                                        }
                                    >
                                        Recevoir ce cadeau
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Disconnect */}
                    <div className="absolute top-0 right-1 p-4">
                        <button
                            className="bg-white text-[#9191e9] text-xs text-center justify-center font-bold p-4 rounded-xl w-[150px]"
                            onClick={disconnect}
                        >
                            Me déconnecter
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default UserInterface;
