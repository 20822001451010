import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getTargets = async () => {
    const res = await axios.get("/targets", config()).catch(errorHandler);
    return res.data;
};

export const getTarget = async (id: string) => {
    const res = await axios.get(`/targets/${id}`, config()).catch(errorHandler);
    return res.data;
};

export const createTarget = async (data: any) => {
    const res = await axios
        .post("/targets", data, config())
        .catch(errorHandler);
    return res.data;
};

export const updateTarget = async (id: string, data: any) => {
    const res = await axios
        .put(`/targets/${id}`, data, config())
        .catch(errorHandler);
    return res.data;
};

export const deleteTarget = async (id: string) => {
    const res = await axios
        .delete(`/targets/${id}`, config())
        .catch(errorHandler);
    return res.data;
};
