import TalkImg from "assets/img/talk-logo-2.svg";
import { useNavigate } from "react-router-dom";
import ScoreChart from "./components/scoreChart";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useEffect, useState } from "react";

const AuditResult = () => {
    const nav = useNavigate();
    const [page, setPage] = useState(1);
    const [answers, setAnswers] = useState<any[]>([]);
    const results = [
        {
            title: "🤳 Réseaux sociaux",
            advice: "Nous recommandons de publier au minimum 2 fois par semaine, c'est le minimum pour garder une audience active et rester dans la tête de vos clients pour vous assurer qu'ils pensent à vous en premier.",
            goFurther:
                "Vous pouvez déléguer la gestion de vos réseaux sociaux, les shootings photos et la création de contenu. Par exemple avec Fidiz Studio, notre offre sur mesure.",
            yourSituation: [
                "Mois de 1 post par mois",
                "Entre 1 et 3 posts par mois",
                "Entre 3 et 5 posts par mois",
                "Plus de 5 posts par mois",
            ],
        },
        {
            title: "🎯 Re-ciblage",
            advice: "Le re-ciblage marketing est une stratégie puissante pour fidéliser vos clients et faire croître votre chiffre d'affaires. Capter les coordonées clients le plus tôt possible est essentiel pour pouvoir les re-cibler.",
            goFurther:
                "Chez Fidiz nous proposons plusieurs outils comme la roue cadeau et le programme de fidélité qui permettent de collecter les coordonnées de vos clients. Nos outils permettent de re-cibler vos clients via des emails et SMS.",
            yourSituation: [
                "Vous n'avez pas de connaisance sur le re-ciblage",
                "Vous êtes entrain de réfléchir à mettre en place du re-ciblage",
                "Vous avez commencé à mettre en place du re-ciblage",
                "Vous avez mis en place du re-ciblage et ça marche",
            ],
        },
        {
            title: "📈 Collecte d'avis",
            advice: "La collecte d'avis est un élément clé pour rassurer vos clients et en attirer de nouveaux. Nous recommandons d'optimiser au maximum cette étape.",
            goFurther:
                "Vous pouvez mettre en place des campagnes d'avis pour collecter des avis sur Google, mettre en place de QR-code, une roue cadeau ou un programme de fidélité pour inciter vos clients à laisser un avis.",
            yourSituation: [
                "Pas de collecte d'avis",
                "Vous réfléchissez à mettre en place de la collecte d'avis",
                "Vous avez commencé à collecter des avis",
                "Vous avez mis en place la collecte d'avis et ça marche",
            ],
        },
        {
            title: "⭐ Gestion des avis",
            advice: "Nous recommandons de répondre à tous les avis, positifs comme négatifs en 24H maximum. Cela montre à vos clients que vous êtes à l'écoute et que vous prenez en compte leur avis tout en améliorant votre ranking sur Google.",
            goFurther:
                "Vous pouvez déléguer la gestion des avis à une agence spécialisée comme Fidiz Studio.",
            yourSituation: [
                "Vous ne répondez pas aux avis",
                "Vous répondez parfois aux avis négtifs",
                "Vous répondez toujours aux avis négtifs",
                "Vous répondez à tous les avis",
            ],
        },
        {
            title: "#️⃣ Mots-clés",
            advice: "Les mots-clés sont essentiels pour être visible sur Google. Selectionnez les bons mots-clés pour votre activité et optimisez vos réseaux en fonction.",
            goFurther:
                "Vous pouvez déléguer la gestion des mots-clés à une agence spécialisée comme Fidiz Studio.",
            yourSituation: [
                "Vous ne connaissez pas les stratégies mots-clés",
                "Vous réfléchissez à mettre en place une stratégie de mots-clés",
                "Vous avez commencé à mettre en place une stratégie de mots-clés",
                "Vous avez mis en place une stratégie de mots-clés et ça marche",
            ],
        },
    ];

    useEffect(() => {
        ["audit_1", "audit_2", "audit_3", "audit_4", "audit_5"].forEach(
            (key) => {
                const value = localStorage.getItem(key);
                if (value) {
                    const { index, score } = JSON.parse(value);
                    setAnswers((prev) => [...prev, { index, score }]);
                }
            },
        );
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-12">
            {/* Left side */}
            <div className="hidden lg:block col col-span-4 border-r-2 border-b-2 rounded-b-lg border-[#1f2937] p-5 bg-talk-500 h-screen">
                <div
                    className={`flex mx-auto items-center bg-white w-[140px] rounded-lg border-2 border-[#1f2937]`}
                >
                    <img
                        className={`p-2 w-[140px]`}
                        src={TalkImg}
                        alt="Fidiz logo"
                    />
                </div>
                <p className="mt-3 text-lg text-center">
                    Faites revenir vos clients en boutique avec Fidiz
                </p>
                <div className="mt-[100px] px-10">
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            1
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Capter les infos de vos clients.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            2
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Lancer des campagnes marketing.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            3
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Mesurez l'impact de vos actions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="col col-span-8 p-10 px-[20px] lg:px-[80px]">
                <h1 className="font-han text-center text-xl font-bold">
                    Compte rendu de l'audit
                </h1>
                <ScoreChart
                    percentage={answers?.reduce(
                        (acc, curr) => acc + curr.score,
                        0,
                    )}
                />
                <div className="mt-5 relative border shadow-lg rounded-xl p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]">
                    <div>
                        <div className="flex justify-center gap-4">
                            <MdChevronLeft
                                className="flex my-auto text-4xl"
                                onClick={() => {
                                    if (page > 1) setPage(page - 1);
                                }}
                            />
                            <h1 className="text-xl text-center lg:text-xl font-bold">
                                {results[page - 1].title}
                            </h1>
                            <MdChevronRight
                                className="flex my-auto text-4xl"
                                onClick={() => {
                                    if (page < 5) setPage(page + 1);
                                }}
                            />
                        </div>
                        <div className="mt-1 flex justify-center gap-2">
                            {page === 1 ? (
                                <div className="h-2 w-2 bg-gray-800 rounded-full"></div>
                            ) : (
                                <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                            )}
                            {page === 2 ? (
                                <div className="h-2 w-2 bg-gray-800 rounded-full"></div>
                            ) : (
                                <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                            )}
                            {page === 3 ? (
                                <div className="h-2 w-2 bg-gray-800 rounded-full"></div>
                            ) : (
                                <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                            )}
                            {page === 4 ? (
                                <div className="h-2 w-2 bg-gray-800 rounded-full"></div>
                            ) : (
                                <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                            )}
                            {page === 5 ? (
                                <div className="h-2 w-2 bg-gray-800 rounded-full"></div>
                            ) : (
                                <div className="h-2 w-2 bg-gray-500 rounded-full"></div>
                            )}
                        </div>
                        <div className="flex mx-auto mt-3 w-[320px] h-[1px] bg-[#777777] rounded-full"></div>
                        {answers[page - 1]?.score >= 15 ? (
                            <>
                                <h1 className="mt-3 text-xl text-center lg:text-lg font-bold text-[#1f2937]">
                                    🎉 Vous êtes sur la bonne voie !
                                </h1>
                                <p className="mt-3 flex w-[80px] mx-auto justify-center text-xl text-center bg-[#44a832] text-white px-2 py-2 rounded-full">
                                    {answers[page - 1]?.score}/20
                                </p>
                            </>
                        ) : (
                            <>
                                <h1 className="mt-3 text-xl text-center lg:text-lg font-bold text-[#a83c32]">
                                    🚨 Attention, il y a des points à améliorer
                                    !
                                </h1>
                                <p className="mt-3 flex w-[80px] mx-auto justify-center text-xl text-center bg-[#a83c32] text-white px-2 py-2 rounded-full">
                                    {answers[page - 1]?.score}/20
                                </p>
                            </>
                        )}
                        <h1 className="mt-3 text-xl text-center lg:text-lg font-bold">
                            🔎 Votre situation
                        </h1>
                        <p className="flex justify-center mx-auto mt-2 text-xs text-center lg:text-lg px-3 lg:max-w-[600px]">
                            {
                                results[page - 1].yourSituation[
                                    answers[page - 1]?.index
                                ]
                            }
                        </p>
                        <h1 className="mt-6 text-xl text-center lg:text-lg font-bold">
                            💡 Nos conseils
                        </h1>
                        <p className="flex mx-auto mt-2 text-xs text-center lg:text-sm px-3 lg:max-w-[600px]">
                            {results[page - 1].advice}
                        </p>
                        <h1 className="mt-6 text-xl text-center lg:text-lg font-bold">
                            🚀 Pour aller plus loin
                        </h1>
                        <p className="flex mx-auto mt-2 text-xs text-center lg:text-sm px-3 lg:max-w-[600px]">
                            {results[page - 1].goFurther}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AuditResult;
