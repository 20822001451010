import GoBack from "components/actions/GoBack";
import { createRanking, deleteRanking, getRankings } from "interfaces/ranking";
import { useState, useEffect } from "react";
import { IoMdTrash } from "react-icons/io";
import { MdAnalytics } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Ranking() {
    const nav = useNavigate();
    const [rankings, setRankings] = useState([]);
    const [newKeyword, setNewKeyword] = useState("");

    const handleAddKeywords = async () => {
        const res = await createRanking({ keywords: newKeyword });
        if (res) {
            initRanking();
            setNewKeyword("");
        } else {
            toast.error("Vous avez atteint le nombre maximum de mots-clés (6)");
        }
    };

    const handleDeteteKeyword = async (id: string) => {
        const res = await deleteRanking(id);
        if (res) {
            initRanking();
        }
    };

    const initRanking = async () => {
        const res = await getRankings();
        if (res) {
            setRankings(res.data);
        }
    };

    useEffect(() => {
        initRanking();
    }, []);

    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between items-center">
                <GoBack />
                <div className="flex gap-1">
                    <div className="flex">
                        <input
                            id="name"
                            key={"name"}
                            type="text"
                            placeholder="Restaurant Paris"
                            value={newKeyword}
                            className="p-2 w-[200px] h-[30px] rounded-xl border-xl border border-gray-300"
                            onChange={(e) => setNewKeyword(e.target.value)}
                        />
                    </div>
                    <button
                        className={`flex items-center px-2 linear h-[30px] rounded-xl bg-primary text-white font-bold text-xs transition duration-200 hover:opacity-70`}
                        onClick={handleAddKeywords}
                    >
                        Ajouter les mot-clés
                    </button>
                </div>
            </div>

            {rankings && rankings.length > 0 && (
                <>
                    {/* Table Header */}
                    <div className="mt-2 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium text-gray-600">
                        <p className="col-span-1">Position</p>
                        <p className="col-span-2">Mots-clés</p>
                        <p className="col-span-2 text-center">Competition</p>
                        <p className="col-span-4">Recommandations</p>
                        <p className="col-span-3">Actions</p>
                    </div>

                    {/* Table Content */}
                    <div className="mt-1 grid grid-cols-1 gap-3">
                        {rankings
                            .sort((a, b) => {
                                if (a.rank === -1) return 1;
                                if (b.rank === -1) return -1;
                                return a.rank - b.rank;
                            })
                            .map((data, index) => (
                                <div
                                    key={index}
                                    className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4 bg-white shadow-sm"
                                >
                                    <div className="col-span-1 font-bold">
                                        {data.rank <= 3 && data.rank > 0 && (
                                            <span className="text-xs p-2 bg-green-200 rounded-xl">
                                                #{data.rank}
                                            </span>
                                        )}
                                        {data.rank > 3 && (
                                            <span className="text-xs p-2 bg-orange-200 rounded-xl">
                                                #{data.rank}
                                            </span>
                                        )}
                                        {data.rank === -1 && (
                                            <span className="text-xs p-2 bg-gray-200 rounded-xl">
                                                N/A
                                            </span>
                                        )}
                                    </div>
                                    {/* Keyword Set */}
                                    <div className="col-span-2">
                                        <p>"{data.keywords}"</p>
                                    </div>

                                    {/* Competition */}
                                    <div className="col-span-2 text-center">
                                        {data.competition === "low" ? (
                                            <span className="text-xs p-2 bg-yellow-200 rounded-xl">
                                                Modérée
                                            </span>
                                        ) : (
                                            <span className="text-xs p-2 bg-red-200 rounded-xl">
                                                Importante
                                            </span>
                                        )}
                                    </div>

                                    <p className="flex gap-1 col-span-4 text-sm">
                                        💡{data.recommendation}
                                    </p>

                                    {/* Actions */}
                                    <div className="col-span-3 flex gap-4">
                                        <button
                                            className="flex my-auto max-h-9 items-center gap-2 text-xs p-2 border rounded-xl hover:bg-gray-50"
                                            onClick={() =>
                                                nav(`/ranking/${data._id}`)
                                            }
                                        >
                                            <MdAnalytics />
                                            Classement
                                        </button>
                                        {/* <button className="flex items-center gap-2 text-xs p-2 border rounded-xl">
                                        <MdEdit />
                                        Modifier
                                    </button> */}
                                        {/* Delete */}
                                        <button
                                            className="flex my-auto max-h-9 items-center gap-2 text-xs p-2 border rounded-xl hover:bg-gray-50"
                                            onClick={() =>
                                                handleDeteteKeyword(data._id)
                                            }
                                        >
                                            <IoMdTrash />
                                            Supprimer
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </>
            )}
            {/* No ranking available */}
            {rankings && rankings.length === 0 && (
                <>
                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-bold text-center">
                            Aucun mot-clé n'a été ajouté
                        </p>
                        <p className="flex mx-auto mt-5 text-center max-w-[550px]">
                            Ajoutez des mots-clés pour suivre le positionnement
                            de votre établissemnt sur Google et recevoir des
                            recommandations pour améliorer votre visibilité et
                            grimper dans les résultats de recherche.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}

export default Ranking;
