import { useEffect } from "react";

function InConnect() {

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const fullName = urlParams.get('name');
        localStorage.setItem('token', token);
        localStorage.setItem('name', fullName);
        window.location.href = "/dashboard";
    }, []);
    return (
        <p>Loading...</p>
    );
}

export default InConnect;
