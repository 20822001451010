import { useEffect } from "react";

const Whatsapp = () => {
    useEffect(() => {
        window.location.href =
            "https://wa.me/759564415?text=Bonjour%20Fidiz%2C%0A%0AJe%20suis%20int%C3%A9ress%C3%A9%20par%20vos%20services%2C%20pouvez-vous%20me%20rappeler%20%3F%0A%0AMerci%20%21";
    }, []);

    return <p>Loading</p>;
};
export default Whatsapp;
