// components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomerCard from "components/card/CustomerCard";
import customerImg from "assets/img/customer.png";
import { getMyOnlyRestaurantCustomers } from "interfaces/customer";
import LoadingCard from "./components/loadingCard";
import Nft4 from "assets/img/nfts/Nft4.png";
import SearchIcon from "components/icons/SearchIcon";
import Card from "components/card";
import Pagination from "components/pagination/Pagination";
import OnBoardingCard from "views/admin/main/account/coursePage/components/OnBoardingCard";
import { useShepherdTour } from "react-shepherd";
import LiveChatFooter from "components/helper/LiveChatFooter";

const steps: any = [
    {
        title: "4/5 Personnaliser les prix",
        text: "Cliquer sur un client, puis sur 'gérer les prix'",
        attachTo: {
            element: ".step-customer-0",
            on: "top",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "J'ai compris !",
                type: "next",
            },
        ],
        id: "step-customer-0",
    },
];

const secondSteps: any = [
    {
        title: "5/5 Envoyer la page de commande",
        text: "Cliquer sur un client, puis sur 'envoyer la page de commande'. Vous pouvez maintenant découvrir la page de commande puis l'envoyer à vos clients.",
        attachTo: {
            element: ".step-customer-0",
            on: "top",
        },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "J'ai compris !",
                type: "next",
            },
        ],
        id: "step-customer-0",
    },
];

const CRM = () => {
    const nav = useNavigate();
    const [customers, setCustomers] = useState(null);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(8);
    const [onboardingStep, setOnboardingStep] = useState(null);
    const isFullyLoaded = customers;
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const tour = useShepherdTour({ tourOptions, steps });
    const secondtour = useShepherdTour({ tourOptions, steps: secondSteps });

    const fetchCustomers = async (page: number, limit: number) => {
        const res = await getMyOnlyRestaurantCustomers(page, limit);
        if (res) {
            setCustomers(res.data);
            setTotal(res.total);
            setPage(page);
            setLimitPerPage(limit);
        }
    };

    const OnboardingOptions = () => {
        return (
            <>
                {onboardingStep === "3" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Importer vos clients"
                            actualStep={3}
                            totalStep={5}
                            description="Vous pouvez import vos clients depuis un fichier CSV ou les ajouter manuellement."
                            onClick={() => {
                                setOnboardingStep("4");
                                localStorage.setItem("onboarding-step", "4");
                                tour.start();
                            }}
                        />
                    </div>
                )}
                {onboardingStep === "4" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Personnaliser les prix"
                            actualStep={4}
                            totalStep={5}
                            description="Si nécessaire, vous pouvez maintenant personnaliser les prix de vos produits par client."
                            onClick={() => {
                                setOnboardingStep("5");
                                localStorage.setItem("onboarding-step", "5");
                                secondtour.start();
                            }}
                        />
                    </div>
                )}
                {onboardingStep === "5" && (
                    <div className="mt-5 mb-4">
                        <OnBoardingCard
                            title="Envoyer la page de commande"
                            actualStep={5}
                            totalStep={5}
                            description="Vous pouvez maintenant découvrir la page de commande puis l'envoyer à vos clients."
                            nextText="Terminer !"
                            onClick={() => {
                                setOnboardingStep(null);
                                localStorage.setItem("onboarding-step", "1000");
                            }}
                        />
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            nav("/login");
        }
        fetchCustomers(page, limitPerPage);
        setOnboardingStep(localStorage.getItem("onboarding-step"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Delay the last search change
    useEffect(() => {
        setCustomers(null);
        const delayDebounceFn = setTimeout(() => {
            fetchCustomers(1, limitPerPage);
        }, 2000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <>
            <OnboardingOptions />
            <Card extra="mt-5 pt-[10px] pl-[20px] pr-[20px] pb-[20px] border">
                <div className="mt-4 grid h-full w-full grid-cols-1 gap-5 xl:mt-3">
                    <div className="h-full w-full rounded-[20px]">
                        <h4 className="ml-2 mt-2 my-auto text-2xl font-medium text-navy-700 dark:text-white">
                            CRM
                        </h4>
                        <div className="mt-4 flex justify-between items-center flex-col sm:flex-row gap-[10px]">
                            <div className="flex h-[38px] items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white lg:col-span-4 w-[80%] sm:w-[40%]">
                                <SearchIcon />
                                <input
                                    type="text"
                                    placeholder="Entreprise, mail, téléphone, contact, ...."
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
                                />
                            </div>
                            <div className="absolute top-0 right-5">
                                <button
                                    className={`p-5 linear mt-4 w-full rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                    onClick={() => nav("/create-customer")}
                                >
                                    Ajouter un client
                                </button>
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-4">
                            {isFullyLoaded && customers.length === 0 && (
                                <div className="col-span-12 mt-5">
                                    <img
                                        className="mx-auto flex h-[300px] w-[300px] items-center justify-center"
                                        src={customerImg}
                                        alt=""
                                    />
                                    <p className="col-span-12 my-auto mt-3 text-center text-base text-navy-700 dark:text-white">
                                        Gérer facilement vos prospects, clients
                                        et automatiser la prise de commandes.
                                    </p>
                                    <div className="font-large mt-5 flex items-center justify-center gap-5 rounded-xl">
                                        <button
                                            className={`p-5 linear w-[300px] rounded-xl bg-primary text-white font-bold py-3 text-xs lg:text-base transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                                            onClick={() =>
                                                nav("/create-customer")
                                            }
                                        >
                                            Ajouter votre premier client
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isFullyLoaded
                                ? customers.map(
                                      (customer: any, index: number) => {
                                          return (
                                              <div
                                                  className={`step-customer-${index}`}
                                              >
                                                  <CustomerCard
                                                      id={customer._id}
                                                      company={`${customer.rewards.points} points de fidélité`}
                                                      displayName={
                                                          customer.displayName
                                                      }
                                                      email={
                                                          customer.emailInfo ||
                                                          customer.email
                                                      }
                                                      phone={
                                                          customer.phoneInfo ||
                                                          customer.phone
                                                      }
                                                      commands="0"
                                                      image={Nft4}
                                                      customerId={
                                                          customer.customerId
                                                      }
                                                  />
                                              </div>
                                          );
                                      },
                                  )
                                : Array(10)
                                      .fill(<LoadingCard />)
                                      .map((order: any) => {
                                          return order;
                                      })}
                        </div>
                    </div>
                </div>
                {/* pagination */}
                <Pagination
                    extra="mt-3"
                    text={false}
                    maxItems={total}
                    itemsPerPage={[8]}
                    page={page}
                    onChange={(page: number, limit: number) =>
                        fetchCustomers(page, limit)
                    }
                />
            </Card>
            <LiveChatFooter />
        </>
    );
};

export default CRM;
