import { ImporterLocale } from "react-csv-importer";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- all exports are ImporterLocale which is already fully typed */
export const frFR: ImporterLocale = {
    general: {
        goToPreviousStepTooltip: "Go to previous step",
    },

    fileStep: {
        initialDragDropPrompt:
            "Glissez ou cliquez pour importer votre fichier CSV...",
        activeDragDropPrompt: "Drop CSV file here...",

        getImportError: (message) => `Erreur: ${message}`,
        getDataFormatError: (message) =>
            `Please check data formatting: ${message}`,
        goBackButton: "Go Back",
        nextButton: "Préparer l'import",

        rawFileContentsHeading: "Contenu du fichier",
        previewImportHeading: "Prévisualisation",
        dataHasHeadersCheckbox: "Contient des en-têtes",
        previewLoadingStatus: "Chargement...",
    },

    fieldsStep: {
        stepSubtitle: "Assigner les colonnes",
        requiredFieldsError: "Veuillez assigner toutes les colonnes requises",
        nextButton: "Import",

        dragSourceAreaCaption: "Colonnes disponibles",
        getDragSourcePageIndicator: (currentPage: number, pageCount: number) =>
            `Page ${currentPage} of ${pageCount}`,
        getDragSourceActiveStatus: (columnCode: string) =>
            `Assigning column ${columnCode}`,
        nextColumnsTooltip: "Show next columns",
        previousColumnsTooltip: "Show previous columns",
        clearAssignmentTooltip: "Clear column assignment",
        selectColumnTooltip: "Select column for assignment",
        unselectColumnTooltip: "Unselect column",

        dragTargetAreaCaption: "Target fields",
        getDragTargetOptionalCaption: (field) => `${field} (optional)`,
        getDragTargetRequiredCaption: (field) => `${field} (required)`,
        dragTargetPlaceholder: "Glissez la colonne ici",
        getDragTargetAssignTooltip: (columnCode: string) =>
            `Assign column ${columnCode}`,
        dragTargetClearTooltip: "Clear column assignment",

        columnCardDummyHeader: "Unassigned field",
        getColumnCardHeader: (code) => `Column ${code}`,
    },

    progressStep: {
        stepSubtitle: "Import",
        uploadMoreButton: "Upload More",
        finishButton: "Finish",
        statusError: "Could not import",
        statusComplete: "Import en cours ...",
        statusPending: "Importing...",
        processedRowsLabel: "Processed rows:",
    },
};
