import { verifyTabletCode } from "interfaces/tablet";
import { useState } from "react";

const Numpad = (props: { onSubmit: () => void }) => {
    const { onSubmit } = props;
    const [code, setCode] = useState("");
    const [error, setError] = useState(null);
    const [focusedButton, setFocusedButton] = useState(null);

    const handleButtonClick = (value: any) => {
        setFocusedButton(value);
        setTimeout(() => {
            setFocusedButton(null);
        }, 1000);

        if (error) {
            setError(false);
        }

        if (value === "C") {
            setCode((prev) => prev.slice(0, -1));
        } else if (value === "✓") {
            alert(`You entered: ${code}`);
            setCode("");
        } else {
            if (code.length >= 4) return;
            setCode(code + value);
        }
    };

    const handleEnter = async () => {
        if (code.length !== 4) {
            setError("Code invalide");
            return;
        }
        const result = await verifyTabletCode(code);
        if (result?.isCodeValid) {
            onSubmit();
        } else {
            setError("Code invalide");
        }
    };

    return (
        <div className="w-full flex flex-col items-center justify-center min-h-screen p-4">
            <div className="bg-white rounded-lg p-4 w-full max-w-sm">
                <div className="mb-4">
                    <p className="font-bold text-center">Code restaurant</p>
                    {/* add space every 2 charactire */}
                    <p className="w-full p-4 text-3xl text-center font-bold">
                        {code}
                    </p>
                    <p className="text-red-500 font-bold text-sm text-center">
                        {error}
                    </p>
                </div>
                <div className="mt-10 grid grid-cols-3 gap-4">
                    {["1", "2", "3", "4", "5", "6", "7", "8", "9"].map(
                        (item) => (
                            <button
                                key={item}
                                onClick={() => handleButtonClick(item)}
                                className={`flex items-center justify-center h-16 bg-white text-gray-800 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === item
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                            >
                                {item}
                            </button>
                        ),
                    )}
                </div>
                {/* 0 centered */}
                <div className="mt-4 grid grid-cols-3 gap-4">
                    <button
                        onClick={() => handleButtonClick("C")}
                        className={`flex items-center justify-center h-16 bg-white text-red-500 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "<"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        {"<"}
                    </button>
                    <button
                        onClick={() => handleButtonClick("0")}
                        className={`flex items-center justify-center h-16 bg-white text-gray-800 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "0"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        0
                    </button>
                    <button
                        onClick={() => handleEnter()}
                        className={`flex items-center justify-center h-16 bg-white text-green-500 text-2xl rounded-full font-bold transition
                        ${
                            focusedButton === "✓"
                                ? "bg-gray-400"
                                : "hover:bg-gray-200"
                        }`}
                    >
                        ✓
                    </button>
                </div>
            </div>
        </div>
    );
};

export default function RestaurantLogin(props: { onFullScreen: any }) {
    const { onFullScreen } = props;

    const handleFullScreen = () => {
        const elem = document.documentElement;
        if (elem.requestFullscreen) elem.requestFullscreen();
        onFullScreen();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
            <Numpad onSubmit={handleFullScreen} />
        </div>
    );
}
