import { useEffect, useState } from "react";
import AskNumber from "./components/AskNumber";
import AskEmail from "./components/AskEmail";
import RestaurantLogin from "./components/FullScreenButton";
import { registerTabletAccount } from "interfaces/tablet";
import { useParams } from "react-router-dom";
import UserInterface from "./components/UserInterface";
import { ToastContainer } from "react-toastify";

function LoyaltyPage() {
    const params = useParams();
    const restaurantId = params.id;
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handlePhoneSubmit = async (
        rphone: string,
        remail: string,
        rtoken: string,
    ) => {
        setPhone(rphone);
        if (remail) setEmail(remail);
        if (rtoken) localStorage.setItem("user-token", rtoken);
    };

    const handleCreateAccount = async (email: string) => {
        const res = await registerTabletAccount(email, phone, restaurantId);
        if (res?.token) {
            localStorage.setItem("user-token", res.token);
            setEmail(email);
        }
    };

    const disconnect = () => {
        setPhone(null);
        setEmail(null);
        localStorage.removeItem("user-token");
    };

    useEffect(() => {
        localStorage.removeItem("user-token");
    }, []);

    return (
        <div className="">
            <ToastContainer />
            {isFullScreen ? (
                <>
                    {!phone && (
                        <AskNumber
                            onSubmit={handlePhoneSubmit}
                            reloadNextReward={email}
                        />
                    )}
                    {phone && !email && (
                        <AskEmail
                            onSubmit={handleCreateAccount}
                            goBack={() => setPhone(null)}
                        />
                    )}
                    {phone && email && (
                        <UserInterface disconnect={disconnect} />
                    )}
                </>
            ) : (
                <RestaurantLogin onFullScreen={() => setIsFullScreen(true)} />
            )}
        </div>
    );
}

export default LoyaltyPage;
