import TalkImg from "assets/img/talk-logo-2.svg";
import ToolsImg from "assets/img/tools-list.png";
import { useNavigate } from "react-router-dom";

function AuditLanding() {
    const navigate = useNavigate();

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
            <div className="hidden lg:block bg-[#FEDE65] rounded-sm h-full">
                <img
                    className={`mt-7 ml-5 md:ml-[50px] w-[180px] rounded-xl`}
                    src={TalkImg}
                    alt="Toola logo"
                />
                <p className="pt-5 md:pt-8 md:ml-5 text-2xl md:text-[45px] font-han px-5 md:px-[30px]">
                    Profitez de votre audit marketing gratuit
                </p>
                <p className="mt-6 md:ml-5 text-lg px-5 md:px-10">
                    Fidiz est la solution tout-en-un qui mélange technologie,
                    marketing et réseaux sociaux pour faire croître votre
                    chiffre d'affaires 🚀 Nos audits sont gratuit.
                </p>
                <p className="mt-4 md:mt-6 md:ml-5 text-lg  px-5 md:px-10 font-bold">
                    Fidiz va vous permettre de :
                </p>
                <ul className="mb-10 md:ml-5 mt-3 text-base md:text-lg  px-5 md:px-10">
                    <li className="mt-5">
                        🗃️ Capter les données de vos clients grâce à divers
                        outils comme notre roue cadeaux, la carte de fidélité
                        digitale et le parrainage clients.
                    </li>
                    <li className="mt-10">
                        🎯 Envoyer des emails et SMS à vos clients avec un
                        ciblage précis pour atteindre les bonnes personnes au
                        bon moment.
                    </li>
                    <li className="mt-10">
                        👁️ Garder un œil sur vos concurrents en visualisant
                        leurs publicités et contenus. Inspirez-vous pour
                        améliorer votre stratégie marketing.
                    </li>
                    <li className="mt-10">
                        📷 Déléguer à nos experts la gestion de vos réseaux
                        sociaux, shooting photos et de votre marketing digital.
                    </li>
                </ul>
            </div>
            <div className="pt-5 bg-[#FEDE65]/80 pb-10 flex px-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 px-4">
                <div className="mx-auto xl:max-w-[420px]">
                    <h3 className="mt-10 lg-mt-0 text-3xl  text-center font-han dark:text-white">
                        On commence l'audit ?
                    </h3>
                    <p className="ml-1 mt-2 text-base text-center">
                        Découvrez les forces et faiblesses de votre boutique et
                        les stratégie conseillées pour améliorer votre chiffre
                        d'affaire.
                    </p>
                    <svg
                        width="6"
                        height="479"
                        viewBox="0 0 446 479"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="rotate-90 mx-auto mt-4 h-20 w-20"
                    >
                        <path
                            d="M379.831 88.044a5.002 5.002 0 0 1-4.49-7.208 5.003 5.003 0 0 1 2.37-2.322c10.5-4.89 18.17-8.37 27.06-12.41 7.45-3.37 15.74-7.13 27.3-12.45-7.28-7.77-13.32-14.31-19.2-20.68-7.4-8-14.39-15.59-22.87-24.52a5.017 5.017 0 0 1-1.045-1.65 5.016 5.016 0 0 1 .095-3.828 5.015 5.015 0 0 1 2.775-2.641 5.004 5.004 0 0 1 5.425 1.22c8.53 9 15.54 16.58 23 24.63 7.1 7.68 14.43 15.63 23.89 25.66a5.007 5.007 0 0 1 1.292 4.396 5.001 5.001 0 0 1-2.832 3.603c-15 6.93-24.92 11.42-33.67 15.39-8.75 3.97-16.52 7.49-27 12.36-.659.3-1.376.454-2.1.45z"
                            fill="#000"
                        ></path>
                        <path
                            d="M79.78 478.756c-1.35 0-2.7 0-4.08-.12-25.63-1.56-46.32-12.36-59.82-31.24-16.57-23.16-18.59-53.11-13.25-72.53a5.007 5.007 0 0 1 6.156-3.495 5.002 5.002 0 0 1 3.495 6.155c-4.72 17.12-2.92 43.57 11.73 64.06 11.69 16.34 29.77 25.7 52.3 27.07 29.19 1.76 52.9-19.62 72.49-65.39 17.76-41.47 29.79-97.61 40.41-147.13 8.57-40 16.67-77.76 26.78-104.4 7.47-19.69 25.84-56.58 65-82.49 42.77-28.32 96.84-34.65 160.72-18.81a4.995 4.995 0 0 1 3.192 2.241 5.014 5.014 0 0 1 .61 3.853 4.998 4.998 0 0 1-6.212 3.606c-150.85-37.39-201.91 63.42-213.96 95.14-9.84 25.94-17.86 63.35-26.35 103-10.71 50-22.86 106.6-41 149-20.59 48.02-46.21 71.48-78.21 71.48z"
                            fill="#000"
                        ></path>
                    </svg>
                    <button
                        className="linear mt-3 w-full rounded-xl bg-gradient-to-r from-[#d4adfe] to-[#d4adfe9b] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:bg-[#fedd65a4] active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={() => navigate("/audit/1")}
                    >
                        Audit en ligne en 5 minutes 🤙
                    </button>
                    <p className="flex justify-center my-3 font-bold text-2xl">
                        OU
                    </p>
                    <button
                        className="linear w-full rounded-xl bg-gradient-to-r from-[#f1e9f8] to-[#f1e9f8b2] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:bg-[#fedd65a4] active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        onClick={() =>
                            (window.location.href =
                                "https://app.lemcal.com/@fidiz/demo")
                        }
                    >
                        Parler avec un expert 🔥
                    </button>
                </div>
                <p className="flex mt-5 mx-auto text-base font-bold">
                    Découvrez nos outils:
                </p>
                <img
                    className={`flex mx-auto mt-3 border-2 border-[#1f2937] w-[80%] rounded-xl`}
                    src={ToolsImg}
                    alt="Tools list"
                />
            </div>
        </div>
    );
}

export default AuditLanding;
