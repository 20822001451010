import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import axios from "axios";
import env from "variables/config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function LeadGen() {
    const navigate = useNavigate();

    const [sent, setSent] = useState(false);
    const [user, setUser] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        phone: "",
        companyName: "",
        brandName: "",
        utmSource: "unknown",
    });

    const handleChange = (e: any) => {
        setUser({ ...user, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (e: any) => {
        const { name, surname, email, password, companyName, brandName } = user;

        const res = await axios
            .post(`${env.apiUrl}/auth/new-lead`, {
                fullName: `${name} ${surname}`,
                email,
                password,
                company: companyName,
                brand: brandName,
                phone: user.phone,
                utmSource: user.utmSource,
            })
            .catch((err) => {
                console.log(err);
                toast.error("Un champ est manquant ou incorrect.");
            });
        if (res) {
            setSent(true);
        }
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmSource = urlParams.get("utm_source") || "unknown";
        setUser((prev) => ({ ...prev, utmSource }));
    }, []);

    return (
        <Default
            maincard={
                <div className="mb-16 flex px-4 h-full w-full items-center justify-center xl:px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
                    <ToastContainer />
                    {/* Sign up section */}
                    {!sent ? (
                        <div className="mt-[10vh] mx-auto w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
                            <h3 className="text-4xl font-han dark:text-white">
                                Demande de demo
                            </h3>
                            <p className="ml-1 mt-[10px] text-base">
                                Un expert va s'occuper de vous !
                            </p>
                            {/* Email */}
                            <div className="mt-5 flex mx-auto">
                                <input
                                    id="email"
                                    key={"email"}
                                    type="text"
                                    placeholder="Email*"
                                    className="mx-auto p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="hidden xl:flex mt-5 justify-between">
                                <div className="">
                                    <input
                                        id="brandName"
                                        key={"brandName"}
                                        type="text"
                                        placeholder="Ma marque*"
                                        className="p-2 rounded-xl h-[60px] border-xl border-2 border-[#1f2937]"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="">
                                    <input
                                        id="companyName"
                                        key={"companyName"}
                                        type="text"
                                        placeholder="Ma raison sociale*"
                                        className="p-2 rounded-xl h-[60px] border-xl border-2 border-[#1f2937]"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="xl:hidden">
                                <div className="mt-5 flex mx-auto">
                                    <input
                                        id="brandName"
                                        key={"brandName"}
                                        type="text"
                                        placeholder="Ma marque*"
                                        className="p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mt-5 flex mx-auto">
                                    <input
                                        id="companyName"
                                        key={"companyName"}
                                        type="text"
                                        placeholder="Ma raison sociale*"
                                        className="p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {/* Phone */}
                            <div className="mt-5 flex mx-auto">
                                <input
                                    id="phone"
                                    key={"phone"}
                                    type="text"
                                    placeholder="Numéro de téléphone*"
                                    className="mx-auto p-2 rounded-xl w-[600px] h-[60px] border-xl border-2 border-[#1f2937]"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* button */}

                            <button
                                className="linear mt-4 w-full rounded-xl bg-[#FEDE65] border-2 border-[#1f2937] font-han py-3 text-base transition duration-200 hover:bg-[#fedd65a4] active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                onClick={handleSubmit}
                            >
                                Me faire rappeler au plus vite 🤙
                            </button>

                            <div className="mt-3">
                                <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                                    Déjà inscrit ?
                                </span>
                                <a
                                    href="/auth/login"
                                    className="ml-1 text-xs font-han hover:text-gray-600 dark:text-white"
                                >
                                    se connecter
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="md:mt-[40%] w-full">
                            <p className="flex justify-center text-center text-2xl font-bold ">
                                Votre demande a bien été envoyée 🔥
                            </p>
                            <p className="mt-5 md:mt-0 flex justify-center text-center ">
                                Nos équipes reviennent vers vous très vite
                            </p>
                        </div>
                    )}
                </div>
            }
        />
    );
}

export default LeadGen;
