import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getMyLead = async () => {
    const res = await axios.get(`/leads`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const acceptLead = async (lead: any) => {
	const id = lead._id;
    const res: any = await axios.put(`/leads/${id}`, {status: "pending"}, config()).catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const rateLead = async (lead: any, comment: string) => {
    const id = lead._id;
    const res: any = await axios.put(`/leads/${id}/rate`, {comment}, config()).catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
}

export const createLead = async (lead: any) => {
    const res = await axios.post("/leads", lead, config()).catch(errorHandler);
    return res.data;
};
