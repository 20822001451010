import TalkImg from "assets/img/talk-logo-2.svg";
import { useNavigate } from "react-router-dom";

const AuditStepFour = () => {
    const nav = useNavigate();

    const storeResult = (key: string, index: number, score: number) => {
        localStorage.setItem(key, JSON.stringify({ index, score }));
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-12">
            {/* Left side */}
            <div className="hidden lg:block col col-span-4 border-r-2 border-b-2 rounded-b-lg border-[#1f2937] p-5 bg-talk-500 h-screen">
                <div
                    className={`flex mx-auto items-center bg-white w-[140px] rounded-lg border-2 border-[#1f2937]`}
                >
                    <img
                        className={`p-2 w-[140px]`}
                        src={TalkImg}
                        alt="Fidiz logo"
                    />
                </div>
                <p className="mt-3 text-lg text-center">
                    Faites revenir vos clients en boutique avec Fidiz
                </p>
                <div className="mt-[100px] px-10">
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            1
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Capter les infos de vos clients.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            2
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Lancer des campagnes marketing.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            3
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Mesurez l'impact de vos actions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="col col-span-8 p-10 px-[20px] lg:px-[80px]">
                <h1 className="font-han text-xl font-bold">
                    4/5 - Traitez-vous les avis Google ?
                </h1>
                <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div
                        className="border shadow-lg rounded-xl p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_4", 0, 0);
                            nav("/audit/5");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                🤭 Non, pas encore !
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Aucune relance, aucun système de collecte n'est
                                mis en place pour le moment. Je laisse les avis
                                se faire naturellement.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl  p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_4", 1, 5);
                            nav("/audit/5");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                😄 Parfois aux avis négatifs
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Je n'ai pas encore mis en place de relance, mais
                                je sais que c'est important pour mon activité.
                                Je pense le faire prochainement.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_4", 2, 11);
                            nav("/audit/5");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                😎 Oui, toujours aux avis négatifs
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Vous avez commencé à capter des coordonnées
                                clients et à mettre en place de campagnes
                            </p>
                        </div>
                    </div>
                    <div
                        className="border shadow-lg rounded-xl p-4 lg:p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            storeResult("audit_4", 3, 20);
                            nav("/audit/5");
                        }}
                    >
                        <div>
                            <h1 className="text-xl lg:text-xl font-bold">
                                🚀 Oui, aux avis possitifs et négatifs
                            </h1>
                            <p className="mt-2 text-xs lg:text-sm">
                                Coordonées clients captées, campagnes en place
                                et régulières, vous avez une machine bien huilée
                                !
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AuditStepFour;
