import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { SiInstagram, SiUbereats } from "react-icons/si";
import { TbMapSearch } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import { getPageBySlug } from "interfaces/page";

const Page = () => {
    const params = useParams();
    const [page, setPage] = useState(null);

    const UserSpace = () => {
        return (
            <>
                <div className="pt-4">
                    <div className="rounded-2xl py-4 mx-5">
                        <p className="mt-10 text-center font-han text-[32px]">
                            {page?.title}
                        </p>
                    </div>
                    <p className="text-center text-xl px-7">
                        {page?.description}
                    </p>
                    {true && (
                        <div className="mt-[80px] pb-10">
                            {page?.urls?.menu && (
                                <div
                                    className={`relative mt-4 rounded-full bg-white mx-5 cursor-pointer xl:mx-[300px] border-solid shadow-xl border border-[#1f2937]`}
                                    onClick={() =>
                                        window.open(page.urls.menu, "_blank")
                                    }
                                >
                                    <div className="py-7">
                                        <MdOutlineRestaurantMenu
                                            size={40}
                                            className="absolute left-10 top-5"
                                        />
                                        <p className="flex justify-center mx-auto text-xl text-[#1e1e1e] font-bold hover:font-medium">
                                            Voir le menu
                                        </p>
                                    </div>
                                </div>
                            )}
                            {page?.urls?.ubereats && (
                                <div
                                    className={`relative mt-4 rounded-full bg-white mx-5 cursor-pointer xl:mx-[300px] border-solid shadow-xl border border-[#1f2937]`}
                                    onClick={() =>
                                        window.open(
                                            page.urls.ubereats,
                                            "_blank",
                                        )
                                    }
                                >
                                    <div className="py-7">
                                        <SiUbereats
                                            size={40}
                                            className="absolute left-10 top-5"
                                        />
                                        <p className="flex justify-center mx-auto text-xl text-[#1e1e1e] font-bold hover:font-medium">
                                            Commander
                                        </p>
                                    </div>
                                </div>
                            )}
                            {page?.urls?.address && (
                                <div
                                    className={`relative mt-4 rounded-full bg-white mx-5 cursor-pointer xl:mx-[300px] border-solid shadow-xl border border-[#1f2937]`}
                                    onClick={() =>
                                        window.open(
                                            `https://www.google.com/maps/search/${page.urls.address}`,
                                            "_blank",
                                        )
                                    }
                                >
                                    <div className="py-7">
                                        <TbMapSearch
                                            size={40}
                                            className="absolute left-10 top-5"
                                        />
                                        <p className="flex justify-center mx-auto text-xl text-[#1e1e1e] font-bold hover:font-medium">
                                            Voir l'itinéraire
                                        </p>
                                    </div>
                                </div>
                            )}
                            {page?.urls?.phone && (
                                <div
                                    className={`relative mt-4 rounded-full bg-white mx-5 cursor-pointer xl:mx-[300px] border-solid shadow-xl border border-[#1f2937]`}
                                    onClick={() =>
                                        window.open(`tel:${page.urls.phone}`)
                                    }
                                >
                                    <div className="py-7">
                                        <FiPhone
                                            size={40}
                                            className="absolute left-10 top-5"
                                        />
                                        <p className="flex justify-center mx-auto text-xl text-[#1e1e1e] font-bold hover:font-medium">
                                            Nous appeler
                                        </p>
                                    </div>
                                </div>
                            )}
                            {page?.urls?.instagram && (
                                <div
                                    className={`relative mt-4 rounded-full bg-white mx-5 cursor-pointer xl:mx-[300px] border-solid shadow-xl border border-[#1f2937]`}
                                    onClick={() =>
                                        window.open(
                                            `https://www.instagram.com/${page.urls.instagram}`,
                                            "_blank",
                                        )
                                    }
                                >
                                    <div className="py-7">
                                        <SiInstagram
                                            size={40}
                                            className="absolute left-10 top-5"
                                        />
                                        <p className="flex justify-center mx-auto text-xl text-[#1e1e1e] font-bold hover:font-medium">
                                            Instagram
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </>
        );
    };

    const initPage = async () => {
        const res = await getPageBySlug(params.slug);
        if (res) {
            setPage(res);
            //Set page title
            document.title = res.title;
        }
    };

    useEffect(() => {
        initPage();
    }, []);

    return (
        <div className="relative min-h-screen">
            <UserSpace />
            <p
                className="text-xs absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                onClick={() =>
                    window.open("https://fidiz.io?utm_source=footer", "_blank")
                }
            >
                Made by Fidiz.
            </p>
        </div>
    );
};

export default Page;
