import React from "react";

function InactiveCustomer(props: {
  date: string;
  sum: string;
  rank?: number;
  name: string;
  avatar?: string;
}) {
  const { date, sum, name } = props;

  return (
      <div className="mb-4 ml-1 flex items-center justify-between">
          {/* left side */}
          <div className="flex items-center gap-3">
              <div>
                  <h5 className="text-base font-bold text-navy-700 dark:text-white">
                      {name}
                  </h5>
                  <p className="text-xs font-medium text-gray-600"> {date} </p>
              </div>
          </div>
          {/* right side */}
          <div
              className={`flex items-center justify-center rounded-xl px-2 py-1 bg-gray-200`}
          >
              <p className="text-sm font-bold"> {sum} </p>
          </div>
      </div>
  );
}

export default InactiveCustomer;
