import tabletImage from "../../assets/img/tablet.png";
import otherImage from "../../assets/img/others.png";
import qrCodeImage from "../../assets/img/qr-code.png";
import flyersImage from "../../assets/img/flyers.png";
import TalkImg from "assets/img/talk-logo-2.svg";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { updateOnboardingSettings } from "interfaces/user";

const OnboardingStepThree = () => {
    const nav = useNavigate();

    const validateStep = (value: string) => {
        updateOnboardingSettings({ supportType: value });
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-12">
            {/* Left side */}
            <div className="hidden lg:block col col-span-4 border-r-2 border-b-2 rounded-b-lg border-[#1f2937] p-5 bg-talk-500 h-screen">
                <div
                    className={`flex mx-auto items-center bg-white w-[140px] rounded-lg border-2 border-[#1f2937]`}
                >
                    <img
                        className={`p-2 w-[140px]`}
                        src={TalkImg}
                        alt="Toola logo"
                    />
                </div>
                <p className="mt-3 text-lg text-center">
                    Faites revenir vos clients en boutique avec Fidiz
                </p>
                <div className="mt-[100px] px-10">
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            1
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Capter les infos de vos clients.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            2
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Lancer des campagnes marketing.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            3
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Mesurez l'impact de vos actions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="col col-span-8 p-10 lg:px-[80px]">
                {/* <h1 className="mt-5 font-han text-3xl md:mt-0 font-bold">
                    On commence ?
                </h1>
                <div className="mt-5 flex gap-3">
                    <p className="mt-2 text-xl font-bold">Notre mission:</p>
                    <p className="mt-2 text-lg">
                        Captez les informations de vos clients et les faire
                        revenir dans votre boutique !
                    </p>
                </div>
                <div className="mt-2 flex gap-3">
                    <p className="mt-2 text-xl font-bold">Comment ?</p>
                    <p className="mt-2 text-lg">
                        Nous proposons plusieurs outils, vous pouvez forcement
                        en trouver un qui vous convient.
                    </p>
                </div> */}
                <div className="flex gap-5">
                    <BiArrowBack
                        className="flex my-auto cursor-pointer h-[30px] w-[30px]"
                        onClick={() => nav("/2/onboarding")}
                    />
                    <h1 className="font-han text-2xl font-bold">
                        Quel support voulez-vous utiliser ?
                    </h1>
                </div>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div
                        className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            validateStep("tablet");
                            nav("/4/onboarding");
                        }}
                    >
                        <img
                            src={tabletImage}
                            alt="Tablet"
                            className="w-[60px]"
                        />
                        <div>
                            <h1 className="mt-4 text-lg font-bold">
                                Expérience tablette
                            </h1>
                            <p className="text-sm mt-1">
                                Vos client créez un compte directement sur la
                                tablette ou en scannant un QR-code et en
                                continuant sur leur téléphone.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            validateStep("phone");
                            nav("/4/onboarding");
                        }}
                    >
                        <img
                            src={qrCodeImage}
                            alt="Tablet"
                            className="w-[60px]"
                        />
                        <div>
                            <h1 className="mt-4 text-lg font-bold">
                                Expérience téléphone
                            </h1>
                            <p className="text-sm mt-1">
                                Vos clients scannent un QR-code sur votre
                                téléphone pour créer un compte. Vous pouvez
                                également les ajouter manuellement.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            validateStep("paper");
                            nav("/4/onboarding");
                        }}
                    >
                        <img
                            src={flyersImage}
                            alt="paper"
                            className="w-[60px]"
                        />
                        <div>
                            <h1 className="mt-4 text-lg font-bold">
                                Expérience papier
                            </h1>
                            <p className="text-sm mt-1">
                                Mettez à disposition des flyers et affiches avec
                                un QR-code pour que vos clients puissent se
                                connecter.
                            </p>
                        </div>
                    </div>
                    <div
                        className="border rounded-xl p-6 cursor-pointer hover:border-2 hover:bg-talk-500/80 hover:border-[#1f2937]"
                        onClick={() => {
                            validateStep("other");
                            nav("/4/onboarding");
                        }}
                    >
                        <img
                            src={otherImage}
                            alt="Customer"
                            className="w-[60px]"
                        />
                        <div>
                            <h1 className="mt-4 text-lg font-bold">Autres</h1>
                            <p className="text-sm mt-1">
                                Vous ne trouvez pas votre bonheur ? Pas de
                                soucis nous pouvons développer gratuitement une
                                solution sur mesure.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OnboardingStepThree;
