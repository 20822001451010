import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const markTaskAsDone = async (id: string) => {
    const res = await axios
        .put(`/tasks/${id}/done`, {}, config())
        .catch(errorHandler);
    return res.data;
};

export const getMyTasks = async () => {
    const res: any = await axios.get("/tasks", config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
}