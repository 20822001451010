const Footer = () => {
    return (
        <div className="flex w-full flex-col items-center justify-between px-1 pb-8 pt-3 lg:px-8 xl:flex-row">
            <p className="mb-4 text-center text-sm font-medium text-gray-600 sm:!mb-0 md:text-lg">
                <span className="mb-4 text-center text-sm text-gray-600 sm:!mb-0 md:text-base">
                    ©{new Date().getFullYear()} Fidiz. All Rights Reserved.
                </span>
            </p>
            <div>
                <ul className="flex flex-wrap items-center gap-3 sm:flex-nowrap md:gap-10">
                    <li>
                        <a
                            target="blank"
                            href="https://go.crisp.chat/chat/embed/?website_id=9adcc5aa-0ea5-46ef-b687-0d9e9b8e4aa7"
                            className="text-base font-medium text-gray-600 hover:text-gray-600"
                        >
                            Support
                        </a>
                    </li>
                    <li>
                        <a
                            target="blank"
                            href="https://status.toola.eu/"
                            className="text-base font-medium text-gray-600 hover:text-gray-600"
                        >
                            Status
                        </a>
                    </li>
                    <li>
                        <a
                            target="blank"
                            href="https://toola.eu/conditions-generales-dutilisations/"
                            className="text-base font-medium text-gray-600 hover:text-gray-600"
                        >
                            Terms of Use
                        </a>
                    </li>
                    <li>
                        <a
                            target="blank"
                            href="https://fidiz.io/blog/"
                            className="text-base font-medium text-gray-600 hover:text-gray-600"
                        >
                            Blog
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
