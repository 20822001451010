import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";

const SearchSelector = (props: {
    label?: string;
    hideLabel?: boolean;
    options: any;
    displayKey: string;
    onSelect: any;
    value?: string | null;
    canCreate?: boolean;
	createNew?: any;
    disabled?: boolean;
    inputSmall?: boolean;
}) => {
    const {
        label,
        options,
        displayKey,
        onSelect,
        hideLabel,
        value,
        canCreate,
		createNew,
        disabled,
        inputSmall = false,
    } = props;
    const [inputValue, setInputValue] = useState("");
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (value) {
            const defaultValue = options.find((o: any) => o._id === value);
			if (defaultValue !== undefined)
            	setSelected(defaultValue?.[displayKey]);
        }
    }, [value, options, displayKey]);

    return (
        <div className="h-10 w-full font-medium gap-2">
            <label
                htmlFor={hideLabel ? "" : label}
                className={`
                    ${inputSmall ? "text-xs font-medium" : "text-sm font-medium"}
                    text-navy-700 dark:text-white`}
            >
                {hideLabel ? "" : label}
            </label>
            <div
                onClick={() => {
                    if (disabled) return;
                    setOpen(!open);
                }}
                className={`${inputSmall && 'h-[35px]'} flex w-full items-center justify-between rounded-xl border ${
                    disabled ? "bg-gray-100" : "bg-white bg-white/0"
                } p-3 text-sm outline-none ${
                    !selected && "text-sm text-gray-300"
                }`}
            >
                {selected
                    ? selected?.length > 25
                        ? selected?.substring(0, 25) + "..."
                        : selected
                    : `Sélectionner`}
                <BiChevronDown
                    size={20}
                    className={`${open && "rotate-180"}`}
                />
            </div>
            <ul
                className={`mt-2 overflow-y-auto rounded-xl bg-white ${
                    open ? "max-h-60 border" : "max-h-0"
                } `}
            >
                <div className="sticky top-0 flex items-center bg-white px-2">
                    <AiOutlineSearch size={18} className="text-gray-700" />
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) =>
                            setInputValue(e.target.value.toLowerCase())
                        }
                        placeholder={`Recherche...`}
                        className="w-[80%] p-2 text-sm outline-none placeholder:text-gray-700"
                    />
                    {canCreate && (
                        <button
                            className="ml-10 hidden text-xs outline-none placeholder:text-gray-700 lg:block"
                            onClick={createNew}
                        >
                            Ajouter
                        </button>
                    )}
                </div>
                {options?.map((option: any, index: number) => (
                    <li
                        key={`${option?.[displayKey]}-${index}`}
                        className={`sticky p-2 text-sm hover:bg-gray-100
			  ${
                  option?.name?.toLowerCase() === selected?.toLowerCase()
                      ? "bg-gray-100"
                      : "bg-white"
              }
			  ${
                  option?.[displayKey]?.toLowerCase().includes(inputValue)
                      ? "block"
                      : "hidden"
              }`}
                        onClick={() => {
                            if (
                                option?.[displayKey]?.toLowerCase() !==
                                selected.toLowerCase()
                            ) {
                                setSelected(option?.[displayKey]);
                                onSelect(option);
                                setOpen(false);
                                setInputValue("");
                            }
                        }}
                    >
                        {option?.[displayKey]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchSelector;
