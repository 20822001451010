import { useEffect, useState } from "react";
import { BiChevronUp } from "react-icons/bi";
import { MdKeyboardReturn } from "react-icons/md";

// Custom components
function InputTagField(props: {
    id: string;
    label?: string;
    error?: string;
    isInvalid?: boolean;
    isLoaded?: any;
    required?: boolean;
    extra?: string;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    ref?: any;
    value?: string | number;
    min?: string;
    onFocus?: any;
    tags?: any;
    allTags?: any;
    onTagCreate?: any;
    onChange?: any;
}) {
    const {
        label,
        error,
        id,
        extra,
        required = false,
        isLoaded = true,
        type,
        placeholder,
        disabled,
        value,
        min,
        ref,
        isInvalid = false,
        tags,
        allTags,
        onTagCreate,
        onChange,
    } = props;

    const [tagsList, setTagsList] = useState(tags ? tags : []);
    const [inputValue, setInputValue] = useState("");
    const [onFocusInput, setOnFocusInput] = useState(false);

    useEffect(() => {
        setTagsList(tags ? tags : []);
    }, [tags]);

    useEffect(() => {
        const remoteTags = [...allTags.map((t: any) => t.name)];
        const localTags = [...tagsList];

        for (const localTag of localTags) {
            if (!remoteTags.includes(localTag)) {
                onTagCreate(localTag);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsList]);

    // Define if the error should be displayed
    let displayError = false;
    if (isLoaded) {
        if (error && isInvalid && value && value !== "" && value !== undefined)
            displayError = true;
        if (required && !value) displayError = true;
    }

    return (
        <div className={`h-full gap-2 ${extra}`}>
            {/* Label */}
            <div className="flex">
                {label && (
                    <label
                        htmlFor={id}
                        className={`mb-2 text-xs font-medium text-navy-700`}
                    >
                        {label}
                    </label>
                )}
            </div>
            <input
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                onKeyUp={(e) => {
                    if (e.key === "Enter") {
                        onChange([...tagsList, inputValue]);
                        setInputValue("");
                    }
                }}
                onFocus={() => {
                    setOnFocusInput(true);
                }}
                disabled={disabled}
                min={min}
                ref={ref}
                type={type}
                id={id}
                value={inputValue}
                placeholder={!displayError && placeholder}
                autoComplete="on"
                className={`flex sm:hidden flex-grow my-auto h-[15px] mb-2 rounded-xl
                    items-center ${!isLoaded && "animate-pulse"}
                    justify-center border bg-white/0 px-2.5 py-3 text-xs outline-none`}
            />
            {/* Tags + Input */}
            <div className="flex border rounded-xl">
                {tags && (
                    <div className="py-2 px-2 flex flex-wrap gap-2">
                        {tagsList.map((tag: any, index: number) => (
                            <div
                                key={index}
                                className="flex items-center gap-2 bg-blue-200 py-1 px-3 rounded-lg"
                            >
                                <p className="text-navy-700 text-[11px] font-medium">{tag}</p>
                                <button
                                    className="flex items-center justify-center rounded-full text-blue-400 text-xs"
                                    onClick={() => {
                                        onChange(
                                            tagsList.filter(
                                                (item: any) => item !== tag,
                                            ),
                                        );
                                    }}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                <input
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            onChange([...tagsList, inputValue]);
                            setInputValue("");
                        }
                    }}
                    onFocus={() => {
                        setOnFocusInput(true);
                    }}
                    disabled={disabled}
                    min={min}
                    ref={ref}
                    type={type}
                    id={id}
                    value={inputValue}
                    placeholder={!displayError && placeholder}
                    autoComplete="on"
                    className={`hidden sm:flex flex-grow my-auto h-[35px]
                    items-center ${!isLoaded && "animate-pulse"}
                    justify-center bg-white/0 px-2.5 py-3 text-xs outline-none`}
                />
                {onFocusInput && (
                    <BiChevronUp
                        className="my-auto text-sm text-gray-300 mr-1 hover:cursor-pointer"
                        size={20}
                        onClick={() => {
                            setOnFocusInput(false);
                        }}
                    />
                )}
            </div>
            {/* Options list */}
            <ul
                className={`z-50 mt-1 overflow-y-auto bg-white rounded-xl ${
                    onFocusInput ? "max-h-60 border" : "max-h-0"
                }`}
                onClick={() => {
                    setOnFocusInput(true);
                }}
            >
                <div className="z-50 sticky top-1">
                    <p className="mt-1 ml-2 text-xs font-medium">
                        Sélectionnez ou créez une option
                    </p>
                    <ul>
                        {allTags &&
                            allTags
                                .filter((t: any) => t.name.includes(inputValue))
                                .filter((t: any) => !tagsList.includes(t.name))
                                .slice(0, 5)
                                .map((option: any, index: number) => (
                                    <li
                                        key={`${option?.["name"]}-${index}`}
                                        className={`flex justify-between sticky p-1 text-xs hover:bg-gray-100 hover:cursor-pointer
                                        ${
                                            option?.["name"]
                                                ?.toLowerCase()
                                                .includes(inputValue)
                                                ? "block"
                                                : "hidden"
                                        }`}
                                    >
                                        <div
                                            className="w-full px-3"
                                            onClick={() => {
                                                onChange([
                                                    ...tagsList,
                                                    option?.["name"],
                                                ]);
                                                setInputValue("");
                                                setOnFocusInput(false);
                                            }}
                                        >
                                            {option?.["name"]}
                                        </div>
                                    </li>
                                ))}
                        {allTags &&
                            allTags.filter((t: any) =>
                                t.name.includes(inputValue),
                            ).length === 0 && (
                                <li
                                    className="flex my-auto justify-between sticky px-3 p-1 text-xs hover:bg-gray-100 hover:cursor-pointer"
                                    onClick={() => {
                                        setTagsList([...tagsList, inputValue]);
                                        onChange([...tagsList, inputValue]);
                                        setInputValue("");
                                        setOnFocusInput(false);
                                    }}
                                >
                                    {inputValue}
                                    <div className="flex gap-1">
                                        <MdKeyboardReturn className="my-auto" />
                                        <p className="text-[10px]">
                                            nouveau tag
                                        </p>
                                    </div>
                                </li>
                            )}
                    </ul>
                </div>
            </ul>
        </div>
    );
}

export default InputTagField;
