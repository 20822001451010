import axios from "axios";

import env from "variables/config";
import { errorHandler } from "./utils";

axios.defaults.baseURL = env.apiUrl;

const config = () => {
    return {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};

export const getEmailModels = async () => {
    const res = await axios.get("/emailModel", config()).catch(errorHandler);
    return res.data;
};

export const getEmailModel = async (id: string) => {
    const res = await axios
        .get(`/emailModel/${id}`, config())
        .catch(errorHandler);
    return res.data;
};

export const createEmailModel = async (data: any) => {
    const res = await axios
        .post("/emailModel", data, config())
        .catch(errorHandler);
    return res.data;
};

export const updateEmailModel = async (id: string, data: any) => {
    const res = await axios
        .put(`/emailModel/${id}`, data, config())
        .catch(errorHandler);
    return res.data;
};

export const sendTestEmail = async (id: string, html: any = null) => {
    const body = html ? { html } : {};
    const res = await axios
        .post(`/emailModel/${id}/sendTest`, body, config())
        .catch(errorHandler);
    return res.data;
};

export const deleteEmailModel = async (id: string) => {
    const res = await axios
        .delete(`/emailModel/${id}`, config())
        .catch(errorHandler);
    return res.data;
};
