import { ba } from "@fullcalendar/core/internal-common";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import TopCustomers from "views/admin/main/account/billing/components/TopCustomers";
import SleepingCustomers from "views/admin/main/account/billing/components/sleepingCustomers";
import ActivityChart from "./components/Activity";
import { getDashboard } from "interfaces/user";
import CubeLoader from "components/loader/CubeLoader";

const Performance = () => {
    const nav = useNavigate();

    const [dashboardData, setDashboardData] = useState<any>(null);

    const [lineChartDataOverallRevenue, setLineChartDataOverallRevenue] =
        useState<any[]>([
            {
                data: [
                    {
                        x: "Client A",
                        y: 218,
                    },
                    {
                        x: "Client B",
                        y: 149,
                    },
                    {
                        x: "Client C",
                        y: 50,
                    },
                    {
                        x: "Client D",
                        y: 50,
                    },
                ],
            },
        ]);

    const options = {
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            //colors: ["#417ec4", "#0260CB"],
            height: 350,
            type: "treemap",
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
            },
            formatter: function (text: any, op: any) {
                return [text, `${op.value} points`];
            },
            offsetY: -4,
        },
    };

    const fetchDashboardData = async () => {
        const res = await getDashboard();
        if (res.error) {
            nav("/login");
            return;
        }
        setDashboardData(res.data);
        setLineChartDataOverallRevenue([
            {
                data: res.data?.topUsers.map((item: any) => ({
                    x: item.email,
                    y: item.points,
                })),
            },
        ]);
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

    return (
        <>
            {!dashboardData ? (
                <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                    <p className="text-2xl font-han text-center mb-[80px]">
                        Lancement du tableau de bord...
                    </p>
                    <CubeLoader />
                </div>
            ) : (
                <div className="mt-8 md:mt-0 grid grid-cols-1 md:grid-cols-3 gap-3">
                    <ActivityChart data={dashboardData} />
                    <TopCustomers data={dashboardData?.topUsers} />
                    <div className="border rounded-xl p-4">
                        <h1 className="text-lg font-bold">
                            Répartition des points de fidélité
                        </h1>
                        <ReactApexChart
                            options={options as any}
                            series={lineChartDataOverallRevenue as any}
                            type="treemap"
                            width="100%"
                            height="85%"
                        />
                    </div>

                    <SleepingCustomers data={dashboardData?.sleepingUsers} />
                </div>
            )}
        </>
    );
};

export default Performance;
