import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

const GoogleFeedback = () => {
    const [rating, setRating] = useState(0);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const params = useParams();
    const nav = useNavigate();

    const sendFeedback = () => {
        setFeedbackSent(true);
        if (rating > 4) {
            window.open(`https://g.page/r/${params.placeId}/review`, "_blank");
        }
    };
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="relative min-h-screen">
            {feedbackSent ? (
                <div className="pt-[50px]">
                    <p className="mt-10 text-[35px] text-center font-han">
                        Merci beaucoup !
                    </p>
                    <button
                        className="mt-10 flex mx-auto rounded-2xl px-10 bg-white py-4 border-2 border-[#1f2937] border-xl cursor-pointer"
                        onClick={() => {}}
                    >
                        <p
                            className="flex mx-auto text-base"
                            onClick={() => nav(`/access/${params.id}`)}
                        >
                            Retour au menu
                        </p>
                    </button>
                </div>
            ) : (
                <div className="pt-[50px]">
                    <p className="mt-10 text-[35px] text-center font-han">
                        Laisse ton avis !
                    </p>
                    <ToastContainer />
                    <p className="px-10 mt-5 text-center text-lg">
                        Merci d'avance pour ton retour, il nous aide à améliorer
                        notre service !
                    </p>
                    <div className="mt-10 flex justify-center gap-5">
                        {rating > 0 ? (
                            <FaStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(1)}
                            />
                        ) : (
                            <FaRegStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(1)}
                            />
                        )}
                        {rating > 1 ? (
                            <FaStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(2)}
                            />
                        ) : (
                            <FaRegStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(2)}
                            />
                        )}
                        {rating > 2 ? (
                            <FaStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(3)}
                            />
                        ) : (
                            <FaRegStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(3)}
                            />
                        )}
                        {rating > 3 ? (
                            <FaStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(4)}
                            />
                        ) : (
                            <FaRegStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(4)}
                            />
                        )}
                        {rating > 4 ? (
                            <FaStar
                                className="cursor-pointer"
                                onClick={() => setRating(5)}
                                size={30}
                            />
                        ) : (
                            <FaRegStar
                                className="cursor-pointer"
                                size={30}
                                onClick={() => setRating(5)}
                            />
                        )}
                    </div>
                    <button
                        className="mt-10 flex mx-auto rounded-2xl px-10 bg-white py-4 border-2 border-[#1f2937] border-xl cursor-pointer"
                        onClick={() => {}}
                    >
                        <p
                            className="flex mx-auto text-base"
                            onClick={sendFeedback}
                        >
                            Laisser mon avis
                        </p>
                    </button>
                </div>
            )}
        </div>
    );
};

export default GoogleFeedback;
