import { MdQrCodeScanner } from "react-icons/md";

const LoginError = () => {
    return (
        <div className="relative bg-[#f7946a] min-h-screen">
            <p className="pt-[150px] text-[45px] text-center font-bold">
                Connexion impossible !
            </p>
            <p className="mt-10 text-center text-lg">
                Vous pouvez récupérer un point de fidélité uniquement en
                scannant un QR code.
            </p>
            <MdQrCodeScanner className="mx-auto mt-10 text-9xl text-center" />
            <p className="text-xs absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                Made by Fidiz.
            </p>
        </div>
    );
};

export default LoginError;
