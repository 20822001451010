function MiniStatistics(props: {
  name: string;
  values?: string;
  icon: JSX.Element;
  value: string;
  iconBg: string;
  extra?: string;
}) {
  const { name, value, icon, iconBg, extra } = props;
  return (
    <div className={`flex w-full border items-center gap-[10px] rounded-[20px] bg-white bg-clip-border px-[5px] sm:px-[18px] py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none ${extra}`}>
      <div
        className={`flex min-h-[50px] min-w-[50px] items-center justify-center rounded-full text-[33px] text-brand-500 dark:!bg-navy-700 dark:text-white ${iconBg} `}
      >
        {icon}
      </div>
      <div className="">
        <p className=" mb-1 text-sm font-medium text-gray-600">{name}</p>
        <h3 className="text-md sm:text-xl font-bold leading-6 text-navy-700 dark:text-white">
          {value}
        </h3>
      </div>
    </div>
  );
}

export default MiniStatistics;
