import { useNavigate } from "react-router-dom";

const CampaignMenu = (props: { path: string }) => {
    const { path } = props;
    const nav = useNavigate();

    return (
        <div className="mt-4 sm:mt-0 ml-5 flex gap-6">
            <p
                className={`${
                    path === "/marketing" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/marketing")}
            >
                {" "}
                Mes campagnes
            </p>
            <p
                className={`${
                    path === "/email-model" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/email-model")}
            >
                Mes templates
            </p>
            <p
                className={`${
                    path === "/customers-lists" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/customers-lists")}
            >
                Mes listes clients
            </p>
            <p
                className={`${
                    path === "/auto-marketing" && "underline"
                } cursor-pointer text-xs sm:text-base`}
                onClick={() => nav("/auto-marketing")}
            >
                Automatisations
            </p>
        </div>
    );
};

export default CampaignMenu;
