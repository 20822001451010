import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LiveChatFooter from "components/helper/LiveChatFooter";
import CubeLoader from "components/loader/CubeLoader";
import CampaignMenu from "views/email-model/component/CampaignMenu";
import { getTargets } from "interfaces/target";

const colorSets = [
    {
        gradient: "from-[#d4adfe] to-[#d4adfe9b]",
        btnColor: "bg-[#d4adfe]",
    },
    {
        gradient: "from-talk-500 to-[#fedd6597]",
        btnColor: "bg-talk-500",
    },
    {
        gradient: "from-[#f1e9f8] to-[#f1e9f8b2]",
        btnColor: "bg-[#f1e9f8]",
    },
];

const CustomerTarget = (props: {
    logo: any;
    key: string;
    name: string;
    service: string;
    description: string;
    url: string;
    gradient: string;
    btnColor: string;
    total: number;
}) => {
    const { name, url, gradient, btnColor, total } = props;
    const nav = useNavigate();

    return (
        <div className="relative w-full mt-2 shadow-lg border border-gray-300 rounded-xl">
            <div className="absolute top-3 right-3 text-xs px-2 py-1 rounded-lg">
                <p className="font-bold text-sm">{total} clients</p>
            </div>
            <div
                className={`px-2 flex min-h-[170px] rounded-t-lg items-center justify-center`}
            >
                <p className="my-10 align-middle text-center text-lg">{name}</p>
            </div>
            <div className="w-full px-4">
                <button
                    className={`mb-4 linear w-full rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                    onClick={() => nav(url)}
                >
                    Modifier ma liste
                </button>
            </div>
        </div>
    );
};

const CustomersLists = () => {
    const nav = useNavigate();
    const [customerTargets, setCustomerTargets] = useState(null);

    const initCustomersTargets = async () => {
        const res = await getTargets();
        if (res) setCustomerTargets(res.data);
    };

    useEffect(() => {
        initCustomersTargets();
    }, []);

    return (
        <div className="">
            {customerTargets && customerTargets.length > 0 && (
                <div className="flex justify-between">
                    <div className="mt-2">
                        <CampaignMenu path="/customers-lists" />
                    </div>
                    <button
                        className={`p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                        onClick={() => nav("/customers-lists/new")}
                    >
                        Ajouter un ciblage
                    </button>
                </div>
            )}
            {/* No email Model created */}
            {customerTargets && customerTargets.length === 0 && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/customers-lists" />
                        </div>
                    </div>
                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-bold text-center">
                            Mettez en place vos ciblages clients
                        </p>
                        <p className="flex mx-auto mt-5 text-center max-w-[550px]">
                            Maintenant que vous pouvez relancer vos clients,
                            créez des listes clients personnalisées pour cibler
                            les bons clients au bon moment !
                        </p>
                        <button
                            className={`mt-5 flex mx-auto p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-sm transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/customers-lists/new")}
                        >
                            Ajouter un ciblage client
                        </button>
                    </div>
                </>
            )}
            {/* List email models */}
            {customerTargets && customerTargets.length > 0 && (
                <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {customerTargets &&
                        customerTargets.map(
                            (emailModel: any, index: number) => (
                                <CustomerTarget
                                    key={emailModel._id}
                                    name={emailModel.name}
                                    service={emailModel.service}
                                    description={emailModel.description}
                                    logo={emailModel.logo}
                                    total={emailModel.totalCustomers}
                                    url={`/customers-lists/${emailModel._id}`}
                                    gradient={colorSets[index % 3].gradient}
                                    btnColor={colorSets[index % 3].btnColor}
                                />
                            ),
                        )}
                </div>
            )}
            {/* Loading */}
            {!customerTargets && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/customers-lists" />
                        </div>
                    </div>

                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center mb-[80px]">
                            Chargement des cibles...
                        </p>
                        <CubeLoader />
                    </div>
                </>
            )}
            <LiveChatFooter />
        </div>
    );
};

export default CustomersLists;
