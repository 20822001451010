function TopCustomerElement(props: {
    givenName: string;
    email: string;
    rank?: number;
    total: number;
}) {
    const { rank, givenName, total, email } = props;

    return (
        <div className="mb-4 ml-1 flex items-center justify-between">
            {/* left side */}
            <div className="flex items-center gap-3">
                <div className="flex h-9 w-9 items-center justify-center rounded-full border">
                    {/* <img src={avatar} className="rounded-full" alt="img" /> */}
                    <p>#{rank}</p>
                </div>
                <div>
                    <h5 className="text-base font-bold text-navy-700 dark:text-white">
                        {givenName}
                    </h5>
                    <p className="text-xs font-medium text-gray-600">
                        {" "}
                        {email}{" "}
                    </p>
                </div>
            </div>
            {/* right side */}
            <div
                className={`ml-4 flex items-center text-center rounded-xl px-2.5 py-1 bg-green-50 text-green-500`}
            >
                <p className="text-xs font-bold"> {total.toFixed()} points</p>
            </div>
        </div>
    );
}

export default TopCustomerElement;
