import TalkImg from "assets/img/talk-logo-2.svg";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { getMyInfo } from "interfaces/user";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { getQRCodeUrl } from "interfaces/presentationMode";

const OnboardingTestingStep = () => {
    const nav = useNavigate();
    const [QRCodeUrl, setQRCodeUrl] = useState("");
    const [rewards, setRewards] = useState(0);
    const [supportType, setSupportType] = useState("");

    const initUser = async () => {
        const user = await getMyInfo();
        if (user) {
            const support = user?.data?.onboardingSettings?.supportType;
            if (support === "tablet") setSupportType("du rendu sur tablette");
            if (support === "flyers") setSupportType("du votre format papier");
            if (support === "phone")
                setSupportType("de l'expérience téléphone");
        }
    };

    const initQrCode = async () => {
        const res = await getQRCodeUrl();
        if (res) {
            setQRCodeUrl(res.url);
            setRewards(res.rewards);
        }
    };

    useEffect(() => {
        initQrCode();
        initUser();

        //Init user after 3 sec do be sure that previous onboarding change is handle
        setTimeout(() => {
            initUser();
        }, 3000);
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-12">
            {/* Left side */}
            <div className="hidden lg:block col col-span-4 border-r-2 border-b-2 rounded-b-lg border-[#1f2937] p-5 bg-talk-500 h-screen">
                <div
                    className={`flex mx-auto items-center bg-white w-[140px] rounded-lg border-2 border-[#1f2937]`}
                >
                    <img
                        className={`p-2 w-[140px]`}
                        src={TalkImg}
                        alt="Toola logo"
                    />
                </div>
                <p className="mt-3 text-lg text-center">
                    Faites revenir vos clients en boutique avec Fidiz
                </p>
                <div className="mt-[100px] px-10">
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            1
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Capter les infos de vos clients.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            2
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Lancer des campagnes marketing.
                        </p>
                    </div>
                    <div className="mx-4 h-[60px] w-2 bg-[#1f2937] roundex-xl"></div>
                    <div className="flex gap-3">
                        <p className="font-han text-3xl border-4 border-[#1f2937] rounded-full w-10 h-10 text-center">
                            3
                        </p>
                        <p className="flex font-han my-auto text-sm text-center">
                            Mesurez l'impact de vos actions.
                        </p>
                    </div>
                </div>
            </div>
            {/* Right Section */}
            <div className="col col-span-8 p-10 lg:px-[80px]">
                {/* <h1 className="mt-5 font-han text-3xl md:mt-0 font-bold">
                    On commence ?
                </h1>
                <div className="mt-5 flex gap-3">
                    <p className="mt-2 text-xl font-bold">Notre mission:</p>
                    <p className="mt-2 text-lg">
                        Captez les informations de vos clients et les faire
                        revenir dans votre boutique !
                    </p>
                </div>
                <div className="mt-2 flex gap-3">
                    <p className="mt-2 text-xl font-bold">Comment ?</p>
                    <p className="mt-2 text-lg">
                        Nous proposons plusieurs outils, vous pouvez forcement
                        en trouver un qui vous convient.
                    </p>
                </div> */}
                <div className="flex gap-5">
                    <BiArrowBack
                        className="flex my-auto cursor-pointer h-[30px] w-[30px]"
                        onClick={() => nav("/3/onboarding")}
                    />
                    <h1 className="font-han text-2xl font-bold">
                        Vous pouvez tester
                    </h1>
                </div>
                <div className="mt-5 lg:mt-10">
                    <div className="mx-3 lg:mx-10 lg:flex justify-between">
                        <p className="text-center text-lg font-bold">
                            Aperçu {supportType}
                        </p>
                        <p
                            className="mt-2 lg:mt-0 lg:flex text-blue-600 text-lg lg:text-base text-center cursor-pointer hover:font-extralight"
                            onClick={() => window.open(QRCodeUrl, "_blank")}
                        >
                            Simuler un scan du QR-code
                        </p>
                    </div>
                    {/* Preview */}
                    <div
                        className={`mt-1 grid grid-cols-1 gap-10 p-10 py-[30px] mx-0 lg:mx-5 bg-[#FEDE65] shadow-xl rounded-xl border-4 border-[#1f2937]`}
                    >
                        <div className="px-1 lg:px-5">
                            <p className="text-2xl lg:text-3xl font-han text-center">
                                Envie de cumuler des points ?
                            </p>
                            <p className="mt-5 text-lg text-center">
                                Bonne nouvelle, on récompense ta fidélité 🎉
                            </p>
                            <div className="mt-7">
                                <div className="">
                                    <div className="">
                                        <QRCode
                                            size={170}
                                            className="flex mx-auto p-1 lg:p-3 border-4 border-[#1f2937] rounded-xl"
                                            value={QRCodeUrl}
                                        />
                                        <p
                                            className="mt-2 text-blue-500 font-bold text-lg text-center lg:text-sm cursor-pointer hover:font-extralight"
                                            onClick={() =>
                                                window.open(QRCodeUrl, "_blank")
                                            }
                                        >
                                            Simuler le scan
                                        </p>
                                        <svg
                                            width="446"
                                            height="479"
                                            viewBox="0 0 446 479"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="-rotate-90 mx-auto mt-4 h-20 w-20"
                                        >
                                            <path
                                                d="M379.831 88.044a5.002 5.002 0 0 1-4.49-7.208 5.003 5.003 0 0 1 2.37-2.322c10.5-4.89 18.17-8.37 27.06-12.41 7.45-3.37 15.74-7.13 27.3-12.45-7.28-7.77-13.32-14.31-19.2-20.68-7.4-8-14.39-15.59-22.87-24.52a5.017 5.017 0 0 1-1.045-1.65 5.016 5.016 0 0 1 .095-3.828 5.015 5.015 0 0 1 2.775-2.641 5.004 5.004 0 0 1 5.425 1.22c8.53 9 15.54 16.58 23 24.63 7.1 7.68 14.43 15.63 23.89 25.66a5.007 5.007 0 0 1 1.292 4.396 5.001 5.001 0 0 1-2.832 3.603c-15 6.93-24.92 11.42-33.67 15.39-8.75 3.97-16.52 7.49-27 12.36-.659.3-1.376.454-2.1.45z"
                                                fill="#000"
                                            ></path>
                                            <path
                                                d="M79.78 478.756c-1.35 0-2.7 0-4.08-.12-25.63-1.56-46.32-12.36-59.82-31.24-16.57-23.16-18.59-53.11-13.25-72.53a5.007 5.007 0 0 1 6.156-3.495 5.002 5.002 0 0 1 3.495 6.155c-4.72 17.12-2.92 43.57 11.73 64.06 11.69 16.34 29.77 25.7 52.3 27.07 29.19 1.76 52.9-19.62 72.49-65.39 17.76-41.47 29.79-97.61 40.41-147.13 8.57-40 16.67-77.76 26.78-104.4 7.47-19.69 25.84-56.58 65-82.49 42.77-28.32 96.84-34.65 160.72-18.81a4.995 4.995 0 0 1 3.192 2.241 5.014 5.014 0 0 1 .61 3.853 4.998 4.998 0 0 1-6.212 3.606c-150.85-37.39-201.91 63.42-213.96 95.14-9.84 25.94-17.86 63.35-26.35 103-10.71 50-22.86 106.6-41 149-20.59 48.02-46.21 71.48-78.21 71.48z"
                                                fill="#000"
                                            ></path>
                                        </svg>
                                        <p className="mt-2 text-center animate-pulse">
                                            {rewards > 0
                                                ? `Scannez moi pour gagner ${rewards} points de fidélité`
                                                : "Scannez moi pour consulter votre solde"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="mt-3 text-center">
                    Vous avez testé ? On passe à la suite !
                </p>
                <div
                    className="flex mx-auto mt-1 w-[300px] py-2 rounded-lg border-2 border-[#1f2937] cursor-pointer bg-[#d4adfe]"
                    onClick={() => nav("/")}
                >
                    <p className="flex mx-auto font-han">
                        Ouvrir mon tableau de bord
                    </p>
                </div>
            </div>
        </div>
    );
};
export default OnboardingTestingStep;
