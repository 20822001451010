import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ScoreChart = (props: { percentage: number }) => {
    const { percentage } = props;

    const [barSerie, setBarSerie] = useState<any[]>([]);

    const [barOptions, setBarOptions] = useState<any>({
        series: [76],
        chart: {
            type: "radialBar",
            offsetY: -20,
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#ffffff",
                    strokeWidth: "97%",
                    margin: 5,
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: "#",
                        opacity: 1,
                        blur: 2,
                    },
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: -2,
                        fontSize: "22px",
                    },
                },
            },
        },
        grid: {
            padding: {
                top: -10,
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "light",
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 255, 56, 91],
            },
        },
        labels: ["Average Results"],
    });

    useEffect(() => {
        setBarSerie([percentage]);
    }, [percentage]);

    return (
        <ReactApexChart
            options={barOptions as any}
            series={barSerie as any}
            type="radialBar"
            width="100%"
            height="300px"
        />
    );
};

export default ScoreChart;
