import { useState } from "react";
import Card from "components/card";
import { IoPaperPlane } from "react-icons/io5";
import { addCustomerTasks } from "interfaces/customer";
import TaskMessage from "./TaskMessage";
import { markTaskAsDone } from "interfaces/task";

// const columns = columnsDataCheck;
export default function Tasks(props: { id: string, tasks: any }) {

    const { id, tasks } = props;
    const [taskDate, setTaskDate] = useState(new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000));
    const [content, setContent] = useState("");
    const [taskList, setTaskList] = useState(tasks);

    const addingTask = async () => {
        const res = await addCustomerTasks(id, content, taskDate.toISOString());
        if (res) {
            const newTask = {
                content,
                status: 'pending',
                dueDate: taskDate.toISOString(),
                _id: res.newTask
            };
            setTaskList([...taskList, newTask])
            setContent("");
            setTaskDate(new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000));
        }
    };

    return (
        <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto border"}>
            <div className="mt-5 w-full px-[8px]">
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Tâches à effectuer
                </h4>
            </div>

            <div className="mt-2 max-h-[170px] overflow-y-auto">
                {taskList
                    .sort(
                        (a: any, b: any) =>
                            new Date(a.dueDate).getTime() -
                            new Date(b.dueDate).getTime(),
                    )
                    .filter((t: any) => t.status === "pending")
                    .map((task: any, index: number) => (
                        <TaskMessage
                            key={index}
                            markAsDone={() => {
                                markTaskAsDone(task._id);
                                const newTaskList = taskList.map((t: any) => {
                                    if (t._id === task._id) {
                                        return { ...t, status: "done" };
                                    }
                                    return t;
                                });
                                setTaskList(newTaskList);
                            }}
                            message={task.content}
                            time={task.dueDate}
                            extra="!bg-lightPrimary w-full rounded-xl"
                            text="text-navy-700"
                            timecolor="text-gray-400 dark:text-white"
                        />
                    ))}
                {taskList &&
                    taskList.filter((t: any) => t.status === "pending").length ===
                        0 && (
                        <div className="text-center py-[70px] text-sm text-gray-600">
                            <p className="text-center text-sm text-gray-600 font-bold">
                                Aucune tâche pour le moment.
                            </p>
                            <p className="px-10 text-center text-sm text-orange-400">
                                Commercialement parlant, on peut faire mieux…
                            </p>
                            <p className="px-10 text-center text-sm text-orange-400">
                                nous allons t’aider ! 💪🏻
                            </p>
                        </div>
                    )}
            </div>
            <div className="mt-6 grid grid-cols-12 items-center gap-2">
                <div className="flex col-span-3 h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3">
                    <input
                        type="date"
                        value={taskDate.toISOString().split("T")[0]}
                        onChange={(e) => setTaskDate(new Date(e.target.value))}
                        className="ml-2 bg-lightPrimary outline-none text-xs"
                    />
                </div>
                <div className="flex col-span-7 h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
                    <input
                        placeholder="Votre tâche..."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="ml-3 h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                        type="text"
                    />
                </div>
                {/* button */}
                <button
                    className="col-span-2 linear flex items-center justify-center rounded-full bg-orange-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer  hover:bg-orange-600 active:bg-orange-700"
                    onClick={addingTask}
                >
                    <IoPaperPlane />
                </button>
            </div>
        </Card>
    );
}
