import { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import InputField from "components/fields/InputField";
import { IoMdTrash } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import arrowImage from "assets/img/arrow.png";
import { getMyRestaurant, updateMyRestaurant } from "interfaces/restaurant";
import SaveStatus from "components/actions/SaveStatus";
import QRCode from "react-qr-code";

const WheelManager = () => {
    const [saveStatus, setSaveStatus] = useState("none");
    const [totalProbability, setTotalProbability] = useState(0);
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [newPrize, setNewPrize] = useState(null);
    const [newPrizeProbability, setNewPrizeProbability] = useState(null);
    const [data, setData] = useState([]);
    const [restaurant, setRestaurant] = useState(null);

    const handleSpinClick = () => {
        if (!mustSpin) {
            const options: any = [];

            data.forEach((d) => {
                for (let i = 0; i < Number(d.probability); i++) {
                    options.push(d.option);
                }
            });
            console.log(options);
            const randomNumber = Math.floor(Math.random() * 100);
            console.log(randomNumber);
            console.log(options[randomNumber]);
            const prizeIndex = data.findIndex(
                (d) => d.option === options[randomNumber],
            );
            setPrizeNumber(prizeIndex);

            setMustSpin(true);
        }
    };

    const initRestaurant = async () => {
        const res = await getMyRestaurant();
        if (res) {
            console.log(res.wheelGifts);
            if (res?.wheelGifts?.length > 0) {
                console.log("setting data");
                setData((prev: any) => res.wheelGifts);
                setRestaurant(res);
            }
        }
    };

    const saveChanges = async () => {
        if (!data || data.length === 0) return;
        const res = await updateMyRestaurant({ wheelGifts: data });
        if (res) setSaveStatus("saved");
        else setSaveStatus("error");
    };

    useEffect(() => {
        const totalProbability = data.reduce(
            (acc, current) => acc + Number(current.probability),
            0,
        );
        setTotalProbability(totalProbability);
    }, [data]);

    useEffect(() => {
        setSaveStatus("saving");
        const totalProbability = data.reduce(
            (acc, current) => acc + Number(current.probability),
            0,
        );
        const delayDebounceFn = setTimeout(async () => {
            if (Number(totalProbability) === 100) saveChanges();
            else setSaveStatus("error");
        }, 1500);
        return () => clearTimeout(delayDebounceFn);
    }, [data]);

    const updateOptions = async (e: any, i: number) => {
        const newData = data.map((data, index) =>
            index === i
                ? {
                      ...data,
                      option: e.target.value,
                  }
                : data,
        );
        setData(newData);
    };

    const updateProbability = async (e: any, i: number) => {
        // Calculate the total probability with the new value
        const probabilityTotal = data.reduce((acc, current, index) => {
            if (index === i) {
                return acc + Number(e.target.value);
            }
            return acc + Number(current.probability);
        }, 0);
        if (probabilityTotal > 100) return;
        const newData = data.map((data, index) =>
            index === i
                ? {
                      ...data,
                      probability: e.target.value,
                  }
                : data,
        );
        setData(newData);
    };

    useEffect(() => {
        initRestaurant();
    }, []);

    return (
        <div className="relative">
            <SaveStatus status={saveStatus} />
            <div className="mt-3 grid grid-cols-3">
                <div className="col-span-2">
                    <div className="mt-10 flex justify-center">
                        {data.length > 0 && (
                            <Wheel
                                mustStartSpinning={mustSpin}
                                prizeNumber={prizeNumber}
                                data={data}
                                backgroundColors={[
                                    "#c2e5ff",
                                    "#5167f6",
                                    "#e6e6e6",
                                ]}
                                perpendicularText={true}
                                pointerProps={{
                                    src: arrowImage,
                                    style: {
                                        width: "50px",
                                        height: "50px",
                                        right: "10px",
                                        rotate: "135deg",
                                    },
                                }}
                                fontSize={20}
                                fontFamily="Arial"
                                fontStyle=""
                                outerBorderWidth={2}
                                innerBorderWidth={2}
                                radiusLineWidth={2}
                                onStopSpinning={() => {
                                    setMustSpin(false);
                                }}
                            />
                        )}
                    </div>
                    <div className="flex justify-center mt-5">
                        <button
                            className={`p-5 linear rounded-xl bg-primary text-white font-bold py-3 transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={handleSpinClick}
                        >
                            Tourner la roue
                        </button>
                    </div>
                </div>
                <div className="">
                    <div className="rounded-xl border-2 border-[#1f2937] w-[300px] overflow-y-auto max-h-[500px]">
                        <div>
                            <h1 className="p-3 text-xl md:mt-0 font-bold">
                                Réglage de la roue
                            </h1>
                            <div key={"create"} className="flex p-2 gap-4">
                                <InputField
                                    id={`new-reward-create`}
                                    label="Cadeau"
                                    placeholder="Un café"
                                    type="text"
                                    onChange={(e: any) =>
                                        setNewPrize(e.target.value)
                                    }
                                    value={newPrize}
                                    inputSmall={true}
                                />
                                <div className="flex">
                                    <InputField
                                        id={`new-probability-create`}
                                        label="Probabilité"
                                        placeholder="50"
                                        min="1"
                                        type="number"
                                        extra="w-[60px]"
                                        onChange={(e: any) =>
                                            setNewPrizeProbability(
                                                e.target.value,
                                            )
                                        }
                                        value={newPrizeProbability}
                                        inputSmall={true}
                                    />
                                    <p className="ml-2 mt-7">%</p>
                                </div>
                                <MdAdd
                                    className="mt-7 cursor-pointer"
                                    size={25}
                                    onClick={() => {
                                        if (!newPrize || !newPrizeProbability)
                                            return;
                                        const newData = [
                                            ...data,
                                            {
                                                option: newPrize,
                                                probability:
                                                    newPrizeProbability,
                                            },
                                        ];
                                        setData(newData);
                                        setNewPrize(null);
                                        setNewPrizeProbability(null);
                                    }}
                                />
                            </div>
                            <div className="mt-3 border-b border-[#1f2937]"></div>
                            {data.map((d, i) => (
                                <>
                                    <div key={i} className="flex p-2 gap-4">
                                        <InputField
                                            id={`reward-${i}`}
                                            label="Cadeau"
                                            placeholder="Un menu offert"
                                            type="text"
                                            onChange={(e: any) =>
                                                updateOptions(e, i)
                                            }
                                            value={d.option}
                                            inputSmall={true}
                                        />
                                        <div className="flex">
                                            <InputField
                                                id={`reward-${i}`}
                                                label="Probabilité"
                                                min="0"
                                                placeholder="Un menu offert"
                                                type="number"
                                                extra="w-[60px]"
                                                onChange={(e: any) =>
                                                    updateProbability(e, i)
                                                }
                                                value={d.probability}
                                                inputSmall={true}
                                            />
                                            <p className="ml-2 mt-7">%</p>
                                        </div>
                                        {data.length > 1 && (
                                            <IoMdTrash
                                                className="mt-7 cursor-pointer"
                                                onClick={() => {
                                                    if (data.length === 1)
                                                        return;
                                                    const newData = data.filter(
                                                        (_, index) =>
                                                            index !== i,
                                                    );
                                                    setData(newData);
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div className="border-b border-[#1f2937]"></div>
                                </>
                            ))}
                            <p className={`m-2 `}>
                                {totalProbability === 100 ? "👍" : "👎"}{" "}
                                Probabilité total: {totalProbability}%
                            </p>
                        </div>
                    </div>
                    <div className="mt-2 w-[300px] rounded-xl border-2 border-[#1f2937] overflow-y-auto h-[220px]">
                        <h1 className="p-3 text-xl md:mt-0 font-bold">
                            Tester & partager
                        </h1>
                        <QRCode
                            size={100}
                            className="mt-2 flex mx-auto p-3 border-4 mb-2 border-[#1f2937] rounded-xl"
                            value={`https://app.fidiz.io/game-wheel/${restaurant?._id}`}
                        />
                        <p
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `https://app.fidiz.io/game-wheel/${restaurant?._id}`,
                                )
                            }
                            className="flex justify-center mx-auto mb-5 cursor-pointer p-2 bg-[#1f2937] text-white text-xs w-[250px] rounded-xl"
                        >
                            Copier l'url
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WheelManager;
