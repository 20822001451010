import { IoMdMenu } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import {
    MdKeyboardArrowDown,
    MdLogout,
    MdOutlineExtension,
    MdSettings,
} from "react-icons/md";
import { useEffect, useState } from "react";

import Dropdown from "components/dropdown";
import { getMyInfo } from "interfaces/user";
import { AiTwotoneShop } from "react-icons/ai";
// import { getCheckoutSession } from "interfaces/stripe";
// import { getMyInfo } from "interfaces/user";

const Navbar = (props: {
    onOpenSidenav: () => void;
    openSideNav: () => void;
    brandText: string;
    secondary?: boolean | string;
}) => {
    const { brandText, openSideNav } = props;
    const [devMode, setDevMode] = useState(false);
    const [me, setMe] = useState<any>(null);
    const nav = useNavigate();

    const openCheckoutSession = async () => {
        nav("/checkout");
        localStorage.setItem("waiting-checkout", "true");
    };

    const onKeyPressed = (e: any) => {
        if (e.keyCode === 113) {
            setDevMode(!devMode);
        }
    };

    const initMe = async () => {
        const me = await getMyInfo();
        setMe(me.data);

        const user = me.data;

        if (user.trialEnded && !user.isSubscribed) {
            openCheckoutSession();
        }
    };

    useEffect(() => {
        initMe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <nav className="fixed border-b left-0 sm:left-[285px] w-full sm:w-[calc(100%-295px)] top-0 py-3 p-2 flex items-center justify-between bg-white">
            <div className="block ml-2" onKeyDown={onKeyPressed}>
                <p className="ml-10 text-2xl font-medium text-navy-700">
                    <Link
                        to="#"
                        className="font-semibold hover:text-navy-700 dark:hover:text-white"
                    >
                        {brandText}
                    </Link>
                </p>
            </div>
            {devMode && (
                <div className="">
                    <p className="ml-1 text-xl text-gray-900 sm:hidden md:hidden lg:hidden xl:hidden">
                        d
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 sm:inline md:hidden lg:hidden xl:hidden">
                        sm
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 md:inline lg:hidden xl:hidden">
                        md
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 lg:inline xl:hidden">
                        lg
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 xl:inline 2xl:hidden">
                        xl
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 2xl:inline 3xl:hidden">
                        2xl
                    </p>
                    <p className="ml-1 hidden text-xl text-gray-900 3xl:inline">
                        3xl
                    </p>
                </div>
            )}
            <div className="flex gap-5">
                {me && !me.isSubscribed && (
                    <>
                        <p className="hidden md:flex my-auto">
                            {me?.trialsRemainingDays > 0
                                ? `Il vous reste ${me?.trialsRemainingDays} jours d'essai`
                                : "Abonnement nécessaire"}
                        </p>
                        <div
                            className="mr-4 flex my-auto bg-primary text-white text-xs px-3 py-2 rounded-lg cursor-pointer"
                            onClick={() => nav("/checkout")}
                        >
                            <p className="font-bold">Je m'abonne</p>
                        </div>
                    </>
                )}
            </div>
            <div className="flex gap-[5px] sm:gap-[10px]">
                {me && me.isSubscribed && (
                    <div
                        className="mr-4 flex my-auto bg-[#1f2937] text-white text-xs px-3 py-2 rounded-lg cursor-pointer"
                        onClick={() => nav("/tokens")}
                    >
                        <p className="font-han">{me.tokens} tokens</p>
                    </div>
                )}
                <div className="step-user-menu hidden border border-[#1f2937] sm:flex my-auto relative px-2 h-[40px] w-[140px] hover:bg-gray-50 hover:cursor-pointer gap-2 justify-between items-center rounded-3xl bg-white bg-cover bg-no-repeat shadow-2xl shadow-shadow-500">
                    <p className="font-bold ml-3 text-xs my-auto">Mon compte</p>
                    {/* Profile & Dropdown */}
                    <Dropdown
                        button={<MdKeyboardArrowDown size={30} />}
                        onClick={openSideNav}
                        children={
                            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                                <div className="ml-2 mt-4 flex flex-col gap-2 px-2">
                                    <div className="flex my-auto gap-1">
                                        <MdSettings className="my-auto" />
                                        <a
                                            href="/settings"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Paramètres du compte
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <AiTwotoneShop
                                            id="test"
                                            className="my-auto"
                                        />
                                        <a
                                            href="/shop-settings"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Paramètres boutique
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <MdOutlineExtension className="my-auto" />
                                        <a
                                            href="/integrate"
                                            className="step-my-account text-xs text-gray-800 dark:text-white hover:dark:text-white"
                                        >
                                            Mes extensions
                                        </a>
                                    </div>
                                    <div className="flex my-auto gap-1">
                                        <MdLogout className="my-auto text-red-500" />
                                        <a
                                            href="https://fidiz.io"
                                            onClick={() => {
                                                localStorage.removeItem(
                                                    "token",
                                                );
                                            }}
                                            className="text-xs text-red-500 hover:text-red-500"
                                        >
                                            Se déconnecter
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                        classNames={"py-2 top-8 -left-[184px] w-max"}
                    />
                </div>
                <div className="sm:hidden relative flex h-[50px] w-[50px] items-center justify-around rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
                    <button
                        onClick={openSideNav}
                        className={`items-center justify-center bg-[transparent] dark:border-white dark:text-white`}
                    >
                        <IoMdMenu size={35} color="#1B254B" />
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
