export function dateToFrench(date: string) {
    const dateObj = new Date(date);
    const day = dateObj.toLocaleString("fr-FR", { weekday: "long" });
    const dayNumber = dateObj.toLocaleString("fr-FR", { day: "numeric" });
    const month = dateObj.toLocaleString("fr-FR", { month: "long" });
    const dayUper = day.charAt(0).toUpperCase() + day.slice(1);
    return `${dayUper} ${dayNumber} ${month}`;
}

export function dateToFrenchShort(date: string) {
    const dateObj = new Date(date);

    const options: any = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        timeZone: "UTC",
    };

    const formattedDate = dateObj.toLocaleDateString("fr-FR", options);

    // check if date is invalide
    if (formattedDate === "Invalid Date") return null;

    return formattedDate;
}

export function formatHours(date: string) {
    const dateObj = new Date(date);
    const hours = dateObj.toLocaleString("fr-FR", { hour: "numeric" });
    const minutes = dateObj.toLocaleString("fr-FR", { minute: "numeric" });
    return `${hours}${minutes}`.replace(" ", "");
}
