import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LiveChatFooter from "components/helper/LiveChatFooter";
import CubeLoader from "components/loader/CubeLoader";
import CampaignMenu from "views/email-model/component/CampaignMenu";
import { dateToFrench } from "utils/date";
import { deleteAutomation, getAutomations } from "interfaces/automation";
import { IoMdTrash } from "react-icons/io";

const AutoMarketing = () => {
    const nav = useNavigate();
    const [automations, setAutomations] = useState(null);

    const initAutomations = async () => {
        const res = await getAutomations();
        if (res) setAutomations(res.data);
    };

    useEffect(() => {
        initAutomations();
    }, []);

    return (
        <div className="">
            {automations && automations.length > 0 && (
                <div className="flex justify-between">
                    <div className="mt-2">
                        <CampaignMenu path="/auto-marketing" />
                    </div>
                    <button
                        className={`p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                        onClick={() => nav("/auto-marketing/new")}
                    >
                        Ajouter une automatisation
                    </button>
                </div>
            )}
            {/* No email Model created */}
            {automations && automations.length === 0 && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/auto-marketing" />
                        </div>
                    </div>
                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-bold text-center">
                            Automatisez vos campagnes
                        </p>
                        <p className="flex mx-auto mt-5 text-center max-w-[550px]">
                            Inscriptions, anniversaires, relances, etc. Créez
                            des campagnes automatisées pour gagner du temps et
                            toucher vos clients au bon moment.
                        </p>
                        <button
                            className={`mt-5 flex mx-auto p-5 linear rounded-xl bg-primary text-white font-bold py-3 text-sm transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={() => nav("/auto-marketing/new")}
                        >
                            Ajouter une automatisation
                        </button>
                    </div>
                </>
            )}
            {/* List email models */}
            {/* List automations */}
            {automations && automations.length > 0 && (
                <>
                    <div className="mt-5 hidden sm:grid grid-cols-12 px-5 w-full text-sm font-medium">
                        <p className="col-span-2">Date</p>
                        <p className="col-span-3">Déclencheur</p>
                        <p className="col-span-3">Template</p>
                        <p className="col-span-2">Nombre d'envois</p>
                        <p className="col-span-2">Action</p>
                    </div>
                    <div className="mt-1 grid grid-cols-1 gap-3">
                        {automations.map((automation: any, index: number) => (
                            <div className="grid grid-cols-1 sm:grid-cols-12 w-full border rounded-xl p-4 gap-4">
                                <p className="col-span-2">
                                    {dateToFrench(automation.createdAt)}
                                </p>
                                <p className="col-span-3 text-sm">
                                    {automation.name}
                                </p>
                                <div className="col-span-3 text-sm">
                                    <p>{automation.templateName}</p>
                                </div>
                                <p className="hidden sm:flex col-span-3 sm:col-span-2">
                                    {automation.totalSend || 0}
                                </p>
                                <div className="col-span-2 flex gap-4">
                                    <IoMdTrash
                                        className="cursor-pointer"
                                        title="Supprimer cette campagne"
                                        onClick={async () => {
                                            await deleteAutomation(
                                                automation._id,
                                            );
                                            initAutomations();
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {/* Loading */}
            {!automations && (
                <>
                    <div className="flex justify-between">
                        <div className="mt-2">
                            <CampaignMenu path="/auto-marketing" />
                        </div>
                    </div>

                    <div className="mt-8 p-10 border-2 border-[#1f2937] rounded-xl">
                        <p className="text-2xl font-han text-center mb-[80px]">
                            Chargement des cibles...
                        </p>
                        <CubeLoader />
                    </div>
                </>
            )}
            <LiveChatFooter />
        </div>
    );
};

export default AutoMarketing;
