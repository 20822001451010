import GoBack from "components/actions/GoBack";
import phoneImage from "../../assets/img/phone.png";
import gmailImage from "../../assets/img/gmail.png";

import { useNavigate } from "react-router-dom";

const CreateTemplate = () => {
    const nav = useNavigate();

    return (
        <>
            <GoBack text="Mes templates" />
            <div className="mt-5 grid grid-cols-2 gap-4">
                <div
                    className="border rounded-xl p-6 cursor-pointer hover:bg-gray-50"
                    onClick={() => nav("/email-model/new")}
                >
                    <img src={gmailImage} alt="Tablet" className="w-[60px]" />
                    <div>
                        <h1 className="mt-4 text-lg font-bold">
                            Créer un template email
                        </h1>
                        <p className="text-sm mt-1">
                            Utilisez notre éditeur pour créer un email
                            personnalisé. Vous pouvez aussi utiliser les
                            templates déjà existant dans votre espace templates.
                        </p>
                    </div>
                </div>
                <div
                    className="border rounded-xl p-6 cursor-pointer hover:bg-gray-50"
                    onClick={() => nav("/sms-model/new")}
                >
                    <img src={phoneImage} alt="Tablet" className="w-[60px]" />
                    <div>
                        <h1 className="mt-4 text-lg font-bold">
                            Créer un template SMS
                        </h1>
                        <p className="text-sm mt-1">
                            Ciblez vos clients de la manière la plus efficace
                            grâces à une campagne SMS. Il suffit d'écrire le
                            contenu de votre message et de l'envoyer à vos
                            clients.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateTemplate;
