import GoBack from "components/actions/GoBack";
import { createEmailModel, sendTestEmail } from "interfaces/emailModel";
import { getMyInfo } from "interfaces/user";
import { useEffect, useRef, useState } from "react";

import EmailEditor from "react-email-editor";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateEmailModel = () => {
    const emailEditorRef = useRef(null);
    const nav = useNavigate();
    const [user, setUser] = useState(null);
    const [testEmailSend, setTestEmailSend] = useState(false);
    const [emailModel, setEmailModel] = useState<any>({
        name: `Template du ${new Date().toLocaleDateString()} à ${new Date().toLocaleTimeString()}`,
        description: "",
    });

    const saveTemplate = () => {
        emailEditorRef.current.editor.exportHtml(
            async (data: any) => {
                const { design, html } = data;
                const body = {
                    name: emailModel.name,
                    description: emailModel.description,
                    json: JSON.stringify(design),
                    html,
                };
                console.log(body);
                const res = await createEmailModel(body);
                if (res) nav("/email-model");
            },
            { minify: true },
        );
    };

    const handleChange = (e: any) => {
        setEmailModel({ ...emailModel, [e.target.id]: e.target.value });
    };

    const onLoad = () => {
        const templateJson: any = {
            counters: {
                u_row: 1,
                u_column: 1,
                u_content_button: 1,
                u_content_text: 16,
                u_content_image: 4,
                u_content_divider: 3,
                u_content_heading: 2,
            },
            body: {
                id: "Aa_7SzQzP4",
                rows: [
                    {
                        id: "I16RC2qUob",
                        cells: [1],
                        columns: [
                            {
                                id: "Kbu77eZLbw",
                                contents: [
                                    {
                                        id: "RVbho_klOv",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_2",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Mathieu,</p>',
                                        },
                                    },
                                    {
                                        id: "4iE2UV-vjY",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_3",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Tu as 20 points de fidélité, profites en !</p>',
                                        },
                                    },
                                    {
                                        id: "NRY9EIqoiR",
                                        type: "image",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            src: {
                                                url: "https://assets.unlayer.com/projects/0/1720167415602-Talk%20Logo%20(4).png",
                                                width: 500,
                                                height: 500,
                                                autoWidth: false,
                                                maxWidth: "22%",
                                            },
                                            textAlign: "center",
                                            altText: "",
                                            action: {
                                                name: "web",
                                                values: {
                                                    href: "",
                                                    target: "_blank",
                                                },
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_image_2",
                                                htmlClassNames:
                                                    "u_content_image",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "dFGFXyDcO4",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontWeight: 700,
                                            fontSize: "51px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_1",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="line-height: 71.4px; color: #f1c40f;"><strong>C\'EST <span style="line-height: 71.4px;">VENDREDI</span> HAPPY !</strong></span></p>',
                                        },
                                    },
                                    {
                                        id: "ArnZ51pR4Y",
                                        type: "image",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            src: {
                                                url: "https://pub-b4cab4172b2e43daaaeb03aece715b20.r2.dev/1720171321797-Cre%CC%81e%20ton%20programme.jpg",
                                                width: 675,
                                                height: 675,
                                                autoWidth: false,
                                                maxWidth: "73%",
                                            },
                                            textAlign: "center",
                                            altText: "",
                                            action: {
                                                name: "web",
                                                values: {
                                                    href: "",
                                                    target: "_blank",
                                                },
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_image_3",
                                                htmlClassNames:
                                                    "u_content_image",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "q4KUJQ6yLd",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontSize: "49px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_4",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="line-height: 68.6px; background-color: #f1c40f; color: #ffffff;">1 MENU ACHETÉ</span></p>',
                                        },
                                    },
                                    {
                                        id: "SRC0Y0NCD1",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontSize: "49px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_6",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="line-height: 68.6px; background-color: #f1c40f; color: #ffffff;">1 DESSERT OFFERT</span></p>',
                                        },
                                    },
                                    {
                                        id: "Ees2CS_3qj",
                                        type: "image",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            src: {
                                                url: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-li1wKcEfHHWnsfNIphQI8QMH/user-BN2lHwPS6RZd1nep5WQ32qdz/img-cJjkYxvakCcw14gaR7ctdGOg.png?st=2024-07-03T12%3A37%3A18Z&se=2024-07-03T14%3A37%3A18Z&sp=r&sv=2023-11-03&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-07-03T02%3A20%3A48Z&ske=2024-07-04T02%3A20%3A48Z&sks=b&skv=2023-11-03&sig=Roa6zTWjZg0b54%2BMTyq5tJDzWLsGBGYBR89NOID0Zfw%3D",
                                                width: 1024,
                                                height: 1024,
                                                autoWidth: false,
                                                maxWidth: "50%",
                                            },
                                            textAlign: "center",
                                            altText: "",
                                            action: {
                                                name: "web",
                                                values: {
                                                    href: "",
                                                    target: "_blank",
                                                },
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_image_1",
                                                htmlClassNames:
                                                    "u_content_image",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            pending: false,
                                            _override: {
                                                mobile: {
                                                    src: {
                                                        url: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-li1wKcEfHHWnsfNIphQI8QMH/user-BN2lHwPS6RZd1nep5WQ32qdz/img-cJjkYxvakCcw14gaR7ctdGOg.png?st=2024-07-03T12%3A37%3A18Z&se=2024-07-03T14%3A37%3A18Z&sp=r&sv=2023-11-03&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2024-07-03T02%3A20%3A48Z&ske=2024-07-04T02%3A20%3A48Z&sks=b&skv=2023-11-03&sig=Roa6zTWjZg0b54%2BMTyq5tJDzWLsGBGYBR89NOID0Zfw%3D",
                                                        width: 1024,
                                                        height: 1024,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    {
                                        id: "oasuyGpLr6",
                                        type: "button",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            href: {
                                                name: "web",
                                                values: {
                                                    href: "https://app.jointalk.fr/access/",
                                                    target: "_blank",
                                                },
                                                attrs: {
                                                    href: "{{href}}",
                                                    target: "{{target}}",
                                                },
                                            },
                                            buttonColors: {
                                                color: "#FFFFFF",
                                                backgroundColor: "#fede65",
                                                hoverColor: "#FFFFFF",
                                                hoverBackgroundColor: "#3AAEE0",
                                            },
                                            size: {
                                                autoWidth: true,
                                                width: "100%",
                                            },
                                            fontSize: "14px",
                                            textAlign: "center",
                                            lineHeight: "120%",
                                            padding: "10px 20px",
                                            border: {},
                                            borderRadius: "4px",
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_button_1",
                                                htmlClassNames:
                                                    "u_content_button",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<span style="line-height: 16.8px;">LET\'S GO !</span>',
                                            calculatedWidth: 111,
                                            calculatedHeight: 37,
                                        },
                                    },
                                    {
                                        id: "i6iC_0NVx4",
                                        type: "divider",
                                        values: {
                                            width: "100%",
                                            border: {
                                                borderTopWidth: "1px",
                                                borderTopStyle: "solid",
                                                borderTopColor: "#BBBBBB",
                                            },
                                            textAlign: "center",
                                            containerPadding: "10px",
                                            anchor: "",
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_divider_1",
                                                htmlClassNames:
                                                    "u_content_divider",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "Uj3FGlI_mY",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontWeight: 700,
                                            fontSize: "34px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_7",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="color: #f1c40f;">L\'ASTUCE POUR GAGNER ENCORE</span></p>',
                                        },
                                    },
                                    {
                                        id: "IQgyuwdUxp",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontWeight: 700,
                                            fontSize: "34px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_8",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="color: #000000; line-height: 47.6px;">+ DE POINTS</span></p>',
                                        },
                                    },
                                    {
                                        id: "KOjsnnUECZ",
                                        type: "divider",
                                        values: {
                                            width: "100%",
                                            border: {
                                                borderTopWidth: "0px",
                                                borderTopStyle: "solid",
                                                borderTopColor: "#BBBBBB",
                                            },
                                            textAlign: "center",
                                            containerPadding: "18px",
                                            anchor: "",
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_divider_3",
                                                htmlClassNames:
                                                    "u_content_divider",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "7OjAJkC-c4",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontWeight: 700,
                                            fontSize: "34px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_11",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="color: #ffffff; line-height: 47.6px; background-color: #f1c40f;">+ 20 POINTS</span></p>',
                                        },
                                    },
                                    {
                                        id: "P9b5J5zIYF",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_12",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Si tu viens au restaurant ET que tu commandes en livraison ou à emporter </p>',
                                        },
                                    },
                                    {
                                        id: "T8Y52H0UgM",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontFamily: {
                                                label: "Impact",
                                                value: "impact,chicago",
                                                url: "",
                                                defaultFont: true,
                                            },
                                            fontWeight: 700,
                                            fontSize: "34px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_10",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;"><span style="color: #ffffff; line-height: 47.6px; background-color: #f1c40f;">+ 20 POINTS</span></p>',
                                        },
                                    },
                                    {
                                        id: "-q7WsdLAjA",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "14px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_9",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Si tu parraines ou fais découvrir à un ami </p>',
                                        },
                                    },
                                    {
                                        id: "SNdrWjsmiq",
                                        type: "divider",
                                        values: {
                                            width: "100%",
                                            border: {
                                                borderTopWidth: "1px",
                                                borderTopStyle: "solid",
                                                borderTopColor: "#BBBBBB",
                                            },
                                            textAlign: "center",
                                            containerPadding: "10px",
                                            anchor: "",
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_divider_2",
                                                htmlClassNames:
                                                    "u_content_divider",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "wHnyJ9H5O1",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "9px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_13",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Pour en savoir plus sur les données à caractère personnel collectées, la finalité de cette collecte, leur traitement, la durée de conservation des données et, plus généralement, sur la gestion de vos données, vous pouvez consulter notre politique de protection des données disponible sur "notre site web". Vous pouvez par ailleurs exercer vos droits à l’égard des données recueillies (notamment en matière d’accès, de copie, de rectification ou d’effacement, d’opposition, de portabilité) par courrier électronique en nous écrivant à "adresse mail"</p>',
                                        },
                                    },
                                    {
                                        id: "IRMZu8iJvn",
                                        type: "image",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            src: {
                                                url: "https://assets.unlayer.com/projects/0/1720167415602-Talk%20Logo%20(4).png",
                                                width: 500,
                                                height: 500,
                                                autoWidth: false,
                                                maxWidth: "22%",
                                            },
                                            textAlign: "center",
                                            altText: "",
                                            action: {
                                                name: "web",
                                                values: {
                                                    href: "",
                                                    target: "_blank",
                                                },
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_image_4",
                                                htmlClassNames:
                                                    "u_content_image",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                        },
                                    },
                                    {
                                        id: "acD5QFY-RJ",
                                        type: "text",
                                        values: {
                                            containerPadding: "10px",
                                            anchor: "",
                                            fontSize: "10px",
                                            textAlign: "center",
                                            lineHeight: "140%",
                                            linkStyle: {
                                                inherit: true,
                                                linkColor: "#0000ee",
                                                linkHoverColor: "#0000ee",
                                                linkUnderline: true,
                                                linkHoverUnderline: true,
                                            },
                                            hideDesktop: false,
                                            displayCondition: null,
                                            _meta: {
                                                htmlID: "u_content_text_15",
                                                htmlClassNames:
                                                    "u_content_text",
                                            },
                                            selectable: true,
                                            draggable: true,
                                            duplicatable: true,
                                            deletable: true,
                                            hideable: true,
                                            text: '<p style="line-height: 140%;">Se désabonner</p>',
                                        },
                                    },
                                ],
                                values: {
                                    _meta: {
                                        htmlID: "u_column_1",
                                        htmlClassNames: "u_column",
                                    },
                                    border: {},
                                    padding: "0px",
                                    backgroundColor: "",
                                },
                            },
                        ],
                        values: {
                            displayCondition: null,
                            columns: false,
                            backgroundColor: "",
                            columnsBackgroundColor: "",
                            backgroundImage: {
                                url: "",
                                fullWidth: true,
                                repeat: "no-repeat",
                                size: "custom",
                                position: "center",
                            },
                            padding: "0px",
                            anchor: "",
                            hideDesktop: false,
                            _meta: {
                                htmlID: "u_row_1",
                                htmlClassNames: "u_row",
                            },
                            selectable: true,
                            draggable: true,
                            duplicatable: true,
                            deletable: true,
                            hideable: true,
                        },
                    },
                ],
                headers: [],
                footers: [],
                values: {
                    popupPosition: "center",
                    popupWidth: "600px",
                    popupHeight: "auto",
                    borderRadius: "10px",
                    contentAlign: "center",
                    contentVerticalAlign: "center",
                    contentWidth: "500px",
                    fontFamily: {
                        label: "Arial",
                        value: "arial,helvetica,sans-serif",
                    },
                    textColor: "#000000",
                    popupBackgroundColor: "#FFFFFF",
                    popupBackgroundImage: {
                        url: "",
                        fullWidth: true,
                        repeat: "no-repeat",
                        size: "cover",
                        position: "center",
                    },
                    popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
                    popupCloseButton_position: "top-right",
                    popupCloseButton_backgroundColor: "#DDDDDD",
                    popupCloseButton_iconColor: "#000000",
                    popupCloseButton_borderRadius: "0px",
                    popupCloseButton_margin: "0px",
                    popupCloseButton_action: {
                        name: "close_popup",
                        attrs: {
                            onClick:
                                "document.querySelector('.u-popup-container').style.display = 'none';",
                        },
                    },
                    backgroundColor: "#F9F9F9",
                    preheaderText: "",
                    linkStyle: {
                        body: true,
                        linkColor: "#0000ee",
                        linkHoverColor: "#0000ee",
                        linkUnderline: true,
                        linkHoverUnderline: true,
                    },
                    backgroundImage: {
                        url: "",
                        fullWidth: true,
                        repeat: "no-repeat",
                        size: "custom",
                        position: "center",
                    },
                    _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
                },
            },
            schemaVersion: 16,
        };
        emailEditorRef.current.editor.loadDesign(templateJson);
    };

    const triggerTestEmail = async () => {
        emailEditorRef.current.editor.exportHtml(
            async (data: any) => {
                const { html } = data;
                const res = await sendTestEmail(null, html);
                if (res) {
                    setTestEmailSend(true);
                    toast.success("Email de test envoyé");
                }
            },
            { minify: true },
        );
    };

    const onReady = () => {
        const brandingElement = document.getElementsByClassName(
            "blockbuilder-branding",
        )[0] as HTMLElement;
        console.log(emailEditorRef.current.editor.frame.iframe.contentWindow);
        if (brandingElement)
            brandingElement.style.setProperty("display", "none");
    };

    const initUser = async () => {
        const res: any = await getMyInfo();
        if (res) setUser(res.data);
    };

    useEffect(() => {
        initUser();
    }, []);

    return (
        <>
            <GoBack text="Choix du template" />
            <div className="mt-2 relative border-2 p-5 border-[#1f2937] rounded-xl">
                <EmailEditor
                    ref={emailEditorRef}
                    onLoad={onLoad}
                    onReady={onReady}
                    options={{
                        locale: "fr",
                    }}
                />
                <div className="mt-3 flex justify-between">
                    <div className="">
                        <p className="text-sm">email: "{user?.email}"</p>
                        <p className="text-sm">object: "email de test"</p>
                        {testEmailSend ? (
                            <p className="text-sm text-green-600">
                                Email de test envoyé
                            </p>
                        ) : (
                            <p
                                className="mt-1 text-sm text-blue-600 cursor-pointer"
                                onClick={triggerTestEmail}
                            >
                                Envoyer l'email de test
                            </p>
                        )}
                    </div>
                    <div className="flex gap-4">
                        <div className="flex">
                            <input
                                id="name"
                                key={"name"}
                                type="text"
                                placeholder="Nom du template"
                                value={emailModel?.name}
                                className="p-2 w-[300px] h-[60px] rounded-xl border-xl border-2 border-[#1f2937]"
                                onChange={handleChange}
                            />
                        </div>
                        <button
                            className={`p-2 linear h-[60px] rounded-xl bg-primary text-white font-bold py-3 text-xs transition duration-200 hover:opacity-70 active:bg-[#fedd65be]`}
                            onClick={saveTemplate}
                        >
                            Sauvegarder le template
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateEmailModel;
