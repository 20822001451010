import InactivCustomer from "components/dataDisplay/InactivCustomer";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { dateToFrenchShort } from "utils/date";

function SleepingCustomers(props: { data: any }) {
    const { data } = props;
    const [sleepingCustomers, setSleepingCustomers] = useState([]);
    const [sinceDays, setSinceDays] = useState(15);

    const diffDays = (dateA: Date, dateB: Date) => {
        const diffTime = Math.abs(dateA.getTime() - dateB.getTime());
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    useEffect(() => {
        if (!data) return;

        const sleepingCustomers: any = [];
        data.forEach((item: any) => {
            if (item.lastScan) {
                const lastScan = new Date(item.lastScan);
                const today = new Date();
                const diffTime = Math.abs(today.getTime() - lastScan.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (diffDays > sinceDays) {
                    sleepingCustomers.push(item);
                }
            }
        });

        //sort by lastScan
        sleepingCustomers.sort((a: any, b: any) => {
            const dateA = new Date(a.lastScan);
            const dateB = new Date(b.lastScan);
            return dateB.getTime() - dateA.getTime();
        });
        setSleepingCustomers(sleepingCustomers);
    }, [data, sinceDays]);

    return (
        <Card extra="px-[34px] pt-3 rounded-[20px] w-full border">
            <div className="flex items-center justify-between">
                <div>
                    <h4 className="ml-px text-lg font-bold text-navy-700 dark:text-white">
                        Clients inactifs
                    </h4>
                    <p className="mb-2">depuis {sinceDays} jours</p>
                </div>
                <div className="w-[60px]">
                    <InputField
                        type="number"
                        id="limit"
                        inputSmall={true}
                        value={sinceDays}
                        onChange={(e: any) =>
                            setSinceDays(Number(e.target.value))
                        }
                    />
                </div>
            </div>
            {sleepingCustomers &&
                sleepingCustomers
                    .slice(0, 5)
                    .map((item: any, index: number) => (
                        <InactivCustomer
                            name={item.givenName}
                            date={`Dernier scan le ${dateToFrenchShort(
                                item.lastScan,
                            )}`}
                            sum={`${diffDays(
                                new Date(item.lastScan),
                                new Date(),
                            )} jours`}
                            rank={1}
                        />
                    ))}
            {/* <div className="contain bottom-1 w-max items-center gap-1">
        <div className="text-base font-bold text-toola-500 transition-all hover:-translate-x-1 hover:cursor-pointer dark:text-white">
          See all InactivCustomers
        </div>
      </div> */}
        </Card>
    );
}

export default SleepingCustomers;
